import { Component, OnInit } from '@angular/core';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { Router } from '@angular/router';
@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  fonts = fonts;

  name = '';
  email = '';
  gender = '';

  female = false;

  enrolled_list: any[] = [];
  enrolled_list_loading = false;
  active_admissions: any[] = [];

  one_paid = false;
  constructor(private restService: AdminservicesService, private router: Router) {
    try {
      const user = CookieStore.getUserInfo();
      this.name = user.name;
      this.email = user.email;
      this.gender = user.gender || 'male';
    } catch (error) {

    }




  }



  ngOnInit(): void {
    this.findEnrollmentDetails();
    this.getActiveAdmissionDetails();
  }

  async findEnrollmentDetails(): Promise<void> {
    try {
      this.enrolled_list_loading = true;
      this.enrolled_list = [];
      const response = await this.restService.findOnlineClassDetails();
      if (response.success && response.data) {

        for (const data of response.data) {
          if (data.status === 'paid') {
            this.one_paid = true;
            break;
          }
        }
        this.enrolled_list = response.data;

      }
    } catch (error) {
      //alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.enrolled_list_loading = false;
    }
  }

  async getActiveAdmissionDetails(): Promise<void> {
    try {
      const response = await this.restService.getActiveAdmissionDetails();
      if (response.success && response.data) {
        this.active_admissions = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }
}
