declare var $: any;
export class JQueryHelper {
    static toPageInit(): void {
        if ($('body').hasClass('sidebar-gone') && $('body').hasClass('sidenav-toggled')) {
            $('body').removeClass('sidenav-toggled');
        }
    }

    static openModal(id: string, option: any): void {
        if ($(id).modal) {
            $(id).modal(option);
        } else {
            console.log($(id).modal);
        }
    }
    static closeModal(id: string): void {
        $(id).modal('hide');
    }

    static showTab(id: string): void {
        $(id).tab('show');
    }
}
