<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row" *ngIf="exam_data_loading">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="text-center">
                            <h1 class="mg-b-5 ">Please wait...</h1>


                        </div>

                    </div>
                </div>
            </div>
        </div>


        <div class="row" *ngIf="!exam_data_loading && exam_data">

            <ng-container *ngIf="locationHelper.isMobile()" class="text-center">
                <div class="top-time-bar">( Time Remaining : {{exam_data.remaining}} )</div>
            </ng-container>
            <ng-container *ngIf="!locationHelper.isMobile()">
                <div class="col-xl-12" *ngIf="exam_data.name">
                    <div class="card mg-b-20">
                        <div class="card-header pb-0">
                            <div class="text-center">


                                <h1 class="mg-b-5 ">{{exam_data.name}}</h1>
                                <h5 class="card-title mg-b-5 ">( Duratation : {{exam_data.duration | number}} mins, No. Of Questions: {{exam_data.no_of_questions | number}} )</h5>
                                <h6 class="card-title mg-b-20 ">( Time Remaining : {{exam_data.remaining}} )</h6>



                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="col-xl-12" *ngIf="exam_data.description || exam_data.patch_description">
                <div class="card mg-b-20">

                    <div class="card-body" style="white-space: pre-wrap">
                        <h4>Instructions</h4>
                        {{exam_data.description || exam_data.patch_description}}
                    </div>
                </div>
            </div>
            <ng-container *ngIf="language_form">
                <div class="col-xl-12" *ngIf="show_locale">
                    <div class="card mg-b-20">
                        <div class="card-header pb-0 no-print">
                            <div class="d-flex justify-content-between">


                                <div class="d-flex my-xl-auto right-content">
                                    <div class="pr-1 mb-3 mb-xl-0">
                                        <form [formGroup]="language_form">
                                            <div class="form-group">
                                                <label class="main-content-label tx-11 tx-medium tx-gray-600">Choose which language you like to write this exam</label>
                                                <select formControlName="language" class="form-control">
                                                    <option value="">Choose Language</option>
                                                    <option *ngFor="let language of commonEnums.languages;let i=index;trackBy:formDataResolver.identify;" [ngValue]="language">{{language}}</option>
                                                </select>
                                            </div>
                                        </form>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>



                <div class="col-xl-12">
                    <div class="card mg-b-20">
                        <div class="card-body">
                            <ng-container *ngFor="let question of exam_data.questions;let i=index;trackBy:formDataResolver.identify;">
                                <ng-container *ngFor="let question_locale of question.question_locale;let j=index;trackBy:formDataResolver.identify;">
                                    <ng-container *ngIf="language_form.value.language.toUpperCase() ==question_locale.locale.toUpperCase()">
                                        <div class="form-group">
                                            <div class="row">
                                                <ng-container *ngIf="locationHelper.isMobile()">
                                                    <div class="col-xl-1">
                                                        <span class="badge badge-success">{{question.category_id}}</span>

                                                    </div>
                                                    <div class="col-xl-11">
                                                        <div style="display: inline;">{{i+1}}) </div>
                                                        <div class="mg-l-2" style="display: inline;" [innerHTML]="question_locale.question"></div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!locationHelper.isMobile()">

                                                    <div class="col-xl-11">
                                                        <div style="display: inline;">{{i+1}}) </div>
                                                        <div class="mg-l-2" style="display: inline;" [innerHTML]="question_locale.question"></div>
                                                    </div>
                                                    <div class="col-xl-1">
                                                        <span class="badge badge-success">{{question.category_id}}</span>

                                                    </div>
                                                </ng-container>

                                            </div>
                                            <div class="clearfix"></div>
                                            <div class="form-group">

                                                <div class="mg-t-5">
                                                    <ng-container *ngFor="let option of question_locale.options;let k=index;trackBy:formDataResolver.identify;">
                                                        <div class="custom-control custom-radio mg-t-10">
                                                            <input id="{{option._id}}" type="radio" class="custom-control-input" value="{{option._id}}" [attr.checked]="question.selected_option_id == option._id? 'checked' : null" name="{{question_locale._id}}" (change)="onOptionChange(question._id,option._id)">
                                                            <label class="custom-control-label" for="{{option._id}}">
                                                                <div class="mg-l-2 clearfix" [innerHTML]="option.option"></div>
                                                            </label>
                                                        </div>
                                                    </ng-container>

                                                </div>
                                            </div>




                                        </div>
                                        <hr>
                                    </ng-container>
                                </ng-container>

                            </ng-container>
                        </div>



                    </div>
                    <ng-container *ngIf="locationHelper.isMobile()" class="text-center">
                        <button class="btn btn-md btn-success mg-b-60" (click)="onExamSubmit()">Finish Exam (Time Remaining : {{this.exam_data.remaining}})</button>
                    </ng-container>
                    <ng-container *ngIf="!locationHelper.isMobile()">
                        <div class="bottom-bar">
                            <button class="btn btn-sm btn-success" (click)="onExamSubmit()">Finish Exam (Time Remaining : {{this.exam_data.remaining}})</button>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>