import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  fonts = fonts;

  login_form: any;
  login_form_loading = false;

  days: number[] = [];
  months: number[] = [];
  years: number[] = [];
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    CookieStore.clearBearerToken();
  }

  ngOnInit(): void {

    this.buildLoginForm();
  }

  buildLoginForm(): void {
    for (let index = 1; index <= 31; index++) {
      this.days.push(index);
    }
    for (let index = 1; index <= 12; index++) {
      this.months.push(index);
    }
    for (let index = (new Date().getFullYear()); index >= 1950; index--) {
      this.years.push(index);
    }
    this.login_form = this.fb.group({
      aadhar_card_no: [''],
      birth_date_display: [],
      day: [''],
      month: [''],
      year: [''],
    });
  }

  changeSelectBox(form, name, e): void {


    const year = this.login_form.get('year');
    const month = this.login_form.get('month');
    const day = this.login_form.get('day');
    /* if (name === 'month' || name === 'year') {
      this.login_form.get('day').setValue('');
    } */
    if (year.value && month.value) {
      const date = new Date(year.value, month.value, 0);
      this.days = [];
      for (let index = 1; index <= date.getDate(); index++) {
        this.days.push(index);
      }
    }
  }

  async onLoginClick(): Promise<void> {
    try {
      const login_data = this.login_form.value;
      if (!login_data.aadhar_card_no
        || login_data.aadhar_card_no.trim().length === 0
        || login_data.aadhar_card_no.trim().length !== 12) {
        alert('Please enter valid 12 Digit AADHAR No');
        return;
      }

      let birth_date_val = '';
      try {
        const year = login_data.year;
        if (!year) {
          alert('Please Select Year');
          return;
        }
        const month = login_data.month;
        if (!month) {
          alert('Please Select Month');
          return;
        }
        const day = login_data.day;
        if (!day) {
          alert('Please Select Day');
          return;
        }


        const birth_date = new Date(year, month - 1, day);
        if (birth_date.getFullYear() === year && (birth_date.getMonth() + 1) === month && birth_date.getDate() === day) {
          birth_date_val = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
        } else {
          alert('Please enter valid Date of Birth , Date of Birth must be in dd/mm/yyyy format');
          return;
        }


      } catch (error) {
        alert('Please enter valid Date of Birth , Date of Birth must be in dd/mm/yyyy format');
        return;
      }

      if (!birth_date_val) {
        alert('Please enter valid Date of Birth');
        return;
      }
      try {
        const date = new Date(birth_date_val);
        if (date > new Date()) {
          alert('Please enter valid Date of Birth');
          return;
        }
      } catch (error) {
        alert('Please enter valid Date of Birth');
        return;
      }
      this.login_form_loading = true;
      login_data.birth_date_display = birth_date_val;
      const response = await this.restService.loginApplicant(login_data);
      if (response.success && response.data) {
        CookieStore.saveAccessToken(response.data.session.access_token);
        CookieStore.saveUserInfo(response.data.applicant);
        this.router.navigate(['/dashboard']);
      } else {
        alert('Error while login, please contact admin');
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save, 'Error while login, please contact admin'));
    } finally {
      this.login_form_loading = false;
    }
  }

}
