<div class="container-fluid">
    <div class="row no-gutter">
        <!-- The image half -->
        <div class="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-primary-transparent">
            <div class="row wd-100p mx-auto text-center">
                <div class="col-md-12 col-lg-12 col-xl-12 my-auto mx-auto wd-100p">
                    <img src="../../assets/img/media/login.png" class="my-auto ht-xl-80p wd-md-100p wd-xl-80p mx-auto"
                        alt="logo">
                </div>
            </div>
        </div>
        <!-- The content half -->
        <div class="col-md-6 col-lg-6 col-xl-5 bg-white">
            <div class="login d-flex align-items-center py-2">
                <!-- Demo content-->
                <div class="container p-0">
                    <div class="row">
                        <div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
                            <div class="card-sigin">
                                <div class="mb-5 d-flex"> <a href="index.html"><img
                                            src="../../assets/img/logo/side-logo.png" class="sign-favicon ht-40"
                                            alt="logo"></a>

                                </div>
                                <div class="main-signup-header">
                                    <h2 class="text-primary">Admission Process</h2>
                                    <h5 class="font-weight-normal mb-4">Login to view your application details.</h5>
                                    <ng-container *ngIf="login_form">
                                        <form [formGroup]="login_form">
                                            <div class="form-group">
                                                <label>Enter Applicant's AADHAR Card Number <small style="color: red;">
                                                        *</small></label>
                                                <input class="form-control" placeholder="AADHAR Card Number" type="text"
                                                    formControlName='aadhar_card_no'>
                                            </div>
                                            <div class="form-group">
                                                <label>Select Date of Birth <small style="color: red;">
                                                        *</small></label>
                                                <div class="row">
                                                    <div class="form-group col-md-4">
                                                        <select class="form-control custom-select" id="day" name="day"
                                                            formControlName="day"
                                                            (change)="changeSelectBox(this.login_form,'day',$event)">
                                                            <option value="">Day</option>
                                                            <option *ngFor="let day of days" [ngValue]="day">
                                                                {{day}}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-4">
                                                        <select class="form-control custom-select" id="month"
                                                            name="month" formControlName="month"
                                                            (change)="changeSelectBox(this.login_form,'month',$event)">
                                                            <option value="">Month</option>
                                                            <option *ngFor="let month of months" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-4">
                                                        <select class="form-control custom-select" id="year" name="year"
                                                            formControlName="year"
                                                            (change)="changeSelectBox(this.login_form,'year',$event)">
                                                            <option value="">Year</option>
                                                            <option *ngFor="let year of years" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                    </div>

                                                </div>

                                            </div>

                                            <button *ngIf="!login_form_loading" class="btn btn-main-primary btn-block"
                                                (click)="onLoginClick()">Login</button>
                                            <button *ngIf="login_form_loading" class="btn btn-main-primary btn-block"
                                                disabled=''>Please wait...</button>

                                        </form>
                                    </ng-container>
                                    <div class="main-signup-footer mt-5">
                                        <p>Click New Applicant to create your account <a routerLink='/register'>New
                                                Applicant</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- End -->
            </div>
        </div><!-- End -->
    </div>
</div>