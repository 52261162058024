import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-viewapplied',
  templateUrl: './viewapplied.component.html',
  styleUrls: ['./viewapplied.component.scss']
})
export class ViewappliedComponent implements OnInit {
  fonts = fonts;

  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;

  subject_list: any[] = [];


  user_info: any;

  // Application Data
  purchased_application_list: any[] = [];
  purchased_application_list_loading = false;

  one_paid = false;

  show_programme_helper: boolean = false;
  status_obj: { id: string, value: string }[] = [
    {
      id: "in_applied", value: "In Applied"
    },
    {
      id: "merit_list", value: "Applied"
    },
    {
      id: "rejected", value: "Rejected"
    },
    {
      id: "verification_list", value: "Verification List"
    },
    {
      id: "verified", value: "Verified List"
    },
    {
      id: "ready_to_pay", value: "Ready to Pay (Selected)"
    },
    {
      id: "enrolled", value: "Paid"
    },
    {
      id: "payment_delayed", value: "Payment Delayed"
    },
    {
      id: "transferred", value: "Transferred"
    },
    {
      id: "transfer_pending", value: "Transfer Pending"
    },
    {
      id: "cancelled", value: "Cancelled"
    }
  ]
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getPurchasedApplications();
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo();
      if (response.success && response.data) {
        this.user_info = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async getPurchasedApplications(): Promise<void> {
    try {
      this.purchased_application_list_loading = true;
      this.purchased_application_list = [];
      const response = await this.restService.getPurchasedApplications();
      if (response.success && response.data) {
        console.log(response.data);
        for (const data of response.data) {
          data.show_payment = true;
          if (data.status === 'paid') {
            this.one_paid = true;
            break;
          }
        }

        for (const data of response.data) {
          if (data.status === 'ready_to_pay' || data.status === 'enrolled') {
            this.show_programme_helper = true;
            break;
          }
        }
        const transfer_data = response.data.find(c => c.transfer_pending_amount > 0);
        if (transfer_data) {
          for (const data of response.data) {
            data.show_payment = false;
            if (data.application_no === transfer_data.application_no) {
              data.show_payment = true;
            }
          }
        }
        this.purchased_application_list = response.data;

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.purchased_application_list_loading = false;
    }
  }

  payOnline(application: any): void {
    const consent = confirm(`Attention!!!\nBefore continue please check the following on your side \n1. Check your account have more than Rs. ${application.programme_fee} balance\n2. Make sure your card is enabled for online transcations\n3. Read our refund policy\n4. Incase of payment issue or help need please contact help desk`);
    if (!consent) {
      return;
    }
    if (application.programme_fee === 0) {
      alert('Cannot proceed with zero amount');
      return;
    }
    const payment_url = this.restService.getProgrammePaymentURL(application.application_no);
    location.href = payment_url;
  }

  getApplicationStatus(status) {
    return this.status_obj.find(x => x.id == status)?.value;
  }



}
