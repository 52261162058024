import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-entrance-dashboard',
  templateUrl: './entrance-dashboard.component.html',
  styleUrls: ['./entrance-dashboard.component.scss']
})
export class EntranceDashboardComponent implements OnInit {

  fonts = fonts;

  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;

  subject_list: any[] = [];


  user_info: any;

  // Application Data
  exam_list: any[] = [];
  exam_list_loading = false;

  preparing_exam = false;
  preparing_exam_text = '';

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getMyExams();
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo();
      if (response.success && response.data) {
        this.user_info = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async getMyExams(): Promise<void> {
    try {
      this.exam_list_loading = true;
      this.exam_list = [];
      const response = await this.restService.findExams();
      if (response.success && response.data) {
        for (const exam of response.data) {
          exam.show_start = false;
          if (!exam.current_time) {
            exam.current_time = new Date(exam.server_time);
          } else {
            exam.current_time = new Date(exam.current_time.getTime() + 1000);
          }
          const remaining = new Date(exam.start_time).getTime() - new Date(exam.current_time).getTime();

          exam.remaining = DateHelper.dhm(remaining, '');
          if (remaining < 0) {
            exam.show_start = true;
          }
        }
        this.exam_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.exam_list_loading = false;
    }

    const internal_time = setInterval(() => {
      if (this.exam_list.length > 0) {
        for (const exam of this.exam_list) {
          exam.show_start = false;
          if (!exam.current_time) {
            exam.current_time = new Date(exam.server_time);
          } else {
            exam.current_time = new Date(exam.current_time.getTime() + 1000);
          }
          const remaining = new Date(exam.start_time).getTime() - new Date(exam.current_time).getTime();

          exam.remaining = DateHelper.dhm(remaining, '');
          if (remaining < 0) {
            if (exam.submitted_count > 0) {

            } else {
              clearInterval(internal_time);
              exam.show_start = true;
              // this.getExamQuestions(exam._id, exam.patch_id);
            }

          }
        }
      }
    }, 1000);
  }

  randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  async getExamQuestions(exam_id: string, patch_id: string): Promise<void> {
    this.preparing_exam = true;
    let rand_interval = (this.randomIntFromInterval(20, 30)) * 1000;
    setTimeout(() => {
      this.preparing_exam = false;
      this.router.navigate(['/entrance/examhall/' + exam_id + '/' + patch_id]);
    }, rand_interval);


    const fi = setInterval(() => {
      clearInterval(fi);
      rand_interval = rand_interval - 1000;
      this.preparing_exam_text = DateHelper.dhm(rand_interval, '');
    }, 1000);

  }



}
