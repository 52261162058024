import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CookieStore } from '../services/helpers/CookieStore';
import { AdminservicesService } from '../services/rest/adminservices.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private router: Router, private restService: AdminservicesService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    let is_valid_entry = true;
    if (!CookieStore.getBearerToken()) {
      is_valid_entry = false;
    }

    if (is_valid_entry) {
      return true;
    } else {
      this.router.navigate(['/access-denied']);
      return false;
    }

  }

}
