<!-- main-sidebar -->
<div class="app-sidebar__overlay" data-toggle="sidebar"></div>
<!-- <aside class="app-sidebar sidebar-scroll" style="overflow: scroll !important;"> -->
<aside class="app-sidebar sidebar-scroll" style="overflow: scroll;">
    <div class="main-sidebar-header active">
        <a class="desktop-logo logo-light active" href="/dashboard"><img src="../../assets/img/logo/side-logo.png"
                class="main-logo" alt="logo"></a>
        <a class="desktop-logo logo-dark active" href="/dashboard"><img src="../../assets/img/logo/side-logo.png"
                class="main-logo dark-theme" alt="logo"></a>
        <a class="logo-icon mobile-logo icon-light active" href="/dashboard"><img
                src="../../assets/img/logo/logo-50-50.png" class="logo-icon" alt="logo"></a>
        <a class="logo-icon mobile-logo icon-dark active" href="/dashboard"><img
                src="../../assets/img/logo/logo-50-50.png" class="logo-icon dark-theme" alt="logo"></a>
    </div>
    <div class="main-sidemenu">
        <div class="app-sidebar__user clearfix" *ngIf="name">
            <div class="dropdown user-pro-body">
                <div class="">
                    <ng-container *ngIf="gender=='male'">
                        <img alt="user-img" class="avatar avatar-xl brround" src="./assets/img/users/man-128.png">
                    </ng-container>
                    <ng-container *ngIf="gender=='female'">
                        <img alt="user-img" class="avatar avatar-xl brround" src="./assets/img/users/women-128.png">
                    </ng-container>


                    <span class="avatar-status profile-status bg-green"></span>
                </div>
                <div class="user-info">
                    <h4 class="font-weight-semibold mt-3 mb-0">{{name}}</h4>
                    <span class="mb-0 text-muted">{{email}}</span>
                </div>
            </div>
        </div>
        <ul class="side-menu" *ngIf="active_admissions?.length>0">


            <li class="slide">
                <a class="side-menu__item" routerLink="/dashboard">
                    <fa-icon [icon]="fonts.faUser" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">My Profile</span>
                </a>
            </li>
            <li class="slide">
                <a class="side-menu__item" routerLink="/applications/apply">
                    <fa-icon [icon]="fonts.faAddressBook" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">Apply New Programme</span>
                </a>
            </li>
            <li class="slide">
                <a class="side-menu__item" routerLink="/applications/applied/list">
                    <fa-icon [icon]="fonts.faIdCard" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">Applied Programmes</span>
                </a>
            </li>
            <li class="slide">
                <a class="side-menu__item" routerLink="/payments/transactions">
                    <fa-icon [icon]="fonts.faMoneyBillWave" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">My Transactions</span>
                </a>
            </li>
            <li class="slide">
                <a class="side-menu__item" routerLink="/entrance/dahboard">
                    <fa-icon [icon]="fonts.faPassport" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">Entrance Exams</span>
                </a>
            </li>
            <!--<li class="slide">
                <a class="side-menu__item" routerLink="/onlineclass">
                    <fa-icon [icon]="fonts.faCompass" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">Online Class Details</span>
                </a>
            </li> -->
            <li class="slide" *ngIf="enrolled_list.length>0">
                <a class="side-menu__item" routerLink="/portal-helper">
                    <fa-icon [icon]="fonts.faCompass" class="side-bar-icon"></fa-icon>
                    <span class="side-menu__label">Portal Helper</span>
                </a>
            </li>

        </ul>
    </div>
</aside>
<!-- main-sidebar -->