
export class LocationHelper {

    static getOrigin(): string {
        if (location.origin) {
            return location.origin;
        } else {
            const protocol = location.protocol;
            const host = location.host;
            const port = location.port;
            return protocol + '//' + host + (port ? ':' + port : '');
        }
    }

    static isMobile(): boolean {
        const width = document.body.clientWidth;
        if (width < 700) {
            return true;
        }
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        return isMobile;
    }


}
