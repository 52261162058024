import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-applynew',
  templateUrl: './applynew.component.html',
  styleUrls: ['./applynew.component.scss']
})
export class ApplynewComponent implements OnInit {

  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;


  subject_list: any[] = [];


  user_info: any;
  user_form: any;

  // Application Data
  application_list: any[] = [];
  application_list_loading = false;

  application_list_form: any;


  // Cart
  cart_list: any[] = [];

  cart_sub_total = 0;
  cart_discount_total = 0;
  cart_grant_total = 0;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo();
      if (response.success && response.data) {
        this.user_info = response.data;
        this.getApplication();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async getApplication(): Promise<void> {
    try {
      this.application_list_loading = true;
      this.application_list = [];
      const response = await this.restService.getApplications();
      if (response.success && response.data) {
        this.application_list = response.data;

        this.buildApplicationListForm();
        console.log(this.application_list_form.value);
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.application_list_loading = false;
    }
  }

  buildApplicationListForm(): void {
    this.application_list_form = this.fb.group({
      application_group_list: this.fb.array(this.buildApplicationGroupDataForm(this.application_list))
    });
  }


  buildApplicationGroupDataForm(data: any[]): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        sc_discount_allowed: [data_item.sc_discount_allowed],
        type: [data_item.type],
        applications: this.fb.array(this.buildApplicationDataForm(data_item.applications, data_item.sc_discount_allowed))
      }));
    }
    return value_to_return;
  }

  buildApplicationDataForm(data: any[], sc_discount_allowed: boolean): any[] {
    if (!data) {
      data = [];
    }
    const value_to_return = [];
    for (const data_item of data) {
      value_to_return.push(this.fb.group({
        admission_id: [data_item.admission_id],
        programme_id: [data_item.programme_id],
        programme_type_id: [data_item.programme_type_id],
        finance_type: [data_item.finance_type],
        programme_name: [data_item.programme_name],
        amount: [data_item.amount],
        already_purchased: [data_item.already_purchased],
        already_in_cart: [data_item.already_in_cart],
        sc_discount_allowed: sc_discount_allowed
      }));
      if (data_item.already_in_cart) {
        this.cart_list.push(data_item);
      }
    }
    this.calculate_total();
    return value_to_return;
  }


  async addToCart(item: any): Promise<void> {
    console.log('', item);

    const cart_item = {
      admission_id: item.value.admission_id,
      programme_id: item.value.programme_id,
    };
    await this.restService.addApplicationToCart(cart_item);
    this.cart_list.push(item.value);
    item.get('already_in_cart').setValue(true);
    this.calculate_total();
  }

  async removeFromCart(item: any): Promise<void> {
    console.log('', item);
    const cart_item = {
      admission_id: item.value.admission_id,
      programme_id: item.value.programme_id,
    };
    await this.restService.removeApplicationFromCart(cart_item);
    this.cart_list = this.cart_list.filter(c => c.programme_id !== item.value.programme_id);
    item.get('already_in_cart').setValue(false);
    this.calculate_total();
  }

  calculate_total(): void {
    this.cart_grant_total = 0;
    this.cart_sub_total = 0;
    this.cart_discount_total = 0;

    let sc_st_sca_discount_eligible = false;
    let regular_app_price = 0;
    for (const item of this.cart_list) {
      this.cart_sub_total = this.cart_sub_total + Number(item.amount);
      if (!sc_st_sca_discount_eligible && item.sc_discount_allowed) {
        if (!this.user_info.sc_st_sca_discount_claimed && item.finance_type === 'regular') {

          if (this.user_info.community_id === 'SC' ||
            this.user_info.community_id === 'ST' ||
            this.user_info.community_id === 'SCA') {

            sc_st_sca_discount_eligible = true;
            regular_app_price = Number(item.amount);
          }

        }
      }
    }

    if (sc_st_sca_discount_eligible) {
      this.cart_discount_total = regular_app_price;
    }



    this.cart_grant_total = this.cart_sub_total - this.cart_discount_total;

  }

  payOnline(): void {
    // if (this.cart_grant_total > 0) {
    const payment_url = this.restService.getPaymentURL();
    location.href = payment_url;
    // } else {
    //   alert('Can not proceed with 0.00 amount, please add more programme in the cart to continue');
    // }
  }



}
