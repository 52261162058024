<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">My Programme(s)</h4>

                        </div>

                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="locationHelper.isMobile()">


                            <ng-container
                                *ngFor="let application of purchased_application_list; let i=index;trackBy:formDataResolver.identify;">
                                <div class="col-sm-12 col-lg-6 col-xl-3">
                                    <div class="card card-img-holder">
                                        <div class="card-body list-icons">
                                            <div class="clearfix">
                                                <div class="float-right  mt-2">
                                                    <span class="text-primary ">
                                                        <!-- <i class="si si-basket-loaded tx-30"></i> -->
                                                        <fa-icon [icon]="fonts.faIdCard"></fa-icon>
                                                    </span>
                                                </div>
                                                <div class="float-left text-left">
                                                    <p class="card-text text-muted mb-1">{{i+1}}.
                                                        {{application.programme_name}}
                                                        {{application.finance_type=="regular"?"(R)":"(SF)"}}</p>
                                                    <h5><b>Application No :</b> {{application.application_no}}</h5>
                                                    <h6><b>Status :</b> {{getApplicationStatus(application.status)}}
                                                    </h6>
                                                    <p class="card-text text-muted mb-1"><b>Purchased On :</b>
                                                        {{application.purchased_on | date:commonEnums.date_format}}</p>
                                                    <!-- <ng-container *ngIf="application.programme_type_id =='PG'"> -->
                                                    <ng-container *ngIf="!this.one_paid">
                                                        <ng-container *ngIf="application.status =='ready_to_pay' ">


                                                            <ng-container
                                                                *ngIf="application.programme_fee_payment_enabled">

                                                                <ng-container
                                                                    *ngIf="application.transfer_pending_amount>0 ">
                                                                    <button type="button"
                                                                        class="btn btn-md btn-info mg-l-10"
                                                                        (click)="payOnline(application)">
                                                                        Pay {{application.transfer_pending_amount |
                                                                        currency:"INR":"Rs. "}} Online
                                                                    </button>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!application.transfer_pending_amount && application.show_payment">
                                                                    <button type="button"
                                                                        class="btn btn-md btn-info mg-l-10"
                                                                        (click)="payOnline(application)">
                                                                        Pay {{application.programme_fee |
                                                                        currency:"INR":"Rs. "}} Online
                                                                    </button>
                                                                </ng-container>

                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="!application.programme_fee_payment_enabled && application.show_payment">
                                                                Please visit college for your admission
                                                            </ng-container>



                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="this.one_paid">
                                                        <ng-container *ngIf="application.status =='enrolled'">
                                                            <span class="badge badge-success">
                                                                Congrats!!! Payment Success
                                                            </span>
                                                            <div>
                                                                Our help and support team will call you and guide
                                                                further shortly.
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <!-- </ng-container> -->
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!locationHelper.isMobile()">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th width="3%">S.No.</th>
                                            <th width="5%">App. Purchased On</th>
                                            <th width="5%">Application No.</th>
                                            <th width="20%">Programme</th>
                                            <th width="10%">Status</th>

                                            <th width="25%">Action & Message</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let application of purchased_application_list; let i=index;trackBy:formDataResolver.identify;">

                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td scope="row">
                                                    {{application.purchased_on | date:commonEnums.date_format}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{application.application_no}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{application.programme_name}}
                                                    {{application.finance_type=="regular"?"(R)":"(SF)"}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{getApplicationStatus(application.status)}}
                                                </td>

                                                <td scope="row">
                                                    <!-- <ng-container *ngIf="application.programme_type_id =='PG'"> -->
                                                    <ng-container *ngIf="!this.one_paid">
                                                        <ng-container *ngIf="application.status =='ready_to_pay'">


                                                            <ng-container
                                                                *ngIf="application.programme_fee_payment_enabled">

                                                                <ng-container
                                                                    *ngIf="application.transfer_pending_amount>0 ">
                                                                    <button type="button"
                                                                        class="btn btn-md btn-info mg-l-10"
                                                                        (click)="payOnline(application)">
                                                                        Pay {{application.transfer_pending_amount |
                                                                        currency:"INR":"Rs. "}} Online
                                                                    </button>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="!application.transfer_pending_amount && application.show_payment">
                                                                    <button type="button"
                                                                        class="btn btn-md btn-info mg-l-10"
                                                                        (click)="payOnline(application)">
                                                                        Pay {{application.programme_fee |
                                                                        currency:"INR":"Rs. "}} Online
                                                                    </button>
                                                                </ng-container>

                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="!application.programme_fee_payment_enabled && application.show_payment">
                                                                Please visit college for your admission
                                                            </ng-container>



                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="this.one_paid">
                                                        <ng-container *ngIf="application.status =='enrolled'">
                                                            <span class="badge badge-success">
                                                                Congrats!!! Payment Success
                                                            </span>
                                                            <div>
                                                                Our help and support team will call you and guide
                                                                further shortly.
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <!-- </ng-container> -->
                                                </td>

                                            </tr>

                                        </ng-container>
                                        <ng-container
                                            *ngIf="!purchased_application_list_loading && purchased_application_list.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="purchased_application_list_loading && purchased_application_list.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>


                                    </tbody>
                                </table>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>

            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Help :
                                <!-- <small>Course Cancel & Course Transfer</small> -->
                            </h4>

                        </div>

                    </div>

                    <div class="card-body">


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th width="3%">S.No.</th>
                                        <th width="40%">Name</th>
                                        <th width="15%">Mobile No.</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">
                                            1
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Dr. P. Suresh Babu
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9843324523
                                        </td>


                                    </tr>

                                    <tr>
                                        <td scope="row">
                                            2
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Dr. S. Balaji
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9884829569
                                        </td>


                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-xl-12" *ngIf="show_programme_helper">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Course Cancel & Transfer Help :
                                <!-- <small>Course Cancel & Course Transfer</small> -->
                            </h4>

                        </div>

                    </div>

                    <div class="card-body">


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th width="3%">S.No.</th>
                                        <th width="40%">Name</th>
                                        <th width="15%">Mobile No.</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr>
                                                                <td scope="row">
                                                                    1
                                                                </td>
                                                                <td scope="row" class="uppercase">
                                                                    Dr. P. Suresh Babu
                                                                </td>
                                                                <td scope="row" class="uppercase">
                                                                    9843324523
                                                                </td>
                                                            </tr> -->

                                    <!-- <tr>
                                                                <td scope="row">
                                                                    2
                                                                </td>
                                                                <td scope="row" class="uppercase">
                                                                    Dr. S. Balaji
                                                                </td>
                                                                <td scope="row" class="uppercase">
                                                                    9884829569
                                                                </td>
                                                            </tr> -->

                                    <tr>
                                        <td scope="row">
                                            1
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Mr. A. Ananda Gopi
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9092449709
                                        </td>
                                    </tr>

                                    <tr>
                                        <td scope="row">
                                            2
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Mr. S. Alagukumar
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9994847670
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-xl-12" *ngIf="show_programme_helper">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Course Fee Information Help :
                                <!-- <small>Course Cancel & Course Transfer</small> -->
                            </h4>

                        </div>

                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th width="3%">S.No.</th>
                                        <th width="40%">Name</th>
                                        <th width="15%">Mobile No.</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td scope="row">
                                            1
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Dr.A.MURUGAN
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9841487867
                                        </td>
                                    </tr>

                                    <tr>
                                        <td scope="row">
                                            2
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Mr.S.ARUNSANKAR
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9626740709
                                        </td>
                                    </tr>

                                    <tr>
                                        <td scope="row">
                                            3
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Mr.K.GANESHA MOORTHY
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9894116751
                                        </td>
                                    </tr>

                                    <tr>
                                        <td scope="row">
                                            4
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Dr.J.JOHN WILSON
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9788674383
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>