<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->

        <div class="row">



            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">My Entrance</h4>

                        </div>

                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="locationHelper.isMobile()">


                            <ng-container
                                *ngFor="let exam of exam_list; let i=index;trackBy:formDataResolver.identify;">
                                <div class="col-sm-12 col-lg-6 col-xl-3">
                                    <div class="card card-img-holder">
                                        <div class="card-body list-icons">
                                            <div class="clearfix">
                                                <div class="float-right  mt-2">
                                                    <span class="text-primary ">
                                                        <!-- <i class="si si-basket-loaded tx-30"></i> -->
                                                        <fa-icon [icon]="fonts.faIdCard"></fa-icon>
                                                    </span>
                                                </div>
                                                <div class="float-left text-left">
                                                    <p class="card-text text-muted mb-1 uppercase">{{i+1}}.
                                                        {{exam.name}}</p>
                                                    <h5 class="uppercase"><b>Starts At :</b>{{exam.start_time |
                                                        date:commonEnums.date_time_format}}</h5>
                                                    <h5 class="uppercase"><b>Submitted
                                                            :</b>{{exam.submitted_count>0?'YES':'NO'}}</h5>

                                                    <ng-container
                                                        *ngIf="!exam.submitted_count || exam.submitted_count==0">
                                                        <!-- <button class="btn btn-sm btn-info" (click)="getExamQuestions(exam._id,exam.patch_id)">Start Exam</button> -->
                                                        <ng-container *ngIf="exam.show_start">
                                                            <ng-container *ngIf="preparing_exam">
                                                                <button class="btn btn-sm btn-success" disabled>Please
                                                                    wait.. </button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!preparing_exam">
                                                                <button class="btn btn-sm btn-info"
                                                                    (click)="getExamQuestions(exam._id,exam.patch_id)">Start
                                                                    Exam</button>
                                                            </ng-container>

                                                        </ng-container>
                                                        <ng-container *ngIf="!exam.show_start">
                                                            <button class="btn btn-sm btn-info"
                                                                [attr.disabled]="!exam.show_start?'':null">Enables in
                                                                {{exam.remaining}}</button>
                                                        </ng-container>

                                                    </ng-container>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!locationHelper.isMobile()">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th width="3%">S.No.</th>
                                            <th width="40%">Exam Name</th>
                                            <th width="15%">Starts At</th>
                                            <th width="15%">Finished</th>
                                            <th width="15%">Start Exam</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let exam of exam_list; let i=index;trackBy:formDataResolver.identify;">

                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{exam.name}} - {{exam.patch_name}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{exam.start_time | date:commonEnums.date_time_format}}
                                                </td>

                                                <td scope="row" class="uppercase">
                                                    {{exam.submitted_count>0?'YES':'NO'}}
                                                </td>

                                                <td scope="row">
                                                    <ng-container
                                                        *ngIf="!exam.submitted_count || exam.submitted_count==0">
                                                        <!-- <button class="btn btn-sm btn-info" (click)="getExamQuestions(exam._id,exam.patch_id)">Start Exam</button> -->
                                                        <ng-container *ngIf="exam.show_start">
                                                            <ng-container *ngIf="preparing_exam">
                                                                <button class="btn btn-sm btn-success" disabled>Please
                                                                    wait ({{preparing_exam_text}}).. </button>
                                                            </ng-container>
                                                            <ng-container *ngIf="!preparing_exam">
                                                                <button class="btn btn-sm btn-info"
                                                                    (click)="getExamQuestions(exam._id,exam.patch_id)">Start
                                                                    Exam</button>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="!exam.show_start">
                                                            <button class="btn btn-sm btn-info"
                                                                [attr.disabled]="!exam.show_start?'':null">Enables in
                                                                {{exam.remaining}}</button>
                                                        </ng-container>

                                                    </ng-container>
                                                </td>

                                            </tr>

                                        </ng-container>
                                        <ng-container *ngIf="!exam_list_loading && exam_list.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">No Active Exams found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="exam_list_loading && exam_list.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>


                                    </tbody>
                                </table>
                            </div>
                        </ng-container>

                    </div>
                </div>

                <!-- <div class="card mg-b-20">
                    <div class="card-body">
                        <div class="text-center mg-b-20">
                            <iframe width="50%" height="300" src="https://www.youtube.com/embed/KcvrxI1-Ehg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>

                        <div class="alert alert-success" role="alert">
                            <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="spinner-grow text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>



                            <a href="assets/docs/Entrance-Examinations-Help.pdf">Click here to view Entrance Examination Help Contact details</a>
                        </div>
                        <div class="alert alert-success" role="alert">
                            <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="spinner-grow text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>



                            <a href="/dashboard">Please fill +2 marks if you have not filled</a>
                        </div>
                    </div>
                </div> -->
            </div>




        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>