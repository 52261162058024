<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->
        <ng-container *ngIf="this.user_info">
            <ng-container *ngIf="this.user_info.blood_group">
                <div class="row">


                    <div class="col-xl-12">
                        <div class="card mg-b-20">
                            <div class="card-header pb-0">
                                <div class="d-flex justify-content-between">
                                    <h4 class="card-title mg-b-0">Applications for Eligible Programmes</h4>

                                </div>
                                <p class="tx-12 tx-gray-500 mb-2">Select Programmes (can choose more than one programme
                                    also) you wish to apply for, by
                                    clicking the 'Apply' button.</p>
                            </div>

                            <div class="card-body">
                                <!-- <div class="form-group">
                                    <label style="color: red;">Note: Applicants of UG Aided Programmes are instructed to
                                        apply both in our College website as well as in Tamil Nadu
                                        Government Website.</label>
                                    <label style="color: red;">குறிப்பு: எமது கல்லூரியில் அரசு உதவி பெறும் இளங்கலை /
                                        இளம் அறிவியல் பாடப் பிரிவுகளில் சேர விரும்பும் மாணவர்கள், கல்லூரியின்
                                        இணையதளம் மற்றும் தமிழ்நாடு அரசின் இணையதளம் இரண்டிலும் விண்ணப்பிக்கவும்.</label>

                                </div> -->

                                <ng-container *ngIf="locationHelper.isMobile()">
                                    <ng-container
                                        *ngFor="let application_group of formDataResolver.getFormArrayControlls(this.application_list_form,'application_group_list'); let i=index;trackBy:formDataResolver.identify;">
                                        <ng-container [formGroup]='application_group'>
                                            <div>
                                                <b class="uppercase">{{commonEnums.getFinanceDisplayText(application_group.value.type)}}
                                                    Programmes</b>
                                            </div>
                                            <div class="row mg-t-30">
                                                <ng-container
                                                    *ngFor="let application of formDataResolver.getFormArrayControlls(application_group,'applications'); let i=index;trackBy:formDataResolver.identify;">
                                                    <ng-container [formGroup]='application'>

                                                        <div class="col-sm-12 col-lg-6 col-xl-3">
                                                            <div class="card card-img-holder">
                                                                <div class="card-body list-icons">
                                                                    <div class="clearfix">
                                                                        <div class="float-right  mt-2">
                                                                            <span class="text-primary ">
                                                                                <i
                                                                                    class="si si-basket-loaded tx-30"></i>
                                                                            </span>
                                                                        </div>
                                                                        <div class="float-left text-left">
                                                                            <p class="card-text text-muted mb-1">
                                                                                {{i+1}}.
                                                                                {{application.value.programme_name}}</p>
                                                                            <h4>{{application.value.amount |
                                                                                currency:"INR":"Rs. "}}</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-footer p-0">
                                                                        <p class="text-muted mb-0 pt-4">
                                                                            <ng-container
                                                                                *ngIf="application.value.already_purchased==true">
                                                                                <b>Already Purchased</b>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="application.value.already_purchased==false">
                                                                                <ng-container
                                                                                    *ngIf="application.value.already_in_cart==true">
                                                                                    <button
                                                                                        class="btn btn-sm btn-danger"
                                                                                        type="button"
                                                                                        (click)="removeFromCart(application)">-
                                                                                        Remove</button>
                                                                                </ng-container>
                                                                                <ng-container
                                                                                    *ngIf="application.value.already_in_cart!=true">
                                                                                    <button
                                                                                        class="btn btn-sm btn-success"
                                                                                        type="button"
                                                                                        (click)="addToCart(application)">+
                                                                                        Apply</button>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">

                                            <tbody *ngIf="application_list_form">


                                                <tr>
                                                    <th class="text-right">Sub Total</th>
                                                    <th class="text-right">{{cart_sub_total|currency:"INR":"Rs. "}}</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Discount </th>
                                                    <th class="text-right">{{cart_discount_total|currency:"INR":"Rs. "}}
                                                    </th>
                                                    <th>(SC/ST/SCA Only - One Aided programme application form
                                                        free of cost)</th>
                                                </tr>
                                                <tr>
                                                    <th class="text-right">Grant Total</th>
                                                    <th class="text-right">{{cart_grant_total|currency:"INR":"Rs. "}}
                                                    </th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th colspan="4" class="text-left">

                                                        <div class="mg-t-5 mg-b-5">

                                                            <ng-container *ngIf="cart_grant_total==0 ">
                                                                <ng-container *ngIf="cart_discount_total>0 ">
                                                                    <button class="btn btn-primary"
                                                                        (click)="payOnline()">
                                                                        Apply
                                                                    </button>

                                                                </ng-container>
                                                                <ng-container *ngIf="cart_discount_total==0 ">
                                                                    <button class="btn btn-primary" disabled>
                                                                        Pay Online
                                                                    </button>
                                                                </ng-container>


                                                            </ng-container>
                                                            <ng-container *ngIf="cart_grant_total>0 ">
                                                                <button class="btn btn-primary" (click)="payOnline()">
                                                                    Pay {{cart_grant_total|currency:"INR":"Rs. "}}
                                                                    Online
                                                                </button>
                                                            </ng-container>



                                                        </div>
                                                    </th>

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!locationHelper.isMobile()">
                                    <div class="table-responsive">
                                        <table class="table table-bordered mg-b-0 text-md-nowrap">
                                            <thead>
                                                <tr>
                                                    <th width="5%">S.No.</th>
                                                    <th width="30%">Programme</th>
                                                    <th width="10%">Application Fee</th>
                                                    <th width="5%">Add</th>

                                                </tr>
                                            </thead>
                                            <tbody *ngIf="application_list_form">

                                                <ng-container
                                                    *ngFor="let application_group of formDataResolver.getFormArrayControlls(this.application_list_form,'application_group_list'); let i=index;trackBy:formDataResolver.identify;">
                                                    <ng-container [formGroup]='application_group'>
                                                        <tr>
                                                            <td scope="row" colspan="4" class="uppercase">
                                                                <b>{{commonEnums.getFinanceDisplayText(application_group.value.type)}}
                                                                    Programmes</b>
                                                            </td>
                                                        </tr>
                                                        <ng-container
                                                            *ngFor="let application of formDataResolver.getFormArrayControlls(application_group,'applications'); let i=index;trackBy:formDataResolver.identify;">
                                                            <ng-container [formGroup]='application'>
                                                                <tr>
                                                                    <td scope="row">
                                                                        {{i+1}}
                                                                    </td>
                                                                    <td scope="row">
                                                                        {{application.value.programme_name}}
                                                                    </td>
                                                                    <td scope="row">
                                                                        <span *ngIf="application.value.amount">
                                                                            {{application.value.amount |
                                                                            currency:"INR":"Rs.
                                                                            "}}
                                                                        </span>
                                                                        <span *ngIf="!application.value.amount">
                                                                            --
                                                                        </span>
                                                                    </td>
                                                                    <td scope="row">
                                                                        <ng-container
                                                                            *ngIf="application.value.already_purchased==true">
                                                                            <b>Already Purchased</b>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="application.value.already_purchased==false">
                                                                            <ng-container
                                                                                *ngIf="application.value.already_in_cart==true">
                                                                                <button class="btn btn-sm btn-danger"
                                                                                    type="button"
                                                                                    (click)="removeFromCart(application)">-
                                                                                    Remove</button>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="application.value.already_in_cart!=true && application.value.amount">
                                                                                <button class="btn btn-sm btn-success"
                                                                                    type="button"
                                                                                    (click)="addToCart(application)">+
                                                                                    Apply</button>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </td>

                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                                <tr>
                                                    <th colspan="2" class="text-right">Sub Total</th>
                                                    <th class="text-right">{{cart_sub_total|currency:"INR":"Rs. "}}</th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th colspan="2" class="text-right">Discount (SC/ST/SCA Only - One
                                                        Aided programme application form
                                                        free of cost)</th>
                                                    <th class="text-right">{{cart_discount_total|currency:"INR":"Rs. "}}
                                                    </th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th colspan="2" class="text-right">Grant Total</th>
                                                    <th class="text-right">{{cart_grant_total|currency:"INR":"Rs. "}}
                                                    </th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th colspan="4" class="text-center">

                                                        <div class="mg-t-5 mg-b-5">

                                                            <ng-container *ngIf="cart_grant_total==0 ">
                                                                <ng-container *ngIf="cart_discount_total>0 ">
                                                                    <button class="btn btn-primary"
                                                                        (click)="payOnline()">
                                                                        Apply
                                                                    </button>

                                                                </ng-container>
                                                                <ng-container *ngIf="cart_discount_total==0 ">
                                                                    <button class="btn btn-primary" disabled>
                                                                        Pay Online
                                                                    </button>
                                                                </ng-container>


                                                            </ng-container>
                                                            <ng-container *ngIf="cart_grant_total>0 ">
                                                                <button class="btn btn-primary" (click)="payOnline()">
                                                                    Pay {{cart_grant_total|currency:"INR":"Rs. "}}
                                                                    Online
                                                                </button>
                                                            </ng-container>



                                                        </div>
                                                    </th>

                                                </tr>
                                                <ng-container
                                                    *ngIf="!application_list_loading && application_list.length==0">
                                                    <tr>
                                                        <th colspan="4" class="text-center">No Records found</th>
                                                    </tr>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="application_list_loading && application_list.length==0">
                                                    <tr>
                                                        <th colspan="4" class="text-center">Loading Please wait</th>
                                                    </tr>
                                                </ng-container>

                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>


                </div>
            </ng-container>
            <ng-container *ngIf="!this.user_info.blood_group">
                <div class="row">
                    <div class="col">
                        <div class="alert alert-warning" role="alert">
                            <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <strong>Oops!</strong> Looks like you not completed the profile data update. Please fill
                            that first.
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>

    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>