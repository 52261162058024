import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-combo-bar',
  templateUrl: './combo-bar.component.html',
  styleUrls: ['./combo-bar.component.scss']
})
export class ComboBarComponent implements OnInit {
  name = '';
  email = '';
  gender = '';
  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    try {
      const user = CookieStore.getUserInfo();
      this.name = user.name;
      this.email = user.email;
      this.gender = user.gender || 'male';
    } catch (error) {

    }

  }

  ngOnInit(): void {
  }

  logout(): void {
    CookieStore.clearBearerToken();
    this.router.navigate(['/login']);
  }
}
