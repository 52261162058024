<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Online Class Details</h4>

                        </div>
                        <p class="tx-12 text-muted mb-0">Use the below information to join the Online Class</p>
                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="locationHelper.isMobile()">


                            <ng-container
                                *ngFor="let application of online_class_list; let i=index;trackBy:formDataResolver.identify;">
                                <div class="col-sm-12 col-lg-6 col-xl-3">
                                    <div class="card card-img-holder">
                                        <div class="card-body list-icons">
                                            <div class="clearfix">
                                                <div class="float-right  mt-2">
                                                    <span class="text-primary ">
                                                        <!-- <i class="si si-basket-loaded tx-30"></i> -->
                                                        <fa-icon [icon]="fonts.faIdCard"></fa-icon>
                                                    </span>
                                                </div>
                                                <div class="float-left text-left">
                                                    <p class="card-text text-muted mb-1">{{i+1}}.</p>
                                                    <p class="card-text text-muted mb-1"><b>Roll No. :</b>
                                                        {{application.roll_no}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Your ANJAC Email :</b>
                                                        {{application.email}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Password. :</b>
                                                        {{application.password}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Google Classroom Id :</b>
                                                        {{application.class_code}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Incharge Name :</b>
                                                        {{application.incharge_name}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Incharge Mobile Number :</b>
                                                        {{application.incharge_mobile}}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!locationHelper.isMobile()">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th width="3%">S.No.</th>
                                            <th width="5%">Roll No.</th>
                                            <th width="5%">Your ANJAC Email</th>
                                            <th width="20%">Password</th>
                                            <th width="10%">Google Classroom Id</th>
                                            <th width="10%">Incharge Name</th>
                                            <th width="10%">Incharge Mobile Number</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngFor="let application of online_class_list; let i=index;trackBy:formDataResolver.identify;">

                                            <tr>
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td scope="row">
                                                    {{application.roll_no}}
                                                </td>
                                                <td scope="row">
                                                    {{application.email}}
                                                </td>
                                                <td scope="row">
                                                    {{application.password}}
                                                </td>
                                                <td scope="row">
                                                    {{application.class_code}}
                                                </td>
                                                <td scope="row">
                                                    {{application.incharge_name}}
                                                </td>
                                                <td scope="row">
                                                    {{application.incharge_mobile}}
                                                </td>



                                            </tr>

                                        </ng-container>
                                        <ng-container *ngIf="!online_class_list_loading && online_class_list.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="online_class_list_loading && online_class_list.length==0">
                                            <tr>
                                                <th colspan="6" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>


                                    </tbody>
                                </table>
                            </div>
                        </ng-container>


                    </div>
                    <div class="card-body">
                        <div class="alert alert-info" role="alert">
                            <span class="alert-inner--text">
                                <div class="spinner-grow text-success" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <a target="_blank" href="assets/docs/Steps_to_join_in_Google_Classroom.pdf" class="">
                                    Click here to view Steps to Join Online Class details
                                </a>
                            </span>
                        </div>
                        <div class="alert alert-info" role="alert">
                            <span class="alert-inner--text">
                                <div class="spinner-grow text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <a target="_blank"
                                    href="assets/docs/Steps_to_attend_online_classes_in_Google_Classroom.pdf" class="">
                                    Click here to view Steps to Attend Online Class details
                                </a>
                            </span>
                        </div>

                        <ng-container *ngIf="user_info.apply_for=='PG'">


                            <div class="alert alert-info" role="alert">
                                <span class="alert-inner--text">
                                    <div class="spinner-grow text-warning" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <a target="_blank" href="assets/docs/Online-SIP.pdf" class="">
                                        Click here to view Online SIP details
                                    </a>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">Help : <small>Course Cancel & Course Transfer</small></h4>

                        </div>

                    </div>

                    <div class="card-body">


                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th width="3%">S.No.</th>
                                        <th width="40%">Name</th>
                                        <th width="15%">Mobile No.</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td scope="row">
                                            1
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Dr. P. Suresh Babu
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9843324523
                                        </td>


                                    </tr>

                                    <tr>
                                        <td scope="row">
                                            2
                                        </td>
                                        <td scope="row" class="uppercase">
                                            Dr. S. Balaji
                                        </td>
                                        <td scope="row" class="uppercase">
                                            9884829569
                                        </td>


                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>