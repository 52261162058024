import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit {

  fonts = fonts;

  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;

  user_info: any;

  // Application Data
  exam_data: any;
  exam_data_loading = false;

  exam_id: string;
  patch_id: string;

  language_form: any;
  show_locale = false;

  server_time: Date = new Date();

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.params.subscribe(data => {
      this.exam_id = data.exam_id;
      this.patch_id = data.patch_id;
    });
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo();
      if (response.success && response.data) {
        this.user_info = response.data;
        let locale = 'ENGLISH';
        if (this.user_info.degree_academics &&
          this.user_info.degree_academics.accademic_info &&
          this.user_info.degree_academics.accademic_info.medium_of_study) {
          locale = this.user_info.degree_academics.accademic_info.medium_of_study.toUpperCase();
        }
        this.language_form = this.fb.group({
          language: locale || ''
        });
        this.getMyExamsQuestion();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async getMyExamsQuestion(): Promise<void> {
    try {
      this.exam_data_loading = true;
      this.exam_data = [];
      const response = await this.restService.findExamsQuestions(this.exam_id, this.patch_id);
      if (response.success && response.data) {
        this.exam_data = response.data;
        this.server_time = new Date();
        if (!this.exam_data.current_time) {
          this.exam_data.current_time = new Date(this.exam_data.server_time);
        }


        for (const question of this.exam_data.questions) {
          if (question.question_locale.length > 1) {
            this.show_locale = true;
            break;
          }
        }

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.exam_data_loading = false;
    }

    const timer = setInterval(() => {
      if (this.exam_data) {
        if (!this.exam_data.current_time) {
          this.exam_data.current_time = new Date(this.exam_data.server_time);
        } else {
          this.exam_data.current_time = new Date(this.exam_data.current_time.getTime() + 1000);
        }
        const end_time = new Date(new Date(this.exam_data.start_time).getTime() + this.exam_data.duration * 60000);
        const remaining_milli_seconds = end_time.getTime() - this.exam_data.current_time.getTime();
        if (remaining_milli_seconds > 0) {
          this.exam_data.remaining = DateHelper.dhm(remaining_milli_seconds, '');
        } else {
          clearInterval(timer);
          this.onExamSubmit(true);
        }
      }
    }, 1000);
  }


  async onOptionChange(question_id: string, option_id: string): Promise<void> {
    try {
      const data = {
        admission_id: this.exam_data.admission_id,
        exam_id: this.exam_data._id,
        exam_patch_id: this.exam_data.patch_id,
        question_id,
        option_id
      };
      this.restService.saveAnswer(data).then(d => { }).catch(e => console.log(e));

    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save));
    } finally {
    }
  }

  async onExamSubmit(internal: boolean = false): Promise<void> {
    try {
      if (!internal) {
        const consent = confirm('Are you sure do you want to finish exam?\nOnce you Finish you can\'t reopen');
        if (!consent) {
          return;
        }
      }
      const data = {
        admission_id: this.exam_data.admission_id,
        exam_id: this.exam_data._id,
        exam_patch_id: this.exam_data.patch_id,
      };
      const response = await this.restService.submitExam(data);
      if (response && response.success) {
        if (!internal) {
          alert('Saved Successfully');
        }
        this.router.navigate(['/entrance/dahboard']);
      } else {
        alert('Error while submiting exam, Please try again after 5 mins');
      }
    } catch (error) {
      alert('Error while submiting exam, Please try again after 5 mins');
    } finally {
    }
  }
}


