<div class="container-fluid">
    <div class="row no-gutter">
        <!-- The image half -->
        <div class="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-primary-transparent">
            <div class="row wd-100p mx-auto text-center">
                <div class="col-md-12 col-lg-12 col-xl-12 my-auto mx-auto wd-100p">
                    <img src="../../assets/img/media/login.png" class="my-auto ht-xl-80p wd-md-100p wd-xl-80p mx-auto"
                        alt="logo">
                </div>
            </div>
        </div>
        <!-- The content half -->
        <div class="col-md-6 col-lg-6 col-xl-5 bg-white">
            <div class="login d-flex align-items-center py-2">
                <!-- Demo content-->
                <div class="container p-0">
                    <div class="row">
                        <div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
                            <div class="card-sigin">
                                <div class="mb-5 d-flex"> <a href="https://admission.anjaconline.org"><img
                                            src="../../assets/img/logo/side-logo.png" class="sign-favicon ht-40"
                                            alt="logo"></a>

                                </div>
                                <div class="main-signup-header">
                                    <h2 class="text-primary">Admission Process</h2>
                                    <h5 class="font-weight-normal mb-4">Excited to see you here, Fill your basic
                                        information to get
                                        started!!!</h5>
                                    <ng-container *ngIf="register_form">
                                        <form [formGroup]="register_form">
                                            <div class="form-group">
                                                <label>Full Name <small style="color: red;">
                                                        *</small></label>

                                                <div class="row mt-0">
                                                    <div class="col-3">
                                                        <input class="form-control" placeholder="Inital" type="text"
                                                            formControlName='middle_name'>
                                                    </div>
                                                    <div class="col-9">
                                                        <input class="form-control" placeholder="Full Name" type="text"
                                                            formControlName='given_name'>
                                                    </div>

                                                </div>



                                            </div>
                                            <div class="form-group">
                                                <label>Enter Applicant's AADHAR Card Number <small style="color: red;">
                                                        *</small></label>
                                                <input class="form-control" placeholder="AADHAR Card Number" type="text"
                                                    formControlName='aadhar_card_no'>
                                            </div>
                                            <div class="form-group">
                                                <label>Select Date of Birth <small style="color: red;">
                                                        *</small></label>
                                                <div class="row">

                                                    <div class="form-group col-md-4">
                                                        <select class="form-control custom-select" id="day" name="day"
                                                            formControlName="day"
                                                            (change)="changeSelectBox(this.login_form,'day',$event)">
                                                            <option value="">Day</option>
                                                            <option *ngFor="let day of days" [ngValue]="day">
                                                                {{day}}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div class="form-group col-md-4">
                                                        <select class="form-control custom-select" id="month"
                                                            name="month" formControlName="month"
                                                            (change)="changeSelectBox(this.login_form,'month',$event)">
                                                            <option value="">Month</option>
                                                            <option *ngFor="let month of months" [ngValue]="month">
                                                                {{month}}
                                                            </option>
                                                        </select>
                                                    </div>


                                                    <div class="form-group col-md-4">
                                                        <select class="form-control custom-select" id="year" name="year"
                                                            formControlName="year"
                                                            (change)="changeSelectBox(this.login_form,'year',$event)">
                                                            <option value="">Year</option>
                                                            <option *ngFor="let year of years" [ngValue]="year">
                                                                {{year}}
                                                            </option>
                                                        </select>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Father's / Guardian's Mobile Number <small style="color: red;">
                                                        *</small></label>
                                                <input class="form-control" placeholder="Mobile Number" type="text"
                                                    formControlName='mobile_number'>
                                            </div>

                                            <!-- <div class="form-group">
                                                <label style="color: red;">Note: Applicants of UG Aided Programmes are
                                                    instructed to
                                                    apply both in our College website as well as in Tamil Nadu
                                                    Government Website.</label>
                                                <label style="color: red;">குறிப்பு: எமது கல்லூரியில் அரசு உதவி பெறும்
                                                    இளங்கலை / இளம் அறிவியல் பாடப் பிரிவுகளில் சேர விரும்பும் மாணவர்கள்,
                                                    கல்லூரியின்
                                                    இணையதளம் மற்றும் தமிழ்நாடு அரசின் இணையதளம் இரண்டிலும்
                                                    விண்ணப்பிக்கவும்.</label>
                                            </div> -->


                                            <button *ngIf="!register_form_loading"
                                                class="btn btn-main-primary btn-block"
                                                (click)="onRegisterClick()">Register</button>
                                            <button *ngIf="register_form_loading" class="btn btn-main-primary btn-block"
                                                disabled=''>Please wait...</button>


                                        </form>
                                    </ng-container>
                                    <div class="main-signup-footer mt-5">
                                        <p>Already applied for programmes ? Awesome!!! then Please click
                                            Login
                                            to view your
                                            application details <a routerLink='/login'>Login</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- End -->
            </div>
        </div><!-- End -->
    </div>
</div>