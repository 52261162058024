<!-- Main-error-wrapper -->
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body text-center">
                    <h2>AYYA NADAR JANAKI AMMAL COLLEGE(Autonomous), SIVAKASI.</h2>
                    <div>UG Programme Selection List</div>

                </div>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <table class="table table-bordered mg-b-0 text-md-nowrap">
                        <thead>

                            <tr>
                                <th class="text-center" style="width: 5%;">S.No</th>
                                <th class="text-center">Programme Name</th>
                                <th class="text-center" style="width: 10%;">View</th>
                            </tr>
                        </thead>

                        <tbody>

                            <ng-container *ngFor="let programme of programme_list;let i=index;trackBy:fromDataResolver.identify;">
                                <tr>
                                    <td>
                                        {{i+1}}
                                    </td>
                                    <td>
                                        {{programme.programme_name}} {{programme.finance_type == 'self_finance'?' (SF)': ' (R)'}}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="programme.selection_list_loading">
                                            <button class="btn btn-sm btn-secondary" disabled>Please wait...</button>
                                        </ng-container>
                                        <ng-container *ngIf="!programme.selection_list_loading">
                                            <button class="btn btn-sm btn-primary" (click)="onProgrammeView(programme)">View</button>
                                        </ng-container>

                                    </td>
                                </tr>
                            </ng-container>


                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>

    <!-- Large Modal -->
    <div class="modal" id="modal-popup">
        <div class="modal-dialog modal-lg big-modal" role="document">
            <div class="modal-content modal-content-demo" *ngIf="selection_list">
                <div class="modal-header">
                    <h6 class="modal-title">Selection List for : {{programme_name}}</h6>
                </div>
                <div class="modal-body">


                    <div class="" *ngIf="selection_list">
                        <div class="table-responsive">
                            <table class="table table-bordered mg-b-0 text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th class="text-center" style="width: 5%;">S.No</th>
                                        <th class="text-center" style="width: 5%;">Application No.</th>
                                        <th class="text-center" style="width: 10%;">Applicant Name</th>


                                    </tr>

                                </thead>

                                <tbody>

                                    <ng-container *ngFor="let programme of selection_list;let i=index;trackBy:fromDataResolver.identify;">

                                        <tr>
                                            <td>
                                                {{i+1}}
                                            </td>
                                            <td class="text-uppercase">
                                                {{programme.application_no}}
                                            </td>

                                            <td class="text-uppercase">
                                                {{programme.name }}
                                            </td>

                                        </tr>

                                    </ng-container>

                                    <ng-container *ngIf="selection_list.length==0">
                                        <tr>
                                            <th colspan="3" class="text-center">No Records found</th>
                                        </tr>
                                    </ng-container>

                                </tbody>



                            </table>
                        </div>
                        <div class="mg-b-20"></div>
                        <div class="text-align-center">

                            <button class="btn ripple btn-secondary" (click)="onSelectionListFormCloseClick()" type="button">Close</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!--End Large Modal -->

</div>
<!-- /Main-error-wrapper -->