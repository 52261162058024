import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { CookieStore } from 'src/app/services/helpers/CookieStore';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  register_form: any;
  register_form_loading = false;

  days: number[] = [];
  months: number[] = [];
  years: number[] = [];

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    CookieStore.clearBearerToken();
  }

  ngOnInit(): void {
    this.buildRegisterForm();
  }

  buildRegisterForm(): void {
    for (let index = 1; index <= 31; index++) {
      this.days.push(index);
    }
    for (let index = 1; index <= 12; index++) {
      this.months.push(index);
    }
    for (let index = (new Date().getFullYear() - 15); index >= 1950; index--) {
      this.years.push(index);
    }
    this.register_form = this.fb.group({
      given_name: [''],
      middle_name: [''],
      aadhar_card_no: [''],
      birth_date: [],
      mobile_number: [''],
      day: [''],
      month: [''],
      year: [''],
    });
  }


  changeSelectBox(form, name, e): void {


    const year = this.register_form.get('year');
    const month = this.register_form.get('month');
    const day = this.register_form.get('day');
    /* if (name === 'month' || name === 'year') {
      this.register_form.get('day').setValue('');
    } */
    if (year.value && month.value) {
      const date = new Date(year.value, month.value, 0);
      this.days = [];
      for (let index = 1; index <= date.getDate(); index++) {
        this.days.push(index);
      }
    }
  }


  async onRegisterClick(): Promise<void> {
    try {
      const register_data = this.register_form.value;
      if (!register_data.aadhar_card_no
        || register_data.aadhar_card_no.trim().length === 0
        || register_data.aadhar_card_no.trim().length !== 12) {
        alert('Please enter valid 12 Digit AADHAR No');
        return;
      }
      if (!register_data.mobile_number
        || register_data.mobile_number.trim().length === 0
        || register_data.mobile_number.trim().length !== 10) {
        alert('Please enter valid 10 digit mobile number');
        return;
      }
      if (!register_data.given_name
        || register_data.given_name.trim().length === 0) {
        alert('Please enter valid Full Name');
        return;
      }
      if (!register_data.middle_name
        || register_data.middle_name.trim().length === 0) {
        alert('Please enter valid Initial');
        return;
      }
      let birth_date_val = '';
      try {
        const year = register_data.year;
        if (!year) {
          alert('Please Select Year');
          return;
        }
        const month = register_data.month;
        if (!month) {
          alert('Please Select Month');
          return;
        }
        const day = register_data.day;
        if (!day) {
          alert('Please Select Day');
          return;
        }


        const birth_date = new Date(year, month - 1, day);
        if (birth_date.getFullYear() === year && (birth_date.getMonth() + 1) === month && birth_date.getDate() === day) {
          birth_date_val = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
        } else {
          alert('Please enter valid Date of Birth , Date of Birth must be in dd/mm/yyyy format');
          return;
        }


      } catch (error) {
        alert('Please enter valid Date of Birth , Date of Birth must be in dd/mm/yyyy format');
        return;
      }

      if (!birth_date_val) {
        alert('Please enter valid Date of Birth');
        return;
      }
      try {
        const date = new Date(birth_date_val);
        if (date > new Date()) {
          alert('Please enter valid Date of Birth');
          return;
        }
      } catch (error) {
        alert('Please enter valid Date of Birth');
        return;
      }
      this.register_form_loading = true;
      register_data.birth_date = birth_date_val;
      const response = await this.restService.registerApplicant(register_data);
      if (response.success && response.data) {
        CookieStore.saveAccessToken(response.data.session.access_token);
        CookieStore.saveUserInfo(response.data.applicant);
        this.router.navigate(['/dashboard']);
      } else {
        alert('Error while register, please contact admin');
      }
    } catch (error) {
      console.log(error);
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.save, 'Error while register, please contact admin'));
    } finally {
      this.register_form_loading = false;
    }
  }

}
