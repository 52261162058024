import { formatDate } from '@angular/common';
export class DateHelper {
    static convertToControlDate(date: Date): string {
        if (date) {
            return formatDate(date, 'yyyy-MM-dd', 'en');
        } else {
            return '';
        }
    }

    static toDisplayDate(date: Date): string {
        if (date) {
            return formatDate(date, 'dd/MM/yyyy', 'en');
        } else {
            return '';
        }
    }

    static getStartDate(date: Date, error_message: string = ''): Date | null {
        const parsed_date = this.validateAndParseDate(date, error_message);
        if (parsed_date) {
            parsed_date.setHours(0, 0, 0, 0);
            return parsed_date;
        } else {
            return null;
        }
    }
    static getEndDate(date: Date, error_message: string = ''): Date | null {
        const parsed_date = this.validateAndParseDate(date, error_message);
        if (parsed_date) {
            parsed_date.setHours(23, 59, 59, 999);
            return parsed_date;
        } else {
            return null;
        }
    }

    static validateAndParseDate(date: any, error_message: string | null = null): Date | null {
        try {
            return new Date(date);
        } catch (error) {
            if (error_message) {
                alert(error_message);
            }
            return null;
        }
    }

    static dhm(miliseconds: number, format: string): string {

        let days;
        let hours;
        let minutes;
        let seconds;
        let total_hours;
        let total_minutes;
        let total_seconds;


        total_seconds = parseInt(Math.floor(miliseconds / 1000).toString(), 10);
        total_minutes = parseInt(Math.floor(total_seconds / 60).toString(), 10);
        total_hours = parseInt(Math.floor(total_minutes / 60).toString(), 10);
        days = parseInt(Math.floor(total_hours / 24).toString(), 10);

        seconds = parseInt((total_seconds % 60).toString(), 10);
        minutes = parseInt((total_minutes % 60).toString(), 10);
        hours = parseInt((total_hours % 24).toString(), 10);

        switch (format) {
            case 's':
                return total_seconds;
            case 'm':
                return total_minutes;
            case 'h':
                return total_hours;
            case 'd':
                return days;
            default:
                const array_val = [];
                if (days > 0) {
                    array_val.push(days.toString().padStart(2, '0'));
                }
                if (hours > 0) {
                    array_val.push(hours.toString().padStart(2, '0'));
                }
                // if (minutes > 0) {
                array_val.push(minutes.toString().padStart(2, '0'));
                // }
                // if (seconds > 0) {
                array_val.push(seconds.toString().padStart(2, '0'));
                // }
                return array_val.join(':');
        }



    }

}
