import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-programmestatus',
  templateUrl: './programmestatus.component.html',
  styleUrls: ['./programmestatus.component.scss']
})
export class ProgrammestatusComponent implements OnInit {


  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  fonts = fonts;


  status = false;
  message = '';

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
    this.route.queryParams.subscribe(params => {
      if (params.status === 'true') {
        this.status = true;
      }
      this.message = params.message;
    });
  }

  ngOnInit(): void {

  }



}
