import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgrammestatusComponent } from './controllers/payment/programmestatus/programmestatus.component';
import { StatusComponent } from './controllers/payment/status/status.component';
import { AccessDeniedComponent } from './controllers/public/access-denied/access-denied.component';
import { LoginComponent } from './controllers/public/login/login.component';
import { RegisterComponent } from './controllers/public/register/register.component';
import { SelectionListComponent } from './controllers/public/selection-list/selection-list.component';
import { UnderMaintenanceComponent } from './controllers/public/under-maintenance/under-maintenance.component';
import { ApplynewComponent } from './controllers/students/applynew/applynew.component';
import { DashboardComponent } from './controllers/students/dashboard/dashboard.component';
import { EntranceDashboardComponent } from './controllers/students/entrance/entrance-dashboard/entrance-dashboard.component';
import { ExamComponent } from './controllers/students/entrance/exam/exam.component';
import { OnlineclassComponent } from './controllers/students/onlineclass/onlineclass.component';
import { PortalHelperComponent } from './controllers/students/portal-helper/portal-helper.component';
import { TransactionsComponent } from './controllers/students/transactions/transactions.component';
import { ViewappliedComponent } from './controllers/students/viewapplied/viewapplied.component';
import { AuthguardGuard } from './guards/authguard.guard';


const routes: Routes = [
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'applications/apply', component: ApplynewComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'applications/applied/list', component: ViewappliedComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'payments/transactions', component: TransactionsComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'portal-helper', component: PortalHelperComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'entrance/dahboard', component: EntranceDashboardComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'entrance/examhall/:exam_id/:patch_id', component: ExamComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'onlineclass', component: OnlineclassComponent,
    canActivate: [AuthguardGuard]
  },
  {
    path: 'selection-list', component: SelectionListComponent
  },
  {
    path: 'payments/transactions/status', component: StatusComponent
  },
  {
    path: 'payments/programme/transactions/status', component: ProgrammestatusComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: '', component: LoginComponent
  },
  {
    path: 'under-maintenance', component: UnderMaintenanceComponent
  },
  {
    path: 'access-denied', component: AccessDeniedComponent
  },
  {
    path: '**', component: AccessDeniedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
