import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss']
})
export class SelectionListComponent implements OnInit {
  fromDataResolver = FromDataResolver;

  programme_list: any[] = [];

  selection_list: any[] = [];
  selection_list_loading = false;

  programme_name: string;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private restService: AdminservicesService) { }

  ngOnInit(): void {
    this.searchProgrammesLite();
  }

  async searchProgrammesLite(): Promise<void> {
    try {
      const response = await this.restService.searchProgrammesLite('UG');
      if (response.success) {
        this.programme_list = response.data;
      }
    } catch (error) {

    } finally {

    }
  }

  async onProgrammeView(programme: any): Promise<void> {
    try {
      this.selection_list = [];
      this.programme_name = programme.programme_name;
      const search_data = {
        programme_type_id: 'UG',
        programme_id: programme.programme_id,
        entrollment_year: 2021
      };
      programme.selection_list_loading = true;
      const response = await this.restService.getSelectionListForPublic(search_data);
      if (response.success) {
        this.selection_list = response.data;
        JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
      }
    } catch (error) {

    } finally {
      programme.selection_list_loading = false;
    }
  }

  onSelectionListFormCloseClick(): void {

    JQueryHelper.closeModal('#modal-popup');
  }
}
