import * as cookies from 'browser-cookies';

export class CookieStore {

    static saveAccessToken(token: string): void {
        this.clearBearerToken();
        if (localStorage) {
            localStorage.setItem('access_token', token);
        } else {
            cookies.set('access_token', token);
        }
    }

    static getBearerToken(): string | null {
        const token = this.getToken();
        if (token) {
            return 'Bearer ' + token;
        }
        return null;
    }

    static getToken(): string | null {
        if (localStorage && localStorage.getItem('access_token')) {
            return localStorage.getItem('access_token');
        } else {
            const cookie = cookies.get('access_token');
            if (cookie) {
                return cookie;
            }
            return null;
        }

    }

    static clearBearerToken(): void {
        if (localStorage) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('userinfo');
        } else {
            cookies.set('access_token', '');
            cookies.set('userinfo', '');
        }
    }


    static saveUserInfo(userinfo: any): void {
        this.clearUserInfo();


        if (localStorage) {
            localStorage.setItem('userinfo', JSON.stringify(userinfo));
        } else {
            cookies.set('userinfo', JSON.stringify(userinfo));
        }
    }

    static getUserInfo(): any {
        if (localStorage && localStorage.getItem('userinfo')) {
            const userinfo = localStorage.getItem('userinfo');
            if (userinfo) {
                return JSON.parse(userinfo);
            } else {
                return null;
            }
        } else {
            const cookie = cookies.get('userinfo');
            if (cookie) {
                return JSON.parse(cookie);
            }
            return null;
        }

    }

    static clearUserInfo(): void {
        if (localStorage) {
            localStorage.removeItem('userinfo');
        } else {
            cookies.set('userinfo', '');
        }
    }



}
