import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fonts from '@fortawesome/free-solid-svg-icons';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';


@Component({
  selector: 'app-onlineclass',
  templateUrl: './onlineclass.component.html',
  styleUrls: ['./onlineclass.component.scss']
})
export class OnlineclassComponent implements OnInit {

  fonts = fonts;

  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;

  subject_list: any[] = [];


  user_info: any;

  // Application Data
  online_class_list: any[] = [];
  online_class_list_loading = false;

  one_paid = false;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.findOnlineClassDetails();
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo();
      if (response.success && response.data) {
        this.user_info = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  async findOnlineClassDetails(): Promise<void> {
    try {
      this.online_class_list_loading = true;
      this.online_class_list = [];
      const response = await this.restService.findOnlineClassDetails();
      if (response.success && response.data) {

        for (const data of response.data) {
          if (data.status === 'paid') {
            this.one_paid = true;
            break;
          }
        }
        this.online_class_list = response.data;

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.online_class_list_loading = false;
    }
  }


}
