import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieStore } from '../helpers/CookieStore';
import { LocationHelper } from '../helpers/LocationHelper';

@Injectable({
  providedIn: 'root'
})
export class AdminservicesService {
  private base_url = '';
  constructor(private httpClient: HttpClient) {
    /* if (location.hostname === 'localhost') {
      this.base_url = 'http://anjac-api.college.com:5002';
    } else { */
    this.base_url = 'https://anjac-api.anjaconline.org';
    //}
  }


  private getHeaderOptions(): any {
    const headers: any = {
      'Content-Type': 'application/json'
    };
    const token = CookieStore.getBearerToken();
    if (token) {
      headers.Authorization = token;
    }
    const header_options = {
      headers: new HttpHeaders(headers),
      responceType: 'json'
    };
    return header_options;
  }


  // Accounts
  async registerApplicant(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/register`, data, this.getHeaderOptions()).toPromise();
  }

  async loginApplicant(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/login`, data, this.getHeaderOptions()).toPromise();
  }

  // Dashboard
  async getApplicantInfo(): Promise<any> {
    const user_data_resp: any = await (this.httpClient.post(`${this.base_url}/admission-srv/applicant/info`,
      {}, this.getHeaderOptions()).toPromise());
    if (user_data_resp.success && user_data_resp.data) {
      CookieStore.saveUserInfo(user_data_resp.data);
    }
    return Promise.resolve(user_data_resp);
  }
  async saveApplicantInfo(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/save`, data, this.getHeaderOptions()).toPromise();
  }

  // Applications
  async getApplications(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/applicant/application/find`, this.getHeaderOptions()).toPromise();
  }

  async addApplicationToCart(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/programe/cart/add`, data, this.getHeaderOptions()).toPromise();
  }
  async removeApplicationFromCart(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/programe/cart/remove`, data, this.getHeaderOptions()).toPromise();
  }

  getPaymentURL(): string {
    const origin = LocationHelper.getOrigin();
    const return_url = origin + '/payments/transactions/status';
    return `${this.base_url}/admission-srv/applicant/payment/payonline?return_url=${return_url}&access_token=${CookieStore.getToken()}`;
  }

  // Schools Subject
  async getSchoolSubjects(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/school/subject`, this.getHeaderOptions()).toPromise();
  }

  // Purchased Applications
  async getPurchasedApplications(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/applicant/applications/order/purchased/list`,
      this.getHeaderOptions()).toPromise();
  }

  // Transactions
  async getPaymentTransactions(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/applicant/transactions`,
      this.getHeaderOptions()).toPromise();
  }


  // fileupload
  uploadFile(formData: any): Promise<any> {
    const headers = new HttpHeaders().set('Authorization', CookieStore.getBearerToken());
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/docs/upload`, formData,
      { headers }).toPromise();
  }

  // Entrance Exam
  async findExams(): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/entrance/applicant/exams/find`, {},
      this.getHeaderOptions()).toPromise();
  }

  async findExamsQuestions(exam_id: string, patch_id: string): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/entrance/applicant/exams/questions/find/${exam_id}/${patch_id}`, {},
      this.getHeaderOptions()).toPromise();
  }

  async saveAnswer(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/entrance/applicant/exams/questions/save`, data,
      this.getHeaderOptions()).toPromise();
  }

  async submitExam(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/entrance/applicant/exams/questions/submit`, data,
      this.getHeaderOptions()).toPromise();
  }

  // programme fee
  getProgrammePaymentURL(application_no: string): string {
    const origin = LocationHelper.getOrigin();
    const return_url = origin + '/payments/programme/transactions/status';
    return `${this.base_url}/admission-srv/applicant/programme/payment/payonline?application_no=${application_no}&return_url=${return_url}&access_token=${CookieStore.getToken()}`;
  }

  // Online Class Details
  async findOnlineClassDetails(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/applicant/online/class/list`,
      this.getHeaderOptions()).toPromise();
  }


  // Programme lite
  async searchProgrammesLite(programme_type_id: string): Promise<any> {
    return this.httpClient.post(`${this.base_url}/programme-srv/programme/utils/search/lite/public/${programme_type_id}`,
      {}, this.getHeaderOptions()).toPromise();
  }

  async getSelectionListForPublic(data: any): Promise<any> {
    return this.httpClient.post(`${this.base_url}/admission-srv/applicant/reports/status-wise/public/selection/list`,
      data, this.getHeaderOptions()).toPromise();
  }

  // Online Class Details
  async getActiveAdmissionDetails(): Promise<any> {
    return this.httpClient.get(`${this.base_url}/admission-srv/admission/utils/active/list`,
      this.getHeaderOptions()).toPromise();
  }
}
