<!-- main-header -->
<div class="main-header sticky side-header nav nav-item">
    <div class="container-fluid">
        <div class="main-header-left ">
            <div class="responsive-logo">
                <a href="/students/dashboard"><img src="../../assets/img/logo/side-logo.png" class="logo-1" alt="logo"></a>
                <a href="/students/dashboard"><img src="../../assets/img/logo/side-logo.png" class="dark-logo-1" alt="logo"></a>
                <a href="/students/dashboard"><img src="../../assets/img/logo/side-logo.png" class="logo-2" alt="logo"></a>
                <a href="/students/dashboard"><img src="../../assets/img/logo/side-logo.png" class="dark-logo-2" alt="logo"></a>
            </div>
            <div class="app-sidebar__toggle" data-toggle="sidebar">
                <a class="open-toggle" href="#"><i class="header-icon fe fe-align-left"></i></a>
                <a class="close-toggle" href="#"><i class="header-icons fe fe-x"></i></a>
            </div>

        </div>
        <div class="main-header-right">

            <div class="nav nav-item  navbar-nav-right ml-auto">

                <div class="dropdown main-profile-menu nav nav-item nav-link" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <a class="profile-user d-flex" style="cursor: pointer;">
                        <ng-container *ngIf="gender=='male'">
                            <img alt="" src="./assets/img/users/man.png" class="">
                        </ng-container>
                        <ng-container *ngIf="gender=='female'">
                            <img alt="" src="./assets/img/users/women.png" class="">
                        </ng-container>
                    </a>
                    <div class="dropdown-menu">
                        <div class="main-header-profile bg-primary p-3">
                            <div class="d-flex wd-100p">
                                <div class="main-img-user">
                                    <ng-container *ngIf="gender=='male'">
                                        <img alt="" src="./assets/img/users/man.png" class="">
                                    </ng-container>
                                    <ng-container *ngIf="gender=='female'">
                                        <img alt="" src="./assets/img/users/women.png" class="">
                                    </ng-container>
                                </div>
                                <div class="ml-3 my-auto">
                                    <h6>{{name}}</h6><span>{{email}}</span>
                                </div>
                            </div>
                        </div>

                        <a class="dropdown-item" (click)="logout()" style="cursor: pointer;"><i class="bx bx-log-out"></i> Sign Out</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="jumps-prevent" style="padding-top: 63px;"></div>
<!-- /main-header -->