<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row">


            <div class="col">

            </div>
            <div class="col">

                <div class="card">
                    <ng-container *ngIf="status">
                        <div class="card-body">
                            <div class="plan-card text-center">
                                <fa-icon [icon]="fonts.faCheck" class="fas plan-icon text-success"></fa-icon>
                                <h6 class="text-drak text-uppercase mt-2">Payment Success !!!</h6>
                                <div class="mg-t-10">
                                    <a class="btn btn-success mg-r-20" routerLink='/applications/applied/list'>Go To
                                        Applied Programme</a>
                                    <a class="btn btn-info" routerLink='/payments/transactions'>Go To Transactions</a>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!status">
                        <div class="card-body">
                            <div class="plan-card text-center">
                                <fa-icon [icon]="fonts.faTimes" class="fas plan-icon text-danger"></fa-icon>
                                <h6 class="text-drak text-uppercase mt-2">Payment Failed !!!</h6>
                                <div class="mg-t-10">
                                    <a class="btn btn-primary mg-r-20" routerLink='/applications/apply'>Try Again</a>
                                    <a class="btn btn-danger" routerLink='/payments/transactions'>Go To Transactions</a>
                                </div>

                            </div>
                        </div>
                    </ng-container>
                </div>

            </div>
            <div class="col"></div>

        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>