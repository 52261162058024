<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <!-- <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2> -->
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>
        <!-- row -->
        <div class="row">


            <div class="col-xl-12">
                <div class="card mg-b-20">
                    <div class="card-header pb-0">
                        <div class="d-flex justify-content-between">
                            <h4 class="card-title mg-b-0">My Payment Transactions</h4>

                        </div>

                    </div>

                    <div class="card-body">
                        <ng-container *ngIf="locationHelper.isMobile()">


                            <ng-container *ngFor="let transaction of transaction_list; let i=index;trackBy:formDataResolver.identify;">
                                <div class="col-sm-12 col-lg-6 col-xl-3">
                                    <div class="card card-img-holder" [ngClass]="{'success': transaction.order_status.toUpperCase() === 'SUCCESS','danger': transaction.order_status.toUpperCase() !== 'SUCCESS' }">
                                        <div class="card-body list-icons">
                                            <div class="clearfix">
                                                <div class="float-right  mt-2">
                                                    <span class="text-primary ">
                                                        <!-- <i class="si si-basket-loaded tx-30"></i> -->
                                                        <fa-icon [icon]="fonts.faMoneyBillWave"></fa-icon>
                                                    </span>
                                                </div>
                                                <div class="float-left text-left">
                                                    <h6 class="card-text text-muted mb-1">{{i+1}}. {{transaction.purpose}}</h6>
                                                    <p class="card-text text-muted mb-1"><b>Date :</b> {{transaction.date | date:commonEnums.date_time_format}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Amount :</b> {{transaction.amount | currency:"INR":"Rs. "}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Qty :</b> {{transaction.no_of_items}}</p>
                                                    <p class="card-text text-muted mb-1 uppercase"><b>Payment Mode :</b>
                                                        <ng-container *ngIf="transaction.payment_mode!=='null'">
                                                            {{transaction.payment_mode}}
                                                        </ng-container>
                                                    </p>
                                                    <p class="card-text text-muted mb-1"><b>Order Id. :</b> {{transaction.order_id}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Gateway Id. :</b> {{transaction.gate_way_id}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Bank Ref. No. :</b> {{transaction.bank_ref_no}}</p>
                                                    <p class="card-text text-muted mb-1 uppercase"><b>Status :</b> {{transaction.order_status}}</p>
                                                    <p class="card-text text-muted mb-1"><b>Bank Message :</b> {{transaction.message}}</p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!locationHelper.isMobile()">
                            <div class="table-responsive">
                                <table class="table table-bordered mg-b-0 text-md-nowrap">
                                    <thead>
                                        <tr>
                                            <th width="3%">S.No.</th>
                                            <th width="15%">Date</th>
                                            <th width="15%">Purpose</th>
                                            <th width="10%">Amount</th>
                                            <th width="5%">Qty</th>
                                            <th width="10%">Payment Mode</th>
                                            <th width="10%">Order Id.</th>
                                            <th width="10%">Gateway Id.</th>
                                            <th width="10%">Bank Ref. No.</th>
                                            <th width="10%">Status</th>
                                            <th width="10%">Bank Message</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let transaction of transaction_list; let i=index;trackBy:formDataResolver.identify;">

                                            <tr [ngClass]="{'table-success': transaction.order_status.toUpperCase() === 'SUCCESS','table-danger': transaction.order_status.toUpperCase() !== 'SUCCESS' }">
                                                <td scope="row">
                                                    {{i+1}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{transaction.date | date:commonEnums.date_time_format}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{transaction.purpose}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{transaction.amount | currency:"INR":"Rs. "}}
                                                </td>

                                                <td scope="row">
                                                    {{transaction.no_of_items}}
                                                </td>
                                                <td scope="row">
                                                    <ng-container *ngIf="transaction.payment_mode!=='null'">
                                                        {{transaction.payment_mode}}
                                                    </ng-container>

                                                </td>
                                                <td scope="row">
                                                    {{transaction.order_id}}
                                                </td>

                                                <td scope="row">
                                                    {{transaction.gate_way_id}}
                                                </td>
                                                <td scope="row">
                                                    {{transaction.bank_ref_no}}
                                                </td>
                                                <td scope="row" class="uppercase">
                                                    {{transaction.order_status}}
                                                </td>
                                                <td scope="row">
                                                    {{transaction.message}}
                                                </td>

                                            </tr>

                                        </ng-container>
                                        <ng-container *ngIf="!transaction_list_loading && transaction_list.length==0">
                                            <tr>
                                                <th colspan="10" class="text-center">No Records found</th>
                                            </tr>
                                        </ng-container>
                                        <ng-container *ngIf="transaction_list_loading && transaction_list.length==0">
                                            <tr>
                                                <th colspan="10" class="text-center">Loading Please wait</th>
                                            </tr>
                                        </ng-container>


                                    </tbody>
                                </table>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>