<app-side-bar></app-side-bar>
<!-- main-content -->
<div class="main-content app-content">

    <app-combo-bar></app-combo-bar>


    <!-- container -->
    <div class="container-fluid">

        <div class="card mg-b-20" *ngIf="enrolled_list.length>0">
            <div class="card-body">
                <div class="alert alert-success" role="alert">
                    <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="spinner-grow text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <a href="/portal-helper">ANJAC Portal - Hostel & Bus Fee Online Payment Helper - Click Here!</a>
                </div>
            </div>
        </div>
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
            <div class="left-content">
                <div>
                    <h2 class="main-content-title tx-24 mg-b-1 mg-b-lg-1">My Profile</h2>
                    <p class="mg-b-0"></p>
                </div>
            </div>
        </div>

        <div class="row row-sm" *ngIf="active_admissions?.length == 0">
            <div class="col-lg-12">
                <div class="card mg-b-20">
                    <div class="card-body">
                        <div class="pl-0">
                            <div class="main-profile-overview " style="text-align: center;">
                                <div class="justify-content-between mg-b-20">
                                    <div>
                                        <h5 class="main-profile-name">No Active Admissions Found!</h5>
                                    </div>
                                </div>

                                <!--skill bar-->
                            </div><!-- main-profile-overview -->
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-sm" *ngIf="active_admissions?.length>0">
            <!-- row -->
            <div class="row row-sm" *ngIf="user_info">
                <ng-container [formGroup]="user_form">
                    <!-- Col -->
                    <div class="col-lg-12">
                        <div class="card mg-b-20">
                            <div class="card-body">
                                <div class="pl-0">
                                    <div class="main-profile-overview " style="text-align: center;">
                                        <div class="main-img-user profile-user">

                                            <ng-container *ngIf="user_form.value.gender=='male'">
                                                <img alt="" src="./assets/img/users/man-128.png">
                                            </ng-container>
                                            <ng-container *ngIf="user_form.value.gender=='female'">
                                                <img alt="" src="./assets/img/users/women-128.png">
                                            </ng-container>

                                        </div>
                                        <div class="justify-content-between mg-b-20">
                                            <div>
                                                <h5 class="main-profile-name">{{user_info.name}}</h5>
                                            </div>
                                        </div>

                                        <!--skill bar-->
                                    </div><!-- main-profile-overview -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- Col -->
                    <!-- <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12">
                                                <div class="form-group mb-0">
                                                    <label style="color: red;">Note: Applicants of UG Aided Programmes
                                                        are instructed to apply both in our College website as well as
                                                        in Tamil Nadu
                                                        Government Website.</label>
                                                    <label style="color: red;">குறிப்பு: எமது கல்லூரியில் அரசு உதவி
                                                        பெறும் இளங்கலை / இளம் அறிவியல் பாடப் பிரிவுகளில் சேர விரும்பும்
                                                        மாணவர்கள், கல்லூரியின்
                                                        இணையதளம் மற்றும் தமிழ்நாடு அரசின் இணையதளம் இரண்டிலும்
                                                        விண்ணப்பிக்கவும்.</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="col-md-12 mg-b-10" *ngIf="user_info.bought_application">
                        <button type="button" (click)="downloadProfile()" class="btn btn-info"
                            style="float: right;">Download
                            Profile</button>
                    </div>



                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">AADHAR Card Information</div>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="blood_group">AADHAR card number<small
                                                            style="color: red;">
                                                            *</small></label>
                                                    <input class="form-control" placeholder="AADHAR card number"
                                                        type="text" formControlName='aadhar_card_no'>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- <div class="col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="blood_group">Upload AADHAR Card Front image<small
                                                            style="color: red;"> *</small></label>
                                                    <input class="form-control" required="" type="file"
                                                        (change)="uploadImage($event,user_form, 'aadhar_card_no_file_front')"
                                                        accept="image/*">
                                                    <ng-container *ngIf='user_form.value.aadhar_card_no_file_front'>
                                                        <label for="blood_group">Preview Front</label>
                                                        <img style="width: 400px;height: 200px;"
                                                            [src]='user_form.value.aadhar_card_no_file_front' />
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="blood_group">Upload AADHAR Card Back image<small
                                                            style="color: red;"> *</small></label>
                                                    <input class="form-control" required="" type="file"
                                                        (change)="uploadImage($event,user_form, 'aadhar_card_no_file_back')"
                                                        accept="image/*">
                                                    <ng-container *ngIf='user_form.value.aadhar_card_no_file_back'>
                                                        <label for="blood_group">Preview Back</label>
                                                        <img style="width: 400px;height: 200px;"
                                                            [src]='user_form.value.aadhar_card_no_file_back' />
                                                    </ng-container>
                                                </div>
                                            </div> -->




                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">Personal Information</div>
                                <div class="row">

                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Full Name <small style="color: red;">
                                                    *</small></label>

                                            <div class="row mt-0">
                                                <div class="col-3">
                                                    <input class="form-control" placeholder="Inital" type="text"
                                                        formControlName='middle_name'>
                                                </div>
                                                <div class="col-9">
                                                    <input class="form-control" placeholder="Full Name" type="text"
                                                        formControlName='given_name'>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Date of Birth </label>
                                            <input class="form-control" type="date" formControlName='birth_date'>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">

                                        <div class="form-group">
                                            <label>Gender </label> <br>
                                            <div class="mg-t-5">
                                                <div class="custom-control custom-radio" style="display: inline;">
                                                    <input id="male" type="radio" class="custom-control-input"
                                                        value="male" name="gender" formControlName="gender">
                                                    <label class="custom-control-label" for="male">Male</label>
                                                </div>

                                                <div class="custom-control custom-radio"
                                                    style="display: inline; margin-left: 20px;">
                                                    <input id="female" type="radio" class="custom-control-input"
                                                        value="female" name="gender" formControlName="gender">
                                                    <label class="custom-control-label" for="female">Female</label>
                                                </div>
                                                <div class="custom-control custom-radio"
                                                    style="display: inline; margin-left: 20px;">
                                                    <input id="transgender" type="radio" class="custom-control-input"
                                                        value="transgender" name="gender" formControlName="gender">
                                                    <label class="custom-control-label"
                                                        for="transgender">Transgender</label>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label for="blood_group">Blood Group<small style="color: red;">
                                                    *</small></label>

                                            <select class="form-control " id="blood_group" name="blood_group"
                                                formControlName="blood_group">
                                                <option value="">Choose your Blood Group</option>
                                                <option
                                                    *ngFor="let blood_group of commonEnums.blood_groups;let i=index;trackBy:formDataResolver.identify;"
                                                    [ngValue]="blood_group">
                                                    {{blood_group}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group mg-t-5">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="physically_challanged" name="physically_challanged"
                                                    formControlName="physically_challanged">
                                                <label class="custom-control-label" for="physically_challanged">Are you
                                                    physically disabled?</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-12 col-lg-12" *ngIf="user_form.value.physically_challanged">
                                        <div class="form-group">
                                            <label for="physically_challanged_proof">Upload Evidence for Physical
                                                Disability
                                                <small style="color: red;">*</small>
                                            </label>
                                            <input class="form-control" required="" type="file"
                                                name="physically_challanged_proof"
                                                (change)="uploadImage($event,user_form,'physically_challanged_proof')"
                                                accept="image/*">
                                            <ng-container *ngIf='user_form.value.physically_challanged_proof'>
                                                <label for="blood_group">Physical Disability Evidence
                                                    Preview</label>
                                                <img style="width: 400px;height: 200px;"
                                                    [src]='user_form.value.physically_challanged_proof' />
                                            </ng-container>
                                        </div>
                                    </div>




                                    <!-- <div class="col-md-4 col-lg-4" *ngIf="user_form.value.physically_challanged">
                                                <div class="form-group mg-t-5">
                                                    <label for="nature_of_disability">Nature of disablity
                                                    </label>
                                                    <input type="text" name="nature_of_disability" class="form-control"
                                                        formControlName="nature_of_disability">
                                                </div>
                                            </div> -->

                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group mg-t-5">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="ex_service"
                                                    name="ex_service" formControlName="ex_service">
                                                <label class="custom-control-label" for="ex_service">Are you
                                                    son/daughter
                                                    of Ex-Service man?</label>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="this.user_form.value.ex_service==true">
                                        <br>
                                        <div class="col-md-6 col-lg-6">

                                            <div class="form-group">
                                                <label for="ex_service_rank">Ex-Service Man Rank<small
                                                        style="color: red;">
                                                        *</small></label>
                                                <input type="text" id="ex_service_rank" name="ex_service_rank"
                                                    class="form-control" formControlName="ex_service_rank">

                                            </div>
                                        </div>
                                        <div class="col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label for="ex_service_i_card_no">Ex-Service Man I-Card Number<small
                                                        style="color: red;"> *</small></label>
                                                <input type="text" id="ex_service_i_card_no" name="ex_service_i_card_no"
                                                    class="form-control" formControlName="ex_service_i_card_no">

                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12" *ngIf="user_form.value.ex_service">
                                            <div class="form-group">
                                                <label for="ex_service_proof">Upload Evidence for Ex-Service Man
                                                    <small style="color: red;">*</small>
                                                </label>
                                                <input class="form-control" required="" type="file"
                                                    name="ex_service_proof"
                                                    (change)="uploadImage($event,user_form,'ex_service_proof')"
                                                    accept="image/*">
                                                <ng-container *ngIf='user_form.value.ex_service_proof'>
                                                    <label for="blood_group">Ex-Service Man Proof
                                                        Preview</label>
                                                    <img style="width: 400px;height: 200px;"
                                                        [src]='user_form.value.ex_service_proof' />
                                                </ng-container>
                                            </div>
                                        </div>

                                    </ng-container>

                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group mg-t-5">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="sports_certs"
                                                    name="sports_certs" formControlName="sports_certs">
                                                <label class="custom-control-label" for="sports_certs">Have you
                                                    participated
                                                    in
                                                    Sports Event(s)
                                                    ?</label>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group mg-t-5">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="need_hostel"
                                                    name="need_hostel" formControlName="need_hostel">
                                                <label class="custom-control-label" for="need_hostel">Do you need Hostel
                                                    Facility?</label>
                                            </div>


                                        </div>
                                    </div>

                                    <div class="col-md-12 col-lg-12">
                                        <!-- *ngIf="this.user_form.value.gender.toLowerCase()=='female'" -->
                                        <div class="form-group mg-t-5">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="need_bus"
                                                    name="need_bus" formControlName="need_bus">
                                                <label class="custom-control-label" for="need_bus">Do you need
                                                    Transportation
                                                    Facility?</label>
                                            </div>


                                        </div>
                                    </div>
                                    <br>
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group mg-t-5">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input"
                                                    id="is_applicant_married" name="is_applicant_married"
                                                    formControlName="is_applicant_married">
                                                <label class="custom-control-label" for="is_applicant_married">Is
                                                    Applicant
                                                    Married?</label>
                                            </div>


                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="main-content-label tx-13 mg-b-25">
                                    Conatct
                                </div>
                                <div class="main-profile-contact-list">
                                    <div class="row">
                                        <div class="col-md-3 col-lg-3">
                                            <label>Father's / Guardian's Mobile Number <small style="color: red;">
                                                    *</small></label>
                                            <input class="form-control" placeholder="Mobile Number" type="text"
                                                formControlName='mobile_number'>
                                        </div>
                                        <div class="col-md-2 col-lg-2">
                                            <label>Applicant Mobile Number <small style="color: red;">
                                                    *</small></label>
                                            <input class="form-control" placeholder="Applicant Mobile Number"
                                                type="text" formControlName='mobile_number_2'>
                                        </div>
                                        <div class="col-md-2 col-lg-2">
                                            <label>Alternate Contact No. 2 </label>
                                            <input class="form-control" placeholder="Alternate Contact No. 2"
                                                type="text" formControlName='mobile_number_3'>
                                        </div>
                                        <div class="col-md-2 col-lg-2">
                                            <label>Alternate Contact No. 3</label>
                                            <input class="form-control" placeholder="Alternate Contact No. 3"
                                                type="text" formControlName='mobile_number_4'>
                                        </div>
                                        <div class="col-md-2 col-lg-2">
                                            <label>Alternate Contact No. 4</label>
                                            <input class="form-control" placeholder="Alternate Contact No. 4"
                                                type="text" formControlName='mobile_number_5'>
                                        </div>
                                    </div>
                                    <div class="mt-3"></div>

                                    <div class="row">
                                        <div class="col-md-3 col-lg-3">
                                            <label>Email <small style="color: red;">
                                                    *</small></label>
                                            <input class="form-control" placeholder="Email" type="text"
                                                formControlName='email'>
                                        </div>

                                    </div>
                                </div><!-- main-profile-contact-list -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">Nationality and Address</div>
                                <p class="mb-25">Enter your communication address, This will be used to send any
                                    official
                                    letter
                                    from college later.</p>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">
                                        <div class="row">
                                            <div class="col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label for="nationality">Nationality<small style="color: red;">
                                                            *</small></label>
                                                    <div class="custom-control custom-radio"
                                                        style="display: inline; margin-left: 20px;">
                                                        <input id="indian" type="radio" class="custom-control-input"
                                                            value="indian" name="nationality"
                                                            formControlName="nationality">
                                                        <label class="custom-control-label" for="indian">Indian</label>
                                                    </div>

                                                    <div class="custom-control custom-radio"
                                                        style="display: inline; margin-left: 20px;">
                                                        <input id="others" type="radio" class="custom-control-input"
                                                            value="others" name="nationality"
                                                            formControlName="nationality">
                                                        <label class="custom-control-label" for="others">Others</label>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container formGroupName="address"
                                        *ngIf="formDataResolver.getValueFromFromGroup(this.user_form,'nationality')=='indian'">
                                        <div class="col-md-12 col-lg-12"
                                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form,'nationality')=='others'">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label for="country">Select your Country<small
                                                                style="color: red;">
                                                                *</small></label>
                                                        <select class="form-control " id="country" name="country"
                                                            formControlName="country">
                                                            <option
                                                                *ngFor="let country of commonEnums.country_list;let i=index;trackBy:formDataResolver.identify;"
                                                                [ngValue]="country">
                                                                {{country}}
                                                            </option>
                                                        </select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">

                                                        <ng-container
                                                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'country')=='India'">

                                                            <label for="state">Select your state<small
                                                                    style="color: red;">
                                                                    *</small></label>
                                                            <select class="form-control " id="state" name="state"
                                                                formControlName="state">
                                                                <option
                                                                    *ngFor="let india_state of commonEnums.india_states;let i=index;trackBy:formDataResolver.identify;"
                                                                    [ngValue]="india_state">
                                                                    {{india_state}}
                                                                </option>
                                                            </select>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'country')!='India'">
                                                            <label for="state">State<small style="color: red;">
                                                                    *</small></label>
                                                            <input type="text" id="state" name="state"
                                                                class="form-control" formControlName="state"
                                                                appFormControlValidationMsg validationMsgId="state">
                                                        </ng-container>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <ng-container
                                                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'state')=='Tamil Nadu'">

                                                            <label for="district">Select your district<small
                                                                    style="color: red;">
                                                                    *</small></label>
                                                            <select class="form-control " id="district" name="district"
                                                                formControlName="district">
                                                                <option
                                                                    *ngFor="let district_in_tamil_nadu of commonEnums.districts_in_tamil_nadu;let i=index;trackBy:formDataResolver.identify;"
                                                                    [ngValue]="district_in_tamil_nadu">
                                                                    {{district_in_tamil_nadu}}
                                                                </option>
                                                            </select>
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="formDataResolver.getValueFromFromGroup(this.user_form.controls.address,'state')!='Tamil Nadu'">
                                                            <label for="district">District<small style="color: red;">
                                                                    *</small></label>
                                                            <input type="text" id="district" name="district"
                                                                class="form-control" formControlName="district">
                                                        </ng-container>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">

                                                        <label for="city">City<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" id="city" name="city" class="form-control"
                                                            formControlName="city">
                                                        <small class="text-muted d-block">Do not enter special
                                                            characters</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label for="pincode">Pincode<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" id="pincode" name="pincode"
                                                            class="form-control" formControlName="pincode">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label for="street">Enter the residential address <small
                                                                style="color: red;">
                                                                *</small></label>
                                                        <input type="text" id="street" name="street"
                                                            class="form-control" formControlName="street">
                                                        <small id="mobile_number_3_help_line"
                                                            class="text-muted d-block">
                                                            Enter door number, street name and other landmarks.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="formDataResolver.getValueFromFromGroup(this.user_form,'nationality')=='others'">
                                        <div class="col-md-12 col-lg-12">
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="form-group">
                                                        <label for="other_nationality_address">Enter the residential
                                                            address
                                                            <small style="color: red;">
                                                                *</small></label>
                                                        <textarea id="other_nationality_address"
                                                            name="other_nationality_address" class="form-control"
                                                            formControlName="other_nationality_address"></textarea>
                                                        <small id="other_nationality_address"
                                                            class="text-muted d-block">
                                                            Enter door number, street name, city/district/state/county
                                                            and
                                                            other landmarks.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">Identification Marks</div>
                                <p class="mb-25">Fill your Identification mark one by one, this will be used in your
                                    certificates. <i>Example: Mole on the right hand thumb finger; Scar on the
                                        forehead</i>
                                    (Required to fill atleast one)</p>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <ng-container formArrayName="identification_marks">
                                            <div
                                                *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form,'identification_marks'); let i=index;trackBy:formDataResolver.identify;">
                                                <div class="form-group">
                                                    <input type="text" id="identification_marks_{{i}}"
                                                        placeholder="Identification Mark {{i+1}}"
                                                        name="identification_marks_{{i}}" class="form-control"
                                                        [formControlName]="i">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">Information about Parent / Guardian</div>
                                <p class="mb-25">Fill up your Parent / Guardian details. This will help us for further
                                    process.</p>
                                <div class="row">
                                    <div class="col-xl-12">

                                        <div class="form-group">

                                            <div class="custom-control custom-radio"
                                                style="display: inline; margin-left: 20px;">
                                                <input id="parent" type="radio" class="custom-control-input"
                                                    value="parent" name="parental_status"
                                                    formControlName="parental_status">
                                                <label class="custom-control-label" for="parent">Parent</label>
                                            </div>

                                            <div class="custom-control custom-radio"
                                                style="display: inline; margin-left: 20px;">
                                                <input id="guardian" type="radio" class="custom-control-input"
                                                    value="guardian" name="parental_status"
                                                    formControlName="parental_status">
                                                <label class="custom-control-label" for="guardian">Guardian</label>
                                            </div>


                                        </div>
                                        <ng-container formArrayName="family_members">


                                            <div class="table-wrap">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <ng-container
                                                                    *ngIf="this.user_form.controls.parental_status.value=='parent'">
                                                                    <th style="width:3%">S.No.</th>

                                                                    <th style="width:15%">Parant</th>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="this.user_form.controls.parental_status.value=='guardian'">
                                                                    <th style="width:15%">Guardian Relationship</th>
                                                                </ng-container>
                                                                <th style="width:20%">Name</th>
                                                                <ng-container *ngIf="!locationHelper.isMobile()">
                                                                    <th style="width:20%">Occupation</th>
                                                                    <th style="width:15%">Monthly Income (Rs.)</th>
                                                                </ng-container>
                                                                <!-- <th>Contact No</th>
                                                                                                                                                        <th>Contact Email</th> -->
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form,'family_members'); let i=index;trackBy:formDataResolver.identify;">

                                                                <ng-container
                                                                    *ngIf="this.user_form.controls.parental_status.value=='parent' && (i==0 || i==1)">
                                                                    <tr>

                                                                        <ng-container [formGroupName]="i">
                                                                            <ng-container
                                                                                *ngIf="this.user_form.controls.parental_status.value=='parent'">
                                                                                <td>
                                                                                    {{i+1}}
                                                                                </td>
                                                                            </ng-container>
                                                                            <td>
                                                                                <div class="form-group">
                                                                                    <ng-container *ngIf="i==0">
                                                                                        <label for=""
                                                                                            style="margin-top: 11px;">Father</label>
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="i==1">
                                                                                        <label for=""
                                                                                            style="margin-top: 11px;">Mother</label>
                                                                                    </ng-container>

                                                                                </div>
                                                                            </td>
                                                                            <ng-container
                                                                                *ngIf="!locationHelper.isMobile()">
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <input type="text"
                                                                                            id="given_name"
                                                                                            name="given_name"
                                                                                            class="form-control"
                                                                                            formControlName="given_name">
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <input type="text"
                                                                                            id="occupation"
                                                                                            name="occupation"
                                                                                            class="form-control"
                                                                                            formControlName="occupation">
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <input type="number" min="0"
                                                                                            id="salary" name="salary"
                                                                                            class="form-control"
                                                                                            formControlName="salary">
                                                                                    </div>
                                                                                </td>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="locationHelper.isMobile()">
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <label for="street">Name</label>
                                                                                        <input type="text"
                                                                                            id="given_name"
                                                                                            name="given_name"
                                                                                            class="form-control"
                                                                                            formControlName="given_name">
                                                                                    </div>
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="street">Occupation</label>
                                                                                        <input type="text"
                                                                                            id="occupation"
                                                                                            name="occupation"
                                                                                            class="form-control"
                                                                                            formControlName="occupation">
                                                                                    </div>
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="street">Salary</label>
                                                                                        <input type="number" min="0"
                                                                                            id="salary" name="salary"
                                                                                            class="form-control"
                                                                                            formControlName="salary">
                                                                                    </div>
                                                                                </td>
                                                                            </ng-container>

                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="this.user_form.controls.parental_status.value=='guardian' && (i==2)">
                                                                    <tr>

                                                                        <ng-container [formGroupName]="i">


                                                                            <td>
                                                                                <ng-container *ngIf="i==2">
                                                                                    <select class="form-control "
                                                                                        id="relationship_name"
                                                                                        name="relationship_name"
                                                                                        formControlName="relationship_name">
                                                                                        <option
                                                                                            *ngFor="let relationship_name of commonEnums.relationship_names;let i=index;trackBy:formDataResolver.identify;"
                                                                                            [ngValue]="relationship_name">
                                                                                            {{relationship_name}}
                                                                                        </option>
                                                                                    </select>
                                                                                </ng-container>
                                                                            </td>
                                                                            <ng-container
                                                                                *ngIf="!locationHelper.isMobile()">
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <input type="text"
                                                                                            id="given_name"
                                                                                            name="given_name"
                                                                                            class="form-control"
                                                                                            formControlName="given_name">
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <input type="text"
                                                                                            id="occupation"
                                                                                            name="occupation"
                                                                                            class="form-control"
                                                                                            formControlName="occupation">
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <input type="number" min="0"
                                                                                            id="salary" name="salary"
                                                                                            class="form-control"
                                                                                            formControlName="salary">
                                                                                    </div>
                                                                                </td>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="locationHelper.isMobile()">
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <label for="street">Name</label>
                                                                                        <input type="text"
                                                                                            id="given_name"
                                                                                            name="given_name"
                                                                                            class="form-control"
                                                                                            formControlName="given_name">
                                                                                    </div>
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="street">Occupation</label>
                                                                                        <input type="text"
                                                                                            id="occupation"
                                                                                            name="occupation"
                                                                                            class="form-control"
                                                                                            formControlName="occupation">
                                                                                    </div>
                                                                                    <div class="form-group">
                                                                                        <label
                                                                                            for="street">Salary</label>
                                                                                        <input type="number" min="0"
                                                                                            id="salary" name="salary"
                                                                                            class="form-control"
                                                                                            formControlName="salary">
                                                                                    </div>
                                                                                </td>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </tr>
                                                                </ng-container>

                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>



                                        </ng-container>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">Community and Caste</div>
                                <div class="row">
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label for="community_id">Select Community<small style="color: red;">
                                                    *</small></label>

                                            <select class="form-control "
                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                id="community_id" name="community_id" formControlName="community_id">
                                                <option value="">Choose your community</option>
                                                <option
                                                    *ngFor="let community of commonEnums.community_list;let i=index;trackBy:formDataResolver.identify;"
                                                    [ngValue]="community">{{community}}
                                                </option>
                                            </select>


                                        </div>

                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label for="caste">Caste<small style="color: red;"> *</small></label>
                                            <input type="text" id="caste"
                                                [attr.disabled]="user_info.bought_application ? '' : null" name="caste"
                                                class="form-control" formControlName="caste">
                                            <small id="mobile_number_3_help_line" class="text-muted d-block">
                                                Enter as per community certifiate
                                            </small>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label for="religion">Religion<small style="color: red;"> *</small></label>
                                            <select class="form-control "
                                                [attr.disabled]="user_info.bought_application ? '' : null" id="religion"
                                                name="religion" formControlName="religion">
                                                <option value="">Choose your Religion</option>
                                                <option
                                                    *ngFor="let religion of commonEnums.religions;let i=index;trackBy:formDataResolver.identify;"
                                                    [ngValue]="religion">
                                                    {{religion}}
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                    <ng-container *ngIf="user_form.value.community_id!=='OC'">
                                        <div class="col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label for="blood_group">Upload Community Certificate image<small
                                                        style="color: red;"> *</small></label>
                                                <input class="form-control" required="" type="file"
                                                    (change)="uploadImage($event,user_form, 'community_id_file')"
                                                    accept="image/*">
                                                <ng-container *ngIf='user_form.value.community_id_file'>
                                                    <label for="blood_group">Preview</label>
                                                    <img style="width: 400px;height: 200px;"
                                                        [src]='user_form.value.community_id_file' />
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>


                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="mb-4 main-content-label">Programme Type</div>
                                <div class="row">
                                    <div class="col-md-12 col-lg-12">

                                        <div class="form-group">
                                            <label for="nationality">Would you like to Apply For<small
                                                    style="color: red;">
                                                    *</small></label>
                                            <ng-container *ngIf="locationHelper.isMobile()">
                                                <br />
                                            </ng-container>
                                            <div class="custom-control custom-radio"
                                                style="display: inline; margin-left: 20px;"
                                                *ngIf="isActiveAdmissionFound('UG')">
                                                <input id="ug" type="radio" class="custom-control-input" value="UG"
                                                    name="apply_for" formControlName="apply_for"
                                                    [attr.disabled]="user_info.bought_application ? '' : null">
                                                <label class="custom-control-label" for="ug"
                                                    [attr.disabled]="user_info.bought_application ? '' : null">UG
                                                    Programmes</label>
                                            </div>

                                            <div class="custom-control custom-radio"
                                                style="display: inline; margin-left: 20px;"
                                                *ngIf="isActiveAdmissionFound('PG')">
                                                <input id="pg" type="radio"
                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                    class="custom-control-input" value="PG" name="apply_for"
                                                    formControlName="apply_for">
                                                <label class="custom-control-label"
                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                    for="pg">PG
                                                    Programmes</label>
                                            </div>

                                            <div class="custom-control custom-radio"
                                                style="display: inline; margin-left: 20px;"
                                                *ngIf="isActiveAdmissionFound('PHD')">
                                                <input id="phd" type="radio"
                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                    class="custom-control-input" value="PHD" name="apply_for"
                                                    formControlName="apply_for">
                                                <label class="custom-control-label"
                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                    for="phd">Ph.D.
                                                    Programmes</label>
                                            </div>


                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <ng-container *ngIf="user_form.value.apply_for === 'UG'">


                        <!-- <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="mb-4 main-content-label">Academic Details : SSLC </div>
                                            <ng-container formGroupName="sslc_academics">
            
                                                <div class="row mg-t-30">
            
                                                    <ng-container formGroupName="accademic_info">
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label for="ex_service_rank">School Name<small style="color: red;">
                                                                        *</small></label>
                                                                <input type="text" name="school_name" class="form-control"
                                                                    formControlName="school_name">
            
                                                            </div>
            
                                                        </div>
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label for="ex_service_rank">Place of School<small
                                                                        style="color: red;">
                                                                        *</small></label>
                                                                <input type="text" name="school_place" class="form-control"
                                                                    formControlName="school_place">
            
                                                            </div>
            
                                                        </div>
                                                        <div class="col-md-6 col-md-6">
            
                                                            <div class="form-group">
                                                                <label for="ex_service_rank">Examination Registration Number<small
                                                                        style="color: red;">
                                                                        *</small></label>
                                                                <input type="text" id="exam_reg_no" name="exam_reg_no"
                                                                    class="form-control" formControlName="exam_reg_no">
                                                                <small id="mobile_number_3_help_line" class="text-muted d-block">
                                                                    Enter as per hall ticket
                                                                </small>
                                                            </div>
            
            
                                                        </div>
            
                                                        <div class="col-md-6 col-lg-6">
            
                                                            <div class="form-group">
                                                                <div class="row">
                                                                    <div class="col-md-6 form-group">
                                                                        <label for="month_of_passout">Month of Passing <small
                                                                                style="color: red;"> *</small>
                                                                        </label>
            
                                                                        <select class="form-control custom-select"
                                                                            formControlName="month_of_passout">
                                                                            <option *ngFor="let month of commonEnums.months"
                                                                                [ngValue]="month.id">
                                                                                {{month.text}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-md-6 form-group">
                                                                        <label for="year_of_passout">Year of Passing <small
                                                                                style="color: red;"> *</small>
                                                                        </label>
                                                                        <select class="form-control custom-select"
                                                                            formControlName="year_of_passout">
                                                                            <option
                                                                                *ngFor="let year of commonEnums.getLastNYears(10)"
                                                                                [ngValue]="year">
                                                                                {{year}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
            
                                                                </div>
            
                                                            </div>
            
                                                        </div>
            
            
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label for="syllabus_type">Board <small style="color: red;">
                                                                        *</small>
                                                                </label>
                                                                <select class="form-control"
                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                    id="syllabus_type" (change)="onSSLCBoardChange()"
                                                                    name="syllabus_type" formControlName="syllabus_type">
                                                                    <option
                                                                        *ngFor="let syllabus_type of commonEnums.sslc_syllabus_types;let i=index;trackBy:formDataResolver.identify;"
                                                                        [ngValue]="syllabus_type">
                                                                        {{syllabus_type}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="form-group mg-t-5">
                                                                <label for="medium_of_study">Medium of instruction<small
                                                                        style="color: red;">
                                                                        *</small></label>
                                                                <div class="row">
                                                                    <div class="col-md-12 col-lg-12">
                                                                        <div class="custom-control custom-radio"
                                                                            style="display: inline;">
                                                                            <input id="sslc_tamil" type="radio"
                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                class="custom-control-input" value="tamil"
                                                                                formControlName="medium_of_study">
                                                                            <label class="custom-control-label"
                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                for="sslc_tamil">Tamil</label>
                                                                        </div>
            
                                                                        <div class="custom-control custom-radio"
                                                                            style="display: inline; margin-left: 20px;">
                                                                            <input id="sslc_english" type="radio"
                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                class="custom-control-input" value="english"
                                                                                formControlName="medium_of_study">
                                                                            <label class="custom-control-label"
                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                for="sslc_english">English</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
            
                                                            </div>
            
                                                        </div>
                                                        <div class="col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label for="blood_group">Upload SSLC Mark Certificate<small
                                                                        style="color: red;"> *</small></label>
                                                                <input class="form-control" required="" type="file"
                                                                    (change)="uploadImage($event,user_form.controls.sslc_academics.controls.accademic_info, 'mark_statement_file')"
                                                                    accept="image/*">
                                                                <ng-container
                                                                    *ngIf='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file'>
                                                                    <label for="blood_group">Preview</label>
                                                                    <img style="width: 400px;height: 200px;"
                                                                        [src]='user_form.controls.sslc_academics.controls.accademic_info.value.mark_statement_file' />
                                                                </ng-container>
                                                            </div>
                                                        </div>
            
            
                                                    </ng-container>
            
            
            
            
            
            
                                                    <div class="col-md-12 col-lg-12">
                                                        <label for="ex_service_rank" class="mb-4 main-content-label">Mark
                                                            Details<small style="color: red;">
                                                                *</small></label>
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="60">S.No.</th>
            
                                                                        <ng-container *ngIf="!locationHelper.isMobile()">
                                                                            <th width="150">Subject Type</th>
                                                                            
                                                                            <th>Subject Name</th>
            
                                                                            <th width="150">Mark / Grade</th>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="locationHelper.isMobile()">
                                                                            <th></th>
                                                                        </ng-container>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
            
            
                                                                    <ng-container formArrayName="marks">
                                                                        <ng-container
                                                                            *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.sslc_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                                                            <ng-container [formGroup]='alias'>
                                                                                <tr>
            
                                                                                    <td>
                                                                                        {{i+1}}
                                                                                    </td>
                                                                                    <ng-container
                                                                                        *ngIf="!locationHelper.isMobile()">
                                                                                        <td>
                                                                                            <ng-container
                                                                                                *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                                                                                <select class="form-control "
                                                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                    formControlName="subject_type">
                                                                                                    <option value="">Choose</option>
                                                                                                    <option
                                                                                                        *ngFor="let icse_subject_type of commonEnums.icse_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                                                                        [ngValue]="icse_subject_type">
                                                                                                        {{icse_subject_type}}
                                                                                                    </option>
                                                                                                </select>
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
                                                                                                {{alias.value.subject_type}}
                                                                                                {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                                                                            </ng-container>
            
            
                                                                                        </td>
                                                                                        
                                                                                        <td>
                                                                                            <ng-container
                                                                                                *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
            
                                                                                                <ng-container
                                                                                                    *ngIf="alias.value.subject_type == 'LANGUAGE'">
            
                                                                                                    <ng-container
                                                                                                        *ngIf="alias.value.part_index == 1">
            
                                                                                                        {{alias.value.subject_name}}
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="alias.value.part_index == 0">
            
                                                                                                        <ng-container
                                                                                                            *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type === 'STATE_BOARD'">
                                                                                                            TAMIL
                                                                                                        </ng-container>
            
            
                                                                                                        <ng-container
                                                                                                            *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type !== 'STATE_BOARD'">
                                                                                                            <select
                                                                                                                class="form-control"
                                                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                                formControlName="subject_name">
                                                                                                                <option value="">
                                                                                                                    Choose Subject
                                                                                                                </option>
                                                                                                                <option
                                                                                                                    *ngFor="let language1_subject of commonEnums.language1_subjects;let i=index;trackBy:formDataResolver.identify;"
                                                                                                                    [ngValue]="language1_subject">
                                                                                                                    {{language1_subject}}
                                                                                                                </option>
                                                                                                            </select>
                                                                                                        </ng-container>
                                                                                                    </ng-container>
            
            
            
            
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="alias.value.subject_type != 'LANGUAGE'">
                                                                                                    {{alias.value.subject_name}}
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                            <ng-container
                                                                                                *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="subject_name">
                                                                                            </ng-container>
                                                                                        </td>
            
            
                                                                                        <td>
                                                                                            <input type="text"
                                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                class="form-control"
                                                                                                formControlName="mark">
                                                                                        </td>
            
                                                                                    </ng-container>
                                                                                    <ng-container *ngIf="locationHelper.isMobile()">
                                                                                        <td>
                                                                                            <div class="form-group">
                                                                                                <label>Subject Type : </label>
                                                                                                <ng-container
                                                                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                                                                                    <select class="form-control "
                                                                                                        [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                        formControlName="subject_type">
                                                                                                        <option value="">Choose
                                                                                                        </option>
                                                                                                        <option
                                                                                                            *ngFor="let icse_subject_type of commonEnums.icse_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                                                                            [ngValue]="icse_subject_type">
                                                                                                            {{icse_subject_type}}
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
                                                                                                    {{alias.value.subject_type}}
                                                                                                    {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                                                                                </ng-container>
                                                                                            </div>
                                                                                            <div class="form-group">
                                                                                                <label>Subject Name : </label>
                                                                                                <ng-container
                                                                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type!='ICSE'">
            
                                                                                                    <ng-container
                                                                                                        *ngIf="alias.value.subject_type == 'LANGUAGE'">
            
                                                                                                        <ng-container
                                                                                                            *ngIf="alias.value.part_index == 1">
            
                                                                                                            {{alias.value.subject_name}}
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="alias.value.part_index == 0">
            
                                                                                                            <ng-container
                                                                                                                *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type === 'STATE_BOARD'">
                                                                                                                TAMIL
                                                                                                            </ng-container>
            
            
                                                                                                            <ng-container
                                                                                                                *ngIf="user_form.value.sslc_academics.accademic_info.syllabus_type !== 'STATE_BOARD'">
                                                                                                                <select
                                                                                                                    class="form-control"
                                                                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                                    formControlName="subject_name">
                                                                                                                    <option
                                                                                                                        value="">
                                                                                                                        Choose
                                                                                                                        Subject
                                                                                                                    </option>
                                                                                                                    <option
                                                                                                                        *ngFor="let language1_subject of commonEnums.language1_subjects;let i=index;trackBy:formDataResolver.identify;"
                                                                                                                        [ngValue]="language1_subject">
                                                                                                                        {{language1_subject}}
                                                                                                                    </option>
                                                                                                                </select>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
            
            
            
            
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="alias.value.subject_type != 'LANGUAGE'">
                                                                                                        {{alias.value.subject_name}}
                                                                                                    </ng-container>
                                                                                                </ng-container>
                                                                                                <ng-container
                                                                                                    *ngIf="this.user_form.value.sslc_academics.accademic_info.syllabus_type=='ICSE'">
                                                                                                    <input type="text"
                                                                                                        class="form-control"
                                                                                                        formControlName="subject_name">
                                                                                                </ng-container>
                                                                                            </div>
                                                                                            <div class="form-group">
                                                                                                <label>Mark / Grade</label>
                                                                                                <input type="number"
                                                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                    class="form-control"
                                                                                                    formControlName="mark">
                                                                                            </div>
            
                                                                                        </td>
                                                                                    </ng-container>
                                                                                </tr>
            
            
            
            
            
                                                                            </ng-container>
                                                                        </ng-container>
            
            
            
            
                                                                    </ng-container>
                                                                </tbody>
                                                            </table>
                                                        </div>
            
                                                    </div>
                                                </div>
            
                                            </ng-container>
            
            
            
                                        </div>
            
                                    </div>
                                </div> -->



                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4 main-content-label">Academic Details : HSC </div>
                                    <ng-container formGroupName="hsc_academics">

                                        <div class="row mg-t-30">

                                            <ng-container formGroupName="accademic_info">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Last Studied School Name<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" name="school_name" class="form-control"
                                                            formControlName="school_name">

                                                    </div>

                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Place of School<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" name="school_place" class="form-control"
                                                            formControlName="school_place">

                                                    </div>

                                                </div>
                                                <div class="col-md-6 col-md-6">

                                                    <div class="form-group">
                                                        <label>Examination Roll Number<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" id="exam_reg_no" name="exam_reg_no"
                                                            class="form-control" formControlName="exam_reg_no">
                                                        <small id="mobile_number_3_help_line"
                                                            class="text-muted d-block">
                                                            Enter as per hall ticket
                                                        </small>
                                                    </div>


                                                </div>

                                                <div class="col-md-6 col-lg-6">

                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-6 form-group">
                                                                <label for="month_of_passout">Month of Passing <small
                                                                        style="color: red;"> *</small>
                                                                </label>

                                                                <select class="form-control custom-select"
                                                                    formControlName="month_of_passout">
                                                                    <option *ngFor="let month of commonEnums.months"
                                                                        [ngValue]="month.id">
                                                                        {{month.text}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6 form-group">
                                                                <label for="year_of_passout">Year of Passing <small
                                                                        style="color: red;"> *</small>
                                                                </label>
                                                                <select class="form-control custom-select"
                                                                    formControlName="year_of_passout">
                                                                    <option
                                                                        *ngFor="let year of commonEnums.getLastNYears(10)"
                                                                        [ngValue]="year">
                                                                        {{year}}
                                                                    </option>
                                                                </select>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Board <small style="color: red;"> *</small>
                                                        </label>
                                                        <select class="form-control "
                                                            [attr.disabled]="user_info.bought_application ? '' : null"
                                                            id="syllabus_type" name="syllabus_type"
                                                            formControlName="syllabus_type" (change)="onBoardChange()">
                                                            <option
                                                                *ngFor="let syllabus_type of commonEnums.syllabus_types;let i=index;trackBy:formDataResolver.identify;"
                                                                [ngValue]="syllabus_type">
                                                                {{syllabus_type}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <label>Medium of instruction<small style="color: red;">
                                                                *</small></label>
                                                        <div class="row">
                                                            <div class="col-md-12 col-lg-12">
                                                                <div class="custom-control custom-radio"
                                                                    style="display: inline;">
                                                                    <input id="hsc_tamil" type="radio"
                                                                        class="custom-control-input" value="tamil"
                                                                        formControlName="medium_of_study">
                                                                    <label class="custom-control-label"
                                                                        for="hsc_tamil">Tamil</label>
                                                                </div>

                                                                <div class="custom-control custom-radio"
                                                                    style="display: inline; margin-left: 20px;">
                                                                    <input id="hsc_english" type="radio"
                                                                        class="custom-control-input" value="english"
                                                                        formControlName="medium_of_study">
                                                                    <label class="custom-control-label"
                                                                        for="hsc_english">English</label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="col-md-12 col-lg-12">
                                                    <div class="form-group mg-t-5">
                                                        <label>School Type<small style="color: red;">
                                                                *</small></label>
                                                        <div class="row">
                                                            <div class="col-md-12 col-lg-12">
                                                                <div class="custom-control custom-radio"
                                                                    style="display: inline;">
                                                                    <input id="govt" type="radio"
                                                                        class="custom-control-input" value="GOVT"
                                                                        formControlName="school_type">
                                                                    <label class="custom-control-label"
                                                                        for="govt">Government</label>
                                                                </div>

                                                                <div class="custom-control custom-radio"
                                                                    style="display: inline; margin-left: 20px;">
                                                                    <input id="govt_aided" type="radio"
                                                                        class="custom-control-input" value="GOVT_AIDED"
                                                                        formControlName="school_type">
                                                                    <label class="custom-control-label"
                                                                        for="govt_aided">Government
                                                                        Aided</label>
                                                                </div>

                                                                <div class="custom-control custom-radio"
                                                                    style="display: inline; margin-left: 20px;">
                                                                    <input id="private" type="radio"
                                                                        class="custom-control-input" value="PRIVATE"
                                                                        formControlName="school_type">
                                                                    <label class="custom-control-label"
                                                                        for="private">Private</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ng-container
                                                    *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                    <div class="col-md-12 col-lg-12">
                                                        <div class="form-group mg-t-5">
                                                            <div class="custom-control custom-checkbox">
                                                                <input id="vocational"
                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                    type="checkbox" class="custom-control-input"
                                                                    formControlName="vocational"
                                                                    (change)="onBoardChange()">
                                                                <label class="custom-control-label"
                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                    for="vocational">Have you studied under Vocational
                                                                    Group
                                                                    (For commerce students only) ?</label>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div class="col-md-12 col-lg-12">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="first_graduate" type="checkbox"
                                                                class="custom-control-input"
                                                                formControlName="first_graduate">
                                                            <label class="custom-control-label" for="first_graduate">Are
                                                                you
                                                                the First Graduate in your family?</label>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="mark-certificate">Upload HSC Mark Certificate
                                                            <small style="color: red;"> *</small>
                                                        </label>
                                                        <input class="form-control" type="file" id="mark-certificate"
                                                            (change)="uploadImage($event,user_form.controls.hsc_academics.controls.accademic_info, 'mark_statement_file')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file'>
                                                            <label for="blood_group">Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.controls.hsc_academics.controls.accademic_info.value.mark_statement_file' />
                                                        </ng-container>
                                                    </div>
                                                </div>

                                            </ng-container>



                                            <div class="col-md-12 col-lg-12">
                                                <label for="ex_service_rank" class="mb-4 main-content-label">Mark
                                                    Details<small style="color: red;">
                                                        *</small></label>
                                                <br>
                                                <h3><b style="color: red;">Note: After publication of HSC Results, enter
                                                        your subject
                                                        marks in this table immediately</b></h3>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="60">S.No.</th>
                                                                <ng-container *ngIf="!locationHelper.isMobile()">

                                                                    <th width="150">Subject Type</th>
                                                                    <!-- <th width="150">Part Type</th> -->
                                                                    <th>Subject Name</th>
                                                                    <!-- <th width="150">+1 Mark / Grade <small>
                                                                                    Not mandatory</small></th> -->
                                                                    <th width="150">+2 Mark / Grade</th>
                                                                </ng-container>
                                                                <ng-container *ngIf="locationHelper.isMobile()">
                                                                    <th></th>
                                                                </ng-container>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            <ng-container formArrayName="marks">
                                                                <ng-container
                                                                    *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.hsc_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                                                    <ng-container [formGroup]='alias'>
                                                                        <tr>

                                                                            <td>
                                                                                {{i+1}}
                                                                            </td>
                                                                            <ng-container
                                                                                *ngIf="!locationHelper.isMobile()">
                                                                                <td>
                                                                                    <ng-container
                                                                                        *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                                                        {{alias.value.subject_type}}
                                                                                        {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                                                                        <select class="form-control "
                                                                                            [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                            formControlName="subject_type">
                                                                                            <option value="">Choose
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let cbsc_subject_type of commonEnums.cbsc_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                                                                [ngValue]="cbsc_subject_type">
                                                                                                {{cbsc_subject_type}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </ng-container>

                                                                                </td>
                                                                                <td>
                                                                                    <ng-container
                                                                                        *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                                                        <select class="form-control "
                                                                                            [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                            formControlName="subject_id">
                                                                                            <option value="">Choose
                                                                                            </option>
                                                                                            <option
                                                                                                *ngFor="let subject of getSchoolSubjectsByPartType(alias.value.part_type);let i=index;trackBy:formDataResolver.identify;"
                                                                                                [ngValue]="subject.subject_id">
                                                                                                {{subject.subject_name}}
                                                                                            </option>
                                                                                        </select>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                        *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                                                                        <input class="form-control"
                                                                                            [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                            required="" type="text"
                                                                                            formControlName="subject_name">
                                                                                    </ng-container>
                                                                                </td>


                                                                                <!-- <td>
                                                                                            <input type="text" class="form-control"
                                                                                                formControlName="mark_1">
                                                                                        </td> -->
                                                                                <td>
                                                                                    <!-- N/A -->
                                                                                    <input type="text"
                                                                                        class="form-control"
                                                                                        [attr.disabled]="user_info.mark_lock?'':null"
                                                                                        formControlName="mark">
                                                                                </td>

                                                                            </ng-container>

                                                                            <ng-container
                                                                                *ngIf="locationHelper.isMobile()">
                                                                                <td>
                                                                                    <div class="form-group">
                                                                                        <label>Subject Type</label>
                                                                                        <ng-container
                                                                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                                                            {{alias.value.subject_type}}
                                                                                            {{commonEnums.rommon_letters[alias.value.part_index+1]}}
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                                                                            <select
                                                                                                class="form-control "
                                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                formControlName="subject_type">
                                                                                                <option value="">Choose
                                                                                                </option>
                                                                                                <option
                                                                                                    *ngFor="let cbsc_subject_type of commonEnums.cbsc_subject_types;let i=index;trackBy:formDataResolver.identify;"
                                                                                                    [ngValue]="cbsc_subject_type">
                                                                                                    {{cbsc_subject_type}}
                                                                                                </option>
                                                                                            </select>
                                                                                        </ng-container>
                                                                                    </div>

                                                                                    <div class="form-group">
                                                                                        <label>Subject Name</label>
                                                                                        <ng-container
                                                                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type=='STATE_BOARD'">
                                                                                            <select
                                                                                                class="form-control "
                                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                formControlName="subject_id">
                                                                                                <option value="">Choose
                                                                                                </option>
                                                                                                <option
                                                                                                    *ngFor="let subject of getSchoolSubjectsByPartType(alias.value.part_type);let i=index;trackBy:formDataResolver.identify;"
                                                                                                    [ngValue]="subject.subject_id">
                                                                                                    {{subject.subject_name}}
                                                                                                </option>
                                                                                            </select>
                                                                                        </ng-container>
                                                                                        <ng-container
                                                                                            *ngIf="this.user_form.value.hsc_academics.accademic_info.syllabus_type!='STATE_BOARD'">
                                                                                            <input class="form-control"
                                                                                                [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                                required="" type="text"
                                                                                                formControlName="subject_name">
                                                                                        </ng-container>
                                                                                    </div>

                                                                                    <!-- <div class="form-group">
                                                                                                <label>+1 Mark / Grade <small>
                                                                                                        Not
                                                                                                        mandatory</small></label>
            
                                                                                                <input type="text"
                                                                                                    class="form-control"
                                                                                                    formControlName="mark_1">
            
                                                                                            </div> -->

                                                                                    <div class="form-group">
                                                                                        <label>+2 Mark / Grade</label>

                                                                                        <input type="text"
                                                                                            class="form-control"
                                                                                            [attr.disabled]="user_info.mark_lock?'':null"
                                                                                            formControlName="mark">

                                                                                    </div>



                                                                                </td>





                                                                            </ng-container>

                                                                        </tr>





                                                                    </ng-container>
                                                                </ng-container>




                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>



                                        </div>

                                    </ng-container>



                                </div>

                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="user_form.value.apply_for === 'PG'">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4 main-content-label">Academic Details : Degree </div>
                                    <ng-container formGroupName="degree_academics">

                                        <div class="row mg-t-30">

                                            <ng-container formGroupName="accademic_info">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Name of the college/university last
                                                            studied<small style="color: red;">
                                                                *</small></label>

                                                        <div class="row">

                                                            <div class="custom-control custom-radio"
                                                                style="display: inline; margin-left: 20px;">
                                                                <input id="true" type="radio"
                                                                    class="custom-control-input" value="false"
                                                                    name="is_anjac" formControlName="is_anjac">
                                                                <label class="custom-control-label"
                                                                    for="true">Others</label>
                                                            </div>
                                                            <ng-container
                                                                *ngIf='user_form.value.degree_academics.accademic_info.is_anjac=="false"'>
                                                                <input type="text" name="school_name"
                                                                    class="form-control mg-l-40"
                                                                    formControlName="school_name"
                                                                    placeholder="Name of the college/university last studied">
                                                            </ng-container>


                                                            <div class="custom-control custom-radio"
                                                                style="display: inline; margin-left: 20px;">
                                                                <input id="false" type="radio"
                                                                    class="custom-control-input" value="true"
                                                                    name="is_anjac" formControlName="is_anjac">
                                                                <label class="custom-control-label" for="false">Ayya
                                                                    Nadar
                                                                    Janaki Ammal College</label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Place<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" name="school_place" class="form-control"
                                                            formControlName="school_place">

                                                    </div>

                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Course Name<small
                                                                style="color: red;">
                                                                *</small></label>
                                                        <input type="text" name="course_name" class="form-control"
                                                            formControlName="course_name">

                                                    </div>

                                                </div>
                                                <div class="col-md-6 col-md-6">

                                                    <div class="form-group">
                                                        <label for="ex_service_rank">College/University Registration
                                                            Number
                                                            *<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" id="exam_reg_no" name="exam_reg_no"
                                                            class="form-control" formControlName="exam_reg_no">

                                                    </div>


                                                </div>

                                                <div class="col-md-6 col-lg-6">

                                                    <div class="form-group">
                                                        <div class="row">
                                                            <div class="col-md-6 form-group">
                                                                <label for="month_of_passout">Month of Passing <small
                                                                        style="color: red;"> *</small>
                                                                </label>

                                                                <select class="form-control custom-select"
                                                                    formControlName="month_of_passout">
                                                                    <option *ngFor="let month of commonEnums.months"
                                                                        [ngValue]="month.id">
                                                                        {{month.text}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6 form-group">
                                                                <label for="year_of_passout">Year of Passing <small
                                                                        style="color: red;"> *</small>
                                                                </label>
                                                                <select class="form-control custom-select"
                                                                    formControlName="year_of_passout">
                                                                    <option
                                                                        *ngFor="let year of commonEnums.getLastNYears(10)"
                                                                        [ngValue]="year">
                                                                        {{year}}
                                                                    </option>
                                                                </select>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>




                                                <div class="col-md-12 col-lg-12">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input id="first_graduate" type="checkbox"
                                                                class="custom-control-input"
                                                                formControlName="first_graduate">
                                                            <label class="custom-control-label" for="first_graduate">Are
                                                                you
                                                                the First Graduate in your family?</label>
                                                        </div>


                                                    </div>
                                                </div>

                                            </ng-container>



                                            <div class="col-md-12 col-lg-12">
                                                <label for="ex_service_rank" class="mb-4 main-content-label">Mark
                                                    Details<small style="color: red;">
                                                        *</small></label>
                                                <h4><b style="color: red;">Note: Enter your Part III Subjects Mark
                                                        only</b>
                                                </h4>
                                                <div class="table-responsive">
                                                    <table class="table table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="5%">S.No.</th>
                                                                <ng-container *ngIf="!locationHelper.isMobile()">
                                                                    <th width="20%">Semester</th>
                                                                    <th width="10%">No. of Subjects <span
                                                                            style="color: red;">(Only
                                                                            Part III)</span></th>
                                                                    <th width="10%">Total Marks</th>
                                                                    <th width="10%">Marks Obtained</th>
                                                                    <th width="30%">Upload Certificate</th>
                                                                </ng-container>
                                                                <ng-container *ngIf="locationHelper.isMobile()">
                                                                    <th></th>
                                                                </ng-container>
                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            <ng-container formArrayName="marks">
                                                                <tr
                                                                    *ngFor="let alias of formDataResolver.getFormArrayControlls(this.user_form.controls.degree_academics,'marks'); let i=index;trackBy:formDataResolver.identify;">
                                                                    <ng-container [formGroupName]="i">
                                                                        <td>
                                                                            {{i+1}}
                                                                        </td>

                                                                        <ng-container
                                                                            *ngIf="!locationHelper.isMobile()">
                                                                            <td>

                                                                                Semester {{
                                                                                commonEnums.rommon_letters[i+1]}}


                                                                            </td>
                                                                            <td>

                                                                                <select
                                                                                    class="form-control custom-select"
                                                                                    [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                    name="no_of_subjects"
                                                                                    formControlName="no_of_subjects">
                                                                                    <option
                                                                                        *ngFor="let no_of_subjects of commonEnums.getNumbers(10)"
                                                                                        [ngValue]="no_of_subjects">
                                                                                        {{no_of_subjects}}
                                                                                    </option>
                                                                                </select>



                                                                            </td>
                                                                            <td>

                                                                                {{formDataResolver.getValueFromFromGroup(alias,"no_of_subjects")*100}}


                                                                            </td>

                                                                            <td>
                                                                                <div class="form-group pt-15">

                                                                                    <input type="number" id="mark"
                                                                                        name="mark"
                                                                                        [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                        min="0" class="form-control"
                                                                                        formControlName="mark">

                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div class="col-md-6 col-lg-6">
                                                                                    <div class="form-group">
                                                                                        <label for="blood_group">Upload
                                                                                            Semester {{
                                                                                            commonEnums.rommon_letters[i+1]}}
                                                                                            Mark Statement</label>
                                                                                        <input class="form-control"
                                                                                            [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                            required="" type="file"
                                                                                            (change)="uploadImage($event,alias, 'semester_file')"
                                                                                            accept="image/*">
                                                                                        <ng-container
                                                                                            *ngIf='alias.value.semester_file'>
                                                                                            <label
                                                                                                for="blood_group">Preview</label>
                                                                                            <img style="width: 400px;height: 200px;"
                                                                                                [src]='alias.value.semester_file' />
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="locationHelper.isMobile()">
                                                                            <td>
                                                                                <div class="form-group">
                                                                                    <label>Semester</label>
                                                                                    {{ commonEnums.rommon_letters[i+1]}}

                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <label>No. of Subjects (Only Part
                                                                                        III)</label>
                                                                                    <select
                                                                                        class="form-control custom-select"
                                                                                        [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                        name="no_of_subjects"
                                                                                        formControlName="no_of_subjects">
                                                                                        <option
                                                                                            *ngFor="let no_of_subjects of commonEnums.getNumbers(10)"
                                                                                            [ngValue]="no_of_subjects">
                                                                                            {{no_of_subjects}}
                                                                                        </option>
                                                                                    </select>

                                                                                </div>

                                                                                <div class="form-group">
                                                                                    <label>Total Mark</label>
                                                                                    {{formDataResolver.getValueFromFromGroup(alias,"no_of_subjects")*100}}

                                                                                </div>
                                                                                <div class="form-group">
                                                                                    <label>Mark Obtained</label>
                                                                                    <input type="number" id="mark"
                                                                                        name="mark"
                                                                                        [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                        min="0" class="form-control"
                                                                                        formControlName="mark">

                                                                                </div>
                                                                                <div class="col-md-12 col-lg-12">
                                                                                    <div class="form-group">
                                                                                        <label for="blood_group">Upload
                                                                                            Semester {{
                                                                                            commonEnums.rommon_letters[i+1]}}
                                                                                            Certificate image</label>
                                                                                        <input class="form-control"
                                                                                            [attr.disabled]="user_info.bought_application ? '' : null"
                                                                                            required="" type="file"
                                                                                            (change)="uploadImage($event,alias, 'semester_file')"
                                                                                            accept="image/*">
                                                                                        <ng-container
                                                                                            *ngIf='alias.value.semester_file'>
                                                                                            <label
                                                                                                for="blood_group">Preview</label>
                                                                                            <img style="width: 400px;height: 200px;"
                                                                                                [src]='alias.value.semester_file' />
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>



                                                                            </td>

                                                                        </ng-container>
                                                                    </ng-container>
                                                                </tr>
                                                            </ng-container>

                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>



                                        </div>

                                    </ng-container>



                                </div>

                            </div>
                        </div>
                    </ng-container>


                    <!--New PhD admission-->
                    <ng-container *ngIf="user_form.value.apply_for === 'PHD'">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="mb-4 main-content-label">Academic Details : Degree </div>
                                    <ng-container formGroupName="phd_academics">
                                        <div class="row mg-t-30">
                                            <ng-container formGroupName="accademic_info">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Name of the college/university last
                                                            studied<small style="color: red;">
                                                                *</small></label>

                                                        <div class="row">

                                                            <div class="custom-control custom-radio"
                                                                style="display: inline; margin-left: 20px;">
                                                                <input id="true" type="radio"
                                                                    class="custom-control-input" value="false"
                                                                    name="is_anjac" formControlName="is_anjac">
                                                                <label class="custom-control-label"
                                                                    for="true">Others</label>
                                                            </div>
                                                            <ng-container
                                                                *ngIf='user_form.value.degree_academics.accademic_info.is_anjac=="false"'>
                                                                <input type="text" name="school_name"
                                                                    class="form-control mg-l-40"
                                                                    formControlName="school_name"
                                                                    placeholder="Name of the college/university last studied">
                                                            </ng-container>


                                                            <div class="custom-control custom-radio"
                                                                style="display: inline; margin-left: 20px;">
                                                                <input id="false" type="radio"
                                                                    class="custom-control-input" value="true"
                                                                    name="is_anjac" formControlName="is_anjac">
                                                                <label class="custom-control-label" for="false">Ayya
                                                                    Nadar
                                                                    Janaki Ammal College</label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Place<small style="color: red;">
                                                                *</small></label>
                                                        <input type="text" name="school_place" class="form-control"
                                                            formControlName="school_place">

                                                    </div>

                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-container formGroupName="qualifiying_info">
                                            <div><b>Qualifications </b></div>
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Name of the Qualifying Examination
                                                            passed / appeared
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="qualifying_exam" class="form-control"
                                                            formControlName="qualifying_exam">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label for="qualifying_exam_appearing_month">Month of
                                                        Passing/Appearing
                                                        <small style="color: red;">*</small>
                                                    </label>

                                                    <select class="form-control custom-select"
                                                        formControlName="qualifying_exam_appearing_month">
                                                        <option *ngFor="let month of commonEnums.months"
                                                            [ngValue]="month.id">
                                                            {{month.text}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 form-group">
                                                    <label for="qualifying_exam_appearing_year">Year of
                                                        Passing/Appearing
                                                        <small style="color: red;">*</small>
                                                    </label>
                                                    <select class="form-control custom-select"
                                                        formControlName="qualifying_exam_appearing_year">
                                                        <option *ngFor="let year of commonEnums.getLastNYears(30)"
                                                            [ngValue]="year">
                                                            {{year}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Register No.
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="qualifying_exam_register_no"
                                                            class="form-control"
                                                            formControlName="qualifying_exam_register_no">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">No. of sittings
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="qualifying_exam_no_of_sittings"
                                                            class="form-control"
                                                            formControlName="qualifying_exam_no_of_sittings">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="ex_service_rank">Distinctions, if any
                                                        </label>
                                                        <textarea name="qualifying_exam_distinctions"
                                                            class="form-control"
                                                            formControlName="qualifying_exam_distinctions">
                                                                </textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="qualifying_exam_proof">Upload Certificate of Proof
                                                            for
                                                            Qualifying Exam
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="qualifying_exam_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'qualifying_exam_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.qualifying_exam_proof'>
                                                            <label for="blood_group">Qualifying Exam Proof
                                                                Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.qualifying_exam_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="mku_entrance_qualified"
                                                                class="custom-control-input" id="mku_entrance_qualified"
                                                                formControlName="mku_entrance_qualified">
                                                            <label class="custom-control-label"
                                                                for="mku_entrance_qualified">Whether
                                                                qualified in Madurai
                                                                Kamaraj University Ph.D.
                                                                entrance test conducted by MKU, Madurai? <small
                                                                    style="color: red;">*</small></label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="net_set_qualified"
                                                                id="net_set_qualified"
                                                                formControlName="net_set_qualified"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="net_set_qualified">Whether
                                                                qualified in NET-LS
                                                                / GATE / SET / JAM
                                                                examinations
                                                                etc.? <small style="color: red;">*</small></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.qualifiying_info.mku_entrance_qualified">
                                                    <div class="form-group">
                                                        <label for="mku_entrance_qualified_proof">Upload Certificate of
                                                            Proof for MKU Entrance Pass Qualification
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="mku_entrance_qualified_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'mku_entrance_qualified_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.mku_entrance_qualified_proof'>
                                                            <label for="blood_group">MKU Entrance Qualified Proof
                                                                Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.mku_entrance_qualified_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.qualifiying_info.net_set_qualified">
                                                    <div class="form-group">
                                                        <label for="net_set_qualified_proof">Upload Certificate of Proof
                                                            for
                                                            NET-LS
                                                            / GATE / SET / JAM Qualifications
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="net_set_qualified_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'net_set_qualified_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.net_set_qualified_proof'>
                                                            <label for="blood_group">NET/SET Qualified Proof
                                                                Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.net_set_qualified_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="published_research_articles"
                                                                id="published_research_articles"
                                                                formControlName="published_research_articles"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="published_research_articles">Whether published
                                                                research
                                                                articles in Web of Science /
                                                                Scopous / PuBMed / FT-45 / Indian Citation indexed
                                                                Journals?
                                                                <small style="color: red;">*</small>
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="rank_medal_received"
                                                                id="rank_medal_received"
                                                                formControlName="rank_medal_received"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="rank_medal_received">Whether
                                                                awarded with rank
                                                                certificate / Medal for
                                                                achievements in core area? <small
                                                                    style="color: red;">*</small></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.qualifiying_info.published_research_articles">
                                                    <div class="form-group">
                                                        <label for="published_research_articles_proof">Upload
                                                            Certificate of
                                                            Proof for research
                                                            articles in Web of Science /
                                                            Scopous / PuBMed / FT-45 / Indian Citation indexed Journals
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="published_research_articles_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'published_research_articles_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.published_research_articles_proof'>
                                                            <label for="blood_group">Research Articles Qualified
                                                                Proof Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.published_research_articles_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.qualifiying_info.rank_medal_received">
                                                    <div class="form-group">
                                                        <label for="rank_medal_received_proof">Upload Certificate of
                                                            Proof
                                                            for awarded with rank
                                                            certificate / Medal for
                                                            achievements in core area?
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="rank_medal_received_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'rank_medal_received_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.rank_medal_received_proof'>
                                                            <label for="blood_group">Rank Medal Proof Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.rank_medal_received_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="summer_project_completed"
                                                                id="summer_project_completed"
                                                                formControlName="summer_project_completed"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="summer_project_completed">Whether completed Summer
                                                                Project / Training in reputed
                                                                institute / organization? <small
                                                                    style="color: red;">*</small></label>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="tnscst_project_awarded"
                                                                id="tnscst_project_awarded"
                                                                formControlName="tnscst_project_awarded"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="tnscst_project_awarded">Whether
                                                                awarded with TNSCST
                                                                Student Project Scheme? <small
                                                                    style="color: red;">*</small></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.qualifiying_info.summer_project_completed">
                                                    <div class="form-group">
                                                        <label for="summer_project_completed_proof">Upload Certificate
                                                            of
                                                            Proof for Summer
                                                            Project / Training in reputed
                                                            institute / organization?
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="summer_project_completed_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'summer_project_completed_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.summer_project_completed_proof'>
                                                            <label for="blood_group">Summer Project Completion Proof
                                                                Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.summer_project_completed_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.qualifiying_info.tnscst_project_awarded">
                                                    <div class="form-group">
                                                        <label for="tnscst_project_awarded_proof">Upload Certificate of
                                                            Proof for awarded with TNSCST
                                                            Student Project Scheme
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="tnscst_project_awarded_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.qualifiying_info, 'tnscst_project_awarded_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.qualifiying_info.tnscst_project_awarded_proof'>
                                                            <label for="blood_group">TNSCST Project Awarded Proof
                                                                Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.qualifiying_info.tnscst_project_awarded_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group mg-t-5">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" name="papers_in_conferences"
                                                                id="papers_in_conferences"
                                                                formControlName="papers_in_conferences"
                                                                class="custom-control-input">
                                                            <label class="custom-control-label"
                                                                for="papers_in_conferences">Whether
                                                                presented papers in
                                                                Conference / Seminar at National
                                                                / International Level? <small
                                                                    style="color: red;">*</small></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>
                                        <div><b>Teaching Experience</b></div>
                                        <div class="row mg-t-5">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="has_teaching_experience"
                                                            id="has_teaching_experience"
                                                            formControlName="has_teaching_experience"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label"
                                                            for="has_teaching_experience">State
                                                            whether applicant has any
                                                            teaching
                                                            experience?
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container formGroupName="experience_info"
                                            *ngIf="user_form.value.phd_academics.has_teaching_experience">
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="teaching_experience_institution">Name of the
                                                            Institution
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="teaching_experience_institution"
                                                            class="form-control"
                                                            formControlName="teaching_experience_institution">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="teaching_experience_post_nature">Nature of post
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="teaching_experience_post_nature"
                                                            class="form-control"
                                                            formControlName="teaching_experience_post_nature">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="teaching_experience_years">Experience
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="teaching_experience_years"
                                                            class="form-control"
                                                            formControlName="teaching_experience_years">
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>


                                        <span><b>Academic attainment: Details of marks obtained in B.A. / B.Com. /
                                                B.B.A. /
                                                B.Sc. and M.A. / M.Com. / M.Sc. Examinations
                                                (Attested photo copies of Mark statement must be submitted along with
                                                the
                                                application)</b></span>
                                        <ng-container formArrayName="academic_attainment">
                                            <div class="table-responsive">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No</th>
                                                            <th>Examination Passed <small style="color: red;">*</small>
                                                            </th>
                                                            <th>Year <small style="color: red;">*</small></th>
                                                            <th>Institution <small style="color: red;">*</small></th>
                                                            <th>University <small style="color: red;">*</small></th>
                                                            <th>Subjects studied <small style="color: red;">*</small>
                                                            </th>
                                                            <th>Percentage obtained <small style="color: red;">*</small>
                                                            </th>
                                                            <th>Distinction if any</th>
                                                            <th>Proof <small style="color: red;">*</small></th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <!--*ngFor="let course of formDataResolver.getFormArrayControlls(this.user_form.controls.phd_academics,'academic_attainment'); let i=index;trackBy:formDataResolver.identify;"-->
                                                        <tr *ngFor="let course of PHDAcademicAttainmentControls; let i=index;"
                                                            [formGroupName]="i">
                                                            <td>{{i+1}}</td>
                                                            <td>
                                                                <input type="text" name="examination_passed"
                                                                    class="form-control"
                                                                    formControlName="examination_passed">
                                                            </td>
                                                            <td>
                                                                <input type="text" name="year" class="form-control"
                                                                    formControlName="year">
                                                            </td>
                                                            <td>
                                                                <input type="text" name="institution"
                                                                    class="form-control" formControlName="institution">
                                                            </td>
                                                            <td>
                                                                <input type="text" name="university"
                                                                    class="form-control" formControlName="university">
                                                            </td>
                                                            <td>
                                                                <textarea name="subjects_studied" class="form-control"
                                                                    formControlName="subjects_studied"></textarea>
                                                            </td>
                                                            <td>
                                                                <input type="text" name="percentage"
                                                                    class="form-control" formControlName="percentage">
                                                            </td>
                                                            <td>
                                                                <input type="text" name="distinction"
                                                                    class="form-control" formControlName="distinction">
                                                            </td>
                                                            <td>
                                                                <input class="form-control" required="" type="file"
                                                                    (change)="uploadImage($event,course, 'proof')"
                                                                    accept="image/*"><br>
                                                                <ng-container *ngIf="course.value.proof">
                                                                    <span style="color:green">Uploaded</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>
                                                                <button class="btn btn-sm btn-danger"
                                                                    (click)="deletePHDAcademicAttainment(i)">Delete</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="10" class="text-center">
                                                                <button class="btn btn-primary"
                                                                    (click)="addPHDAcademicAttainment()">Add
                                                                    More</button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </ng-container>

                                        <div><b>Project & Research Details</b></div>
                                        <div class="row mg-t-5">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="has_submitted_project"
                                                            id="has_submitted_project"
                                                            formControlName="has_submitted_project"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label"
                                                            for="has_submitted_project">State
                                                            whether the applicant
                                                            submitted project report during M.A. / M.Com. / M.Sc. /
                                                            M.Phil.
                                                            Degree courses
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container formGroupName="project_info"
                                            *ngIf="user_form.value.phd_academics.has_submitted_project">
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="title_of_project">Title of the Project Report
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="title_of_project" class="form-control"
                                                            formControlName="title_of_project">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="name_designation_of_guide">Name and Designation of
                                                            the
                                                            guide / supervisor
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="name_designation_of_guide"
                                                            class="form-control"
                                                            formControlName="name_designation_of_guide">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="guide_testimonial_proof">Upload A testimonial from
                                                            the
                                                            Guide / Supervisor if possible
                                                        </label>
                                                        <input class="form-control" required="" type="file"
                                                            name="guide_testimonial_proof"
                                                            (change)="uploadImage($event,user_form.controls.phd_academics.controls.project_info, 'guide_testimonial_proof')"
                                                            accept="image/*">
                                                        <ng-container
                                                            *ngIf='user_form.value.phd_academics.project_info.guide_testimonial_proof'>
                                                            <label for="blood_group">Guide Testimonial Proof
                                                                Preview</label>
                                                            <img style="width: 400px;height: 200px;"
                                                                [src]='user_form.value.phd_academics.project_info.guide_testimonial_proof' />
                                                        </ng-container>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>

                                        <div class="row mg-t-5">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="has_research_experience"
                                                            id="has_research_experience"
                                                            formControlName="has_research_experience"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label"
                                                            for="has_research_experience">State
                                                            Applicant has any Research
                                                            experience?
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container formGroupName="research_experience"
                                            *ngIf="user_form.value.phd_academics.has_research_experience">
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="title_of_project">Details of research papers
                                                            published,
                                                            if any
                                                        </label>
                                                        <textarea name="research_paper_published" class="form-control"
                                                            formControlName="research_paper_published"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="name_designation_of_guide">Details of papers
                                                            presented,
                                                            if any
                                                        </label>
                                                        <textarea name="details_of_papers_presented"
                                                            class="form-control"
                                                            formControlName="details_of_papers_presented"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="name_designation_of_guide">Details of Summer
                                                            Training /
                                                            Workshop attended, if any
                                                        </label>
                                                        <textarea name="summer_training_workshop_attended"
                                                            class="form-control"
                                                            formControlName="summer_training_workshop_attended"></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>

                                        <div class="row mg-t-10">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group">
                                                    <label for="fields_of_research_interest">Details of Broad fields of
                                                        research interest
                                                        <small style="color: red;">*</small>
                                                    </label>
                                                    <textarea name="fields_of_research_interest" class="form-control"
                                                        formControlName="fields_of_research_interest"></textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div><b>Bank Details</b></div>
                                        <ng-container formGroupName="bank_details">
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="bank_name">Bank Name
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="bank_name" class="form-control"
                                                            formControlName="bank_name">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="place">Place
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="place" class="form-control"
                                                            formControlName="place">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="account_number">Account Number
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="account_number" class="form-control"
                                                            formControlName="account_number">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="staff_department">IFSC Code
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="ifsc_code" class="form-control"
                                                            formControlName="ifsc_code">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="micr_code">MICR Code
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="micr_code" class="form-control"
                                                            formControlName="micr_code">
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>


                                        <div><b>Hostel / Accommodation Details</b></div>
                                        <div class="row mg-t-5">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="hostel_needed" id="hostel_needed"
                                                            formControlName="hostel_needed"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label" for="hostel_needed">Does the
                                                            applicant need accommodation in the Hostel ?
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="!user_form.value.phd_academics.hostel_needed"
                                            formGroupName="accommodation_details">
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="name_of_guardian">Name of the local guardian
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="name_of_guardian" class="form-control"
                                                            formControlName="name_of_guardian">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="relationship">Relationship
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="relationship" class="form-control"
                                                            formControlName="relationship">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="residential_address">Residential Address
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <textarea name="residential_address" class="form-control"
                                                            formControlName="residential_address"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="residential_phone_std_code">Residential Address'
                                                            Phone
                                                            no. with STD Code
                                                        </label>
                                                        <input type="text" name="residential_phone_std_code"
                                                            class="form-control"
                                                            formControlName="residential_phone_std_code">
                                                    </div>
                                                </div>

                                                <div class="col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="residential_address_mobile_no">Mobile No.
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="residential_address_mobile_no"
                                                            class="form-control"
                                                            formControlName="residential_address_mobile_no">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mg-t-10">
                                                <div class="col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="official_address">Offical Address
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <textarea name="official_address" class="form-control"
                                                            formControlName="official_address"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="official_std_code">Official Address' Phone no. with
                                                            STD
                                                            Code
                                                        </label>
                                                        <input type="text" name="official_std_code" class="form-control"
                                                            formControlName="official_std_code">
                                                    </div>
                                                </div>

                                                <div class="col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="official_mobile_no">Official Address' Mobile No.
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="official_mobile_no"
                                                            class="form-control" formControlName="official_mobile_no">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>


                                        <div><b>Reference Details</b></div>
                                        <div class="row mg-t-5">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="applicant_has_staff_reference"
                                                            id="applicant_has_staff_reference"
                                                            formControlName="applicant_has_staff_reference"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label"
                                                            for="applicant_has_staff_reference">If
                                                            any of the applicant’s
                                                            relatives had been / is a student or a member of the staff
                                                            of
                                                            this college?
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container formGroupName="applicant_reference_details"
                                            *ngIf="user_form.value.phd_academics.applicant_has_staff_reference">
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="name_of_staff">Name of the staff
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="name_of_staff" class="form-control"
                                                            formControlName="name_of_staff">
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="relationship">Relationship
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="relationship" class="form-control"
                                                            formControlName="relationship">
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label for="staff_department">Department to which the staff
                                                            belongs
                                                            to
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <input type="text" name="staff_department" class="form-control"
                                                            formControlName="staff_department">
                                                    </div>
                                                </div>

                                            </div>
                                        </ng-container>

                                        <div><b>Misc. Details</b></div>
                                        <div class="row mg-t-10">
                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="had_disciplinary_action"
                                                            id="had_disciplinary_action"
                                                            formControlName="had_disciplinary_action"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label"
                                                            for="had_disciplinary_action">Has the
                                                            applicant been subjected
                                                            to any disciplinary action either by the School/ College or
                                                            the
                                                            Hostel authorities at
                                                            any time?<small style="color: red;">*</small></label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6">
                                                <div class="form-group mg-t-5">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="had_scholarship"
                                                            id="had_scholarship" formControlName="had_scholarship"
                                                            class="custom-control-input">
                                                        <label class="custom-control-label" for="had_scholarship">Was
                                                            the
                                                            applicant in receipt of any fee concession or scholarship in
                                                            the
                                                            college in which he/she studied last? If so,
                                                            the nature of the same with particular reference to the
                                                            proceedings of the authority sanctioning the award may be
                                                            stated. <small style="color: red;">*</small></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container>
                                            <div class="row mg-t-10">
                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.had_disciplinary_action">
                                                    <div class="form-group">
                                                        <label for="disciplinary_action">Details of disciplinary action,
                                                            if
                                                            any <small style="color: red;">*</small>
                                                        </label>
                                                        <textarea name="disciplinary_action" class="form-control"
                                                            formControlName="disciplinary_action"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-6"
                                                    *ngIf="user_form.value.phd_academics.had_scholarship">
                                                    <div class="form-group">
                                                        <label for="scholarship">Details of papers Scholarship,
                                                            if any
                                                            <small style="color: red;">*</small>
                                                        </label>
                                                        <textarea name="scholarship" class="form-control"
                                                            formControlName="scholarship"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </ng-container>



                                </div>

                            </div>
                        </div>
                    </ng-container>


                    <!--End of PhD-->

                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-footer text-center">
                                <button type="submit" class="btn btn-success waves-effect waves-light"
                                    [attr.disabled]="user_form.value.apply_for ? null: ''"
                                    (click)="previewProfile()">Save
                                    and
                                    Apply</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- /Col -->
            </div>
            <div class="row row-sm" *ngIf="!user_info">
                <div class="col">
                    <div class="alert alert-info" role="alert">
                        <button aria-label="Close" class="close" data-dismiss="alert" type="button">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        Loading Please wait...
                    </div>
                </div>
            </div>
            <!-- row closed -->
        </div>



        <!--Preview Modal-->
        <div class="modal" id="modal-popup">
            <div class="modal-dialog big-modal" role="document" *ngIf="popup_mode == 'preview' ">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">Preview Application
                        </h6>
                    </div>
                    <div class="modal-body">
                        <div class="card card-body pd-20 pd-md-40">
                            <div id="profile-view" class="mg-b-10">
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <h4>Ayya Nadar Janaki Ammal College <i>(Autonomous)</i>, Sivakasi.</h4>
                                        <h5>Admission 2024</h5>
                                    </div>
                                </div>
                                <p class="text-center"><b>Applicant Profile</b></p>
                                <p class="mg-b-10">Applied Graduation Type: <b>{{preview_info.apply_for |
                                        uppercase}}</b>
                                </p>
                                <p><b>Personal Information</b></p>
                                <div class="row table-row">
                                    <div class="col-md-4 table-cell">
                                        <span>Applicant Name: <b>{{preview_info.given_name | uppercase}}
                                                {{preview_info.middle_name | uppercase}}</b></span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Aadhar No.: <b>{{preview_info.aadhar_card_no}}</b></span><br>
                                        <!-- <span
                                            *ngIf="preview_info.aadhar_card_no_file_front && preview_info.aadhar_card_no_file_back">
                                            (Aadhar Card <a [href]="preview_info.aadhar_card_no_file_front"
                                                target="blank">Front</a> and
                                            <a [href]="preview_info.aadhar_card_no_file_back" target="blank">Back</a>
                                            Copy
                                            Attached)
                                        </span> -->
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Applicant Email: <b>{{preview_info.email}}</b></span>
                                    </div>
                                </div>
                                <div class="row table-row">
                                    <div class="col-md-2 table-cell">Mobile No: <b>{{preview_info.mobile_number}}</b>
                                    </div>
                                    <div class="col-md-2 table-cell">Alternate Mobile No 1:
                                        <span *ngIf="preview_info.mobile_number2">
                                            <b>{{preview_info.mobile_number2}}</b></span>
                                        <span *ngIf="!preview_info.mobile_number2">
                                            ---</span>
                                    </div>
                                    <div class="col-md-2 table-cell">Alternate Mobile No 2:
                                        <span *ngIf="preview_info.mobile_number3">
                                            <b>{{preview_info.mobile_number3}}</b></span>
                                        <span *ngIf="!preview_info.mobile_number3">
                                            ---</span>
                                    </div>
                                    <div class="col-md-2 table-cell">Alternate Mobile No 3:
                                        <span *ngIf="preview_info.mobile_number4">
                                            <b>{{preview_info.mobile_number4}}</b></span>
                                        <span *ngIf="!preview_info.mobile_number4">
                                            ---</span>
                                    </div>
                                    <div class="col-md-2 table-cell">Alternate Mobile No 4:
                                        <span *ngIf="preview_info.mobile_number5">
                                            <b>{{preview_info.mobile_number5}}</b></span>
                                        <span *ngIf="!preview_info.mobile_number5">
                                            ---</span>
                                    </div>
                                </div>

                                <div class="row table-row">
                                    <div class="col-md-4 table-cell">
                                        <span>Gender: <b>{{preview_info.gender | titlecase}}</b></span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Birth Date: <b>{{preview_info.birth_date | date: 'dd/MM/yyyy'}}</b></span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Blood Group: <b>{{preview_info.blood_group}}</b></span>
                                    </div>
                                </div>

                                <div class="row table-row">
                                    <div class="col-md-4 table-cell">
                                        <span>Community: <b>{{preview_info.community_id}}</b></span><br>
                                        <span *ngIf="preview_info.community_id_file">
                                            (<a [href]="preview_info.community_id_file" target="blank">Community
                                                certificate</a> Attached)
                                        </span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Caste: <b>{{preview_info.caste | titlecase}}</b></span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Religion: <b>{{preview_info.religion | titlecase}}</b></span>
                                    </div>
                                </div>

                                <div class="row table-row">
                                    <div class="col-md-4 table-cell">
                                        <span>Physically disabled: <b>{{preview_info.physically_challanged ? "Yes":
                                                "No"}}</b></span><br>
                                        <span *ngIf="preview_info.physically_challanged_proof">
                                            (<a [href]="preview_info.physically_challanged_proof"
                                                target="blank">Physical
                                                Disability
                                                Evidence</a> Attached)
                                        </span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Ward of Ex-Service Man: <b>{{preview_info.ex_service ? "Yes":
                                                "No"}}</b></span><br>
                                        <span *ngIf="preview_info.ex_service_proof">
                                            (<a [href]="preview_info.ex_service_proof" target="blank">Ex-Service Man
                                                Evidence</a> Attached)
                                        </span>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Participated in Sports: <b>{{preview_info.sports_certs ? "Yes":
                                                "No"}}</b></span><br>
                                        <span>Need Hostel Facility: <b>{{preview_info.need_hostel ? "Yes":
                                                "No"}}</b></span><br>
                                        <ng-container>
                                            <!-- *ngIf="preview_info.gender == 'female'" -->
                                            <span>
                                                <!-- *ngIf="preview_info.gender == 'female'" -->
                                                Need Transport Facility: <b>{{preview_info.need_bus ? "Yes":
                                                    "No"}}</b>
                                            </span><br>
                                        </ng-container>
                                        <span>Marital Status: <b>{{preview_info.is_applicant_married ? "Married":
                                                "Unmarried"}}</b></span>
                                    </div>
                                </div>

                                <div class="row table-row">
                                    <div class="col-md-4 table-cell">
                                        <span>Residential Address:</span>
                                        <p *ngIf="preview_info.nationality == 'indian'">
                                            <b>
                                                <span>{{preview_info.address.street}},</span> <br>
                                                <span>{{preview_info.address.city}},</span> <br>
                                                <span>{{preview_info.address.district}} - </span>
                                                <span>{{preview_info.address.pincode}},</span> <br>
                                                <span>{{preview_info.address.state}}.</span>
                                            </b>
                                        </p>
                                        <p *ngIf="preview_info.nationality != 'indian'">
                                            <span><b>{{preview_info.other_nationality_address}}</b></span>
                                        </p>
                                    </div>
                                    <div class="col-md-4 table-cell">
                                        <span>Identification Marks:</span>
                                        <p *ngFor="let mark of preview_info.identification_marks; let i = index">
                                            <b>{{i+1}}. {{mark}}</b>
                                        </p>
                                    </div>
                                </div>

                                <div class="row table-row">
                                    <div class="col-md-8">
                                        <p *ngIf="preview_info.family_members.length>0">Family Members Details:</p>
                                        <div class="table-responsive" *ngIf="preview_info.family_members.length>0"
                                            id="preview-family-members">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 5%;">S.No</th>
                                                        <th style="width: 20%;">Relationship</th>
                                                        <th style="width: 30%;">Name</th>
                                                        <th style="width: 25%;">Occupation</th>
                                                        <th style="width: 20%;">Salary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let member of preview_info.family_members; let i = index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{member.relationship_type}}</td>
                                                        <td><b>{{member.given_name}}</b></td>
                                                        <td>{{member.occupation}}</td>
                                                        <td>
                                                            <span *ngIf="member.salary">
                                                                Rs. {{member.salary}}
                                                            </span>
                                                            <span *ngIf="!member.salary">
                                                                ---
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <p><b>Academics Information</b></p>
                                <ng-container *ngIf="preview_info.apply_for == 'UG'">
                                    <div class="row table-row">
                                        <div class="col-md-4 table-cell">
                                            <span>School Name:
                                                <b>{{preview_info.hsc_academics.accademic_info.school_name}}</b></span><br>
                                            <span><b>{{preview_info.hsc_academics.accademic_info.school_place}}</b></span><br>
                                            <span>School Type:
                                                <b>{{getSchoolType(preview_info.hsc_academics.accademic_info.school_type)}}</b></span>
                                        </div>
                                        <div class="col-md-4 table-cell">
                                            <span>Exam Reg. No.:
                                                <b>{{preview_info.hsc_academics.accademic_info.exam_reg_no}}</b></span>
                                            <br>
                                            <span>Month & Year of Passing:
                                                <b>{{getPassingMonth(preview_info.hsc_academics.accademic_info.month_of_passout)}},
                                                    {{preview_info.hsc_academics.accademic_info.year_of_passout}}
                                                </b></span> <br>
                                            <span>First Graduate:
                                                <b>{{preview_info.hsc_academics.accademic_info.first_graduate ? "Yes":
                                                    "No"}}</b></span>
                                        </div>
                                        <div class="col-md-4 table-cell">
                                            <span>Board:
                                                <b>{{preview_info.hsc_academics.accademic_info.syllabus_type.split('_').join('
                                                    ') |
                                                    uppercase}}</b></span><br>
                                            <span>Medium of Instruction:
                                                <b>{{preview_info.hsc_academics.accademic_info.medium_of_study |
                                                    titlecase}}</b></span><br>
                                            <span>Vocational Group:
                                                <b>{{preview_info.hsc_academics.accademic_info.vocational ? "Yes":
                                                    "No"}}
                                                </b></span>
                                        </div>
                                    </div>

                                    <div class="row table-row">
                                        <div class="col-md-12">
                                            <span>HSC Mark Details</span>
                                            <span *ngIf="preview_info.hsc_academics.accademic_info.mark_statement_file">
                                                (<a [href]="preview_info.hsc_academics.accademic_info.mark_statement_file"
                                                    target="blank">Mark
                                                    Statement
                                                </a>Attached)
                                            </span>
                                            <div class="table-responsive" id="preview-hsc-marks">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 10%;">S.No</th>
                                                            <th>Subject Name</th>
                                                            <th style="width:20%">Mark</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let mark of preview_info.hsc_academics.marks; let i = index;">
                                                            <td>{{i+1}}</td>
                                                            <td><b>{{mark.subject_name}}</b></td>
                                                            <td><b>{{mark.mark}}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="preview_info.apply_for == 'PG'">
                                    <div class="row table-row">
                                        <div class="col-md-4 table-cell">
                                            <span>College Name:
                                                <b>{{preview_info.degree_academics.accademic_info.school_name}}</b></span><br>
                                            <span><b>{{preview_info.degree_academics.accademic_info.school_place}}</b></span>
                                        </div>
                                        <div class="col-md-4 table-cell">
                                            <span>UG Programme Studied:
                                                <b>{{preview_info.degree_academics.accademic_info.course_name |
                                                    uppercase}}</b></span><br>
                                            <span>College/University Reg. No.:
                                                <b>{{preview_info.degree_academics.accademic_info.exam_reg_no}}</b></span>
                                        </div>
                                        <div class="col-md-4 table-cell">
                                            <span>Month & Year of Passing:
                                                <b>{{getPassingMonth(preview_info.degree_academics.accademic_info.month_of_passout)}},
                                                    {{preview_info.degree_academics.accademic_info.year_of_passout}}
                                                </b></span> <br>
                                            <span>First Graduate:
                                                <b>{{preview_info.degree_academics.accademic_info.first_graduate ?
                                                    "Yes":
                                                    "No"}}</b></span>
                                        </div>
                                    </div>

                                    <div class="row table-row">
                                        <div class="col-md-12">
                                            <span>UG Mark Details</span>
                                            <div class="table-responsive" id="preview-ug-marks">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 5%;">S.No</th>
                                                            <th style="width: 5%;">Semester</th>
                                                            <th style="width:10%">No of Part III Subjects studied</th>
                                                            <th style="width: 30%;">Marks Obtained</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let mark of preview_info.degree_academics.marks; let i = index;">
                                                            <td>{{i+1}}</td>
                                                            <td><b>{{mark.semester}}</b></td>
                                                            <td><b>{{mark.no_of_subjects}}</b></td>
                                                            <td><b>{{mark.mark}}</b><br>
                                                                <span *ngIf="mark.semester_file">
                                                                    (<a [href]="mark.semester_file" target="blank">Mark
                                                                        Statement
                                                                    </a>Attached)
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="row table-row" *ngIf="purchased_application_list.length>0">
                                    <div class="col-md-12">
                                        <span>Applied Programmes</span>
                                        <div class="table-responsive" id="preview-applied-applications">
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Application No.</th>
                                                        <th>Applied Programme Type</th>
                                                        <th>Applied Programme Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        *ngFor="let application of purchased_application_list; let i = index;">
                                                        <td>{{i+1}}</td>
                                                        <td>{{application.application_no}}</td>
                                                        <td>{{application.programme_type_id}}</td>
                                                        <td>{{application.programme_name}}
                                                            {{application.finance_type == 'regular'?'(R)':'(SF)'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div class="row table-row" *ngIf="purchased_application_list.length==0">
                                    <div class="col-md-12">
                                        <span>Applied Applications: <b>N/A</b></span>
                                    </div>
                                </div>
                            </div>

                            <div class="row mg-t-10">
                                <div class="col-md-12">
                                    <form [formGroup]="user_form">
                                        <div class="custom-control custom-radio" style="display: inline;">
                                            <input id="agree" type="radio" class="custom-control-input" value="agree"
                                                name="agreed_to_save" formControlName="agreed_to_save">
                                            <label class="custom-control-label" for="agree">I agree that all the above
                                                furnished details are true to the best of my knowledge
                                                and the application shall be rejected if any discrepancy is
                                                found.</label>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="row mg-t-20">
                                <div class="col-md-6">
                                    <button type="submit" class="btn btn-success waves-effect waves-light mg-r-10"
                                        *ngIf="!save_loading"
                                        [attr.disabled]="user_form.value.agreed_to_save ? null: ''"
                                        (click)="saveProfile()">Save
                                        and
                                        Apply</button>
                                    <button type="submit" class="btn btn-success waves-effect waves-light mg-r-10"
                                        *ngIf="save_loading" disabled>Saving...</button>
                                    <button class="btn btn-secondary" (click)="onCloseClick()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<!-- /main-content -->
<app-footer></app-footer>