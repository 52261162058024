import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonEnums } from 'src/app/services/helpers/CommonEnums';
import { CommonErrorHelper } from 'src/app/services/helpers/CommonErrorHelper';
import { DateHelper } from 'src/app/services/helpers/DateHelper';
import { FromDataResolver } from 'src/app/services/helpers/FormDataResolver';
import { JQueryHelper } from 'src/app/services/helpers/JQueryHelper';
import { LocationHelper } from 'src/app/services/helpers/LocationHelper';
import { AdminservicesService } from 'src/app/services/rest/adminservices.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  commonEnums = CommonEnums;
  formDataResolver = FromDataResolver;
  locationHelper = LocationHelper;

  subject_list: any[] = [];
  active_admissions: any[] = [];

  enrolled_list: any[] = [];
  enrolled_list_loading = false;

  user_info: any;
  user_form: any;
  preview_info: any;

  parental_status = 'parent';
  popup_mode = '';
  save_loading: boolean = false;

  purchased_application_list: any[] = [];
  purchased_application_list_loading = false;

  one_paid = false;
  school_types: {
    id: string, text: string
  }[] = [{
    id: "PRIVATE", text: "Private"
  },
  {
    id: "GOVT", text: "Government"
  },
  {
    id: "GOVT_AIDED", text: "Government Aided"
  }];

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private restService: AdminservicesService) {
    JQueryHelper.toPageInit();
  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getSchoolSubjects();
    this.getActiveAdmissionDetails();
    //this.findEnrollmentDetails();
  }

  async getActiveAdmissionDetails(): Promise<void> {
    try {
      const response = await this.restService.getActiveAdmissionDetails();
      if (response.success && response.data) {
        this.active_admissions = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  isActiveAdmissionFound(programme_type_id) {
    return this.active_admissions.filter(x => x.programme_type_id == programme_type_id).length;
  }


  async getSchoolSubjects(): Promise<void> {
    try {
      const response = await this.restService.getSchoolSubjects();
      if (response.success && response.data) {
        this.subject_list = response.data;
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  getSchoolSubjectsByPartType(part_type: number): any[] {
    return this.subject_list.filter(c => c.part_type === part_type);
  }

  async getUserInfo(): Promise<void> {
    try {
      const response = await this.restService.getApplicantInfo();
      if (response.success && response.data) {
        this.user_info = response.data;
        this.buildUserForm();
      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    }
  }

  buildUserForm(): void {
    try {
      if (!this.user_info.address) {
        this.user_info.address = {};
      }
      if (!this.user_info.academics) {
        this.user_info.academics = [];
      }
      /* let sslc_academics = this.user_info.sslc_academics;
      if (!sslc_academics) {
        sslc_academics = {};
      }
      if (!sslc_academics.accademic_info) {
        sslc_academics.accademic_info = {};
      } */

      let hsc_academics = this.user_info.hsc_academics;
      if (!hsc_academics) {
        hsc_academics = {};
      }
      if (!hsc_academics.accademic_info) {
        hsc_academics.accademic_info = {};
      }
      // let hsc_11th_academics = this.user_info.hsc_11th_academics;
      // if (!hsc_11th_academics) {
      //   hsc_11th_academics = {};
      // }
      // if (!hsc_11th_academics.accademic_info) {
      //   hsc_11th_academics.accademic_info = {};
      // }

      let degree_academics = this.user_info.degree_academics;
      if (!degree_academics) {
        degree_academics = {};
      }
      if (!degree_academics.accademic_info) {
        degree_academics.accademic_info = {};
      }


      let phd_academics = this.user_info.phd_academics;
      if (!phd_academics) {
        phd_academics = {};
      }
      if (!phd_academics.accademic_info) {
        phd_academics.accademic_info = {};
      }
      if (!phd_academics.qualifiying_info) {
        phd_academics.qualifiying_info = {};
      }
      if (!phd_academics.has_teaching_experience) {
        phd_academics.has_teaching_experience = false;
      }
      if (!phd_academics.experience_info) {
        phd_academics.experience_info = {};
      }
      if (!phd_academics.has_submitted_project) {
        phd_academics.has_submitted_project = false;
      }
      if (!phd_academics.project_info) {
        phd_academics.project_info = {};
      }
      if (!phd_academics.academic_attainment) {
        phd_academics.academic_attainment = [];
      }
      if (!phd_academics.research_experience) {
        phd_academics.research_experience = {};
      }
      if (!phd_academics.has_research_experience) {
        phd_academics.has_research_experience = false;
      }
      if (!phd_academics.fields_of_research_interest) {
        phd_academics.fields_of_research_interest = "";
      }
      if (!phd_academics.had_disciplinary_action) {
        phd_academics.had_disciplinary_action = false;
      }
      if (!phd_academics.disciplinary_action) {
        phd_academics.disciplinary_action = '';
      }
      if (!phd_academics.had_scholarship) {
        phd_academics.had_scholarship = false;
      }
      if (!phd_academics.scholarship) {
        phd_academics.scholarship = '';
      }
      if (!phd_academics.bank_details) {
        phd_academics.bank_details = {};
      }
      if (!phd_academics.hostel_needed) {
        phd_academics.hostel_needed = false;
      }
      if (!phd_academics.accommodation_details) {
        phd_academics.accommodation_details = {};
      }
      if (!phd_academics.applicant_has_staff_reference) {
        phd_academics.applicant_has_staff_reference = false;
      }
      if (!phd_academics.applicant_reference_details) {
        phd_academics.applicant_reference_details = {};
      }




      this.user_form = this.fb.group({
        _id: [this.user_info._id],
        aadhar_card_no: [this.user_info.aadhar_card_no],
        //aadhar_card_no_file_front: [this.user_info.aadhar_card_no_file_front],
        //aadhar_card_no_file_back: [this.user_info.aadhar_card_no_file_back],
        mobile_number: [this.user_info.mobile_number],
        mobile_number_2: [this.user_info.mobile_number_2],
        mobile_number_3: [this.user_info.mobile_number_3],
        mobile_number_4: [this.user_info.mobile_number_4],
        mobile_number_5: [this.user_info.mobile_number_5],
        email: [this.user_info.email],
        middle_name: [this.user_info.middle_name],
        given_name: [this.user_info.given_name],
        birth_date: [DateHelper.convertToControlDate(new Date(this.user_info.birth_date))],
        gender: [this.user_info.gender || ''],
        blood_group: [this.user_info.blood_group || ''],
        community_id: [this.user_info.community_id || ''],
        community_id_file: [this.user_info.community_id_file || ''],
        caste: [this.user_info.caste || ''],
        religion: [this.user_info.religion || ''],
        physically_challanged: [this.user_info.physically_challanged || false],
        physically_challanged_proof: [this.user_info.physically_challanged_proof || ''], //new field
        //nature_of_disability: [this.user_info.nature_of_disability || ''],
        ex_service: [this.user_info.ex_service || false],
        ex_service_rank: [this.user_info.ex_service_rank || ''],
        ex_service_i_card_no: [this.user_info.ex_service_i_card_no || ''],
        ex_service_proof: [this.user_info.ex_service_proof || ''], //new field
        sports_certs: [this.user_info.sports_certs || false],
        need_hostel: [this.user_info.need_hostel || false],
        need_bus: [this.user_info.need_bus || false],
        is_applicant_married: [this.user_info.is_applicant_married || false],
        nationality: [this.user_info.nationality || 'indian'],
        address: this.fb.group({
          country: [this.user_info.address.country || 'India'],
          state: [this.user_info.address.state || 'Tamil Nadu'],
          district: [this.user_info.address.district],
          city: [this.user_info.address.city],
          pincode: [this.user_info.address.pincode],
          street: [this.user_info.address.street],
        }),
        other_nationality_address: [this.user_info.other_nationality_address || ''], //new field
        identification_marks: this.fb.array(this.prepareIdentificationMasrs(this.user_info.identification_marks)),
        parental_status: [this.parental_status],
        family_members: this.fb.array(this.prepareFamilyInfo(this.user_info.family_members)),
        apply_for: [this.user_info.apply_for || ''],
        /* sslc_academics: this.fb.group({
          accedemic_type: ['SSLC'],
          accademic_info: this.fb.group({
            school_name: [sslc_academics.accademic_info.school_name || ''],
            school_place: [sslc_academics.accademic_info.school_place || ''],
            course_name: [sslc_academics.accademic_info.course_name || ''],
            exam_reg_no: [sslc_academics.accademic_info.exam_reg_no || ''],
            year_month_passout: [sslc_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [sslc_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [sslc_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [sslc_academics.accademic_info.medium_of_study || 'tamil'],
            syllabus_type: [sslc_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
            mark_statement_file: [sslc_academics.accademic_info.mark_statement_file || ''],
          }),
          marks: this.fb.array(this.prepareAccedamicMarkInfo('SSLC', 'STATE_BOARD', sslc_academics.marks))
        }), */
        hsc_academics: this.fb.group({
          accedemic_type: ['HSC'],
          accademic_info: this.fb.group({
            school_name: [hsc_academics.accademic_info.school_name || ''],
            school_place: [hsc_academics.accademic_info.school_place || ''],
            school_type: [hsc_academics.accademic_info.school_type || ''],
            course_name: [hsc_academics.accademic_info.course_name || ''],
            exam_reg_no: [hsc_academics.accademic_info.exam_reg_no || ''],
            vocational: [hsc_academics.accademic_info.vocational || false],
            year_month_passout: [hsc_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [hsc_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [hsc_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [hsc_academics.accademic_info.medium_of_study || 'tamil'],
            first_graduate: [hsc_academics.accademic_info.first_graduate || false],
            syllabus_type: [hsc_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
            mark_statement_file: [hsc_academics.accademic_info.mark_statement_file || ''],
          }),
          marks: this.fb.array(this.prepare12thAccedamicMarkInfo('HSC',
            (hsc_academics.accademic_info.vocational || false), 'STATE_BOARD', hsc_academics.marks))
        }),
        // hsc_11th_academics: this.fb.group({
        //   accedemic_type: ['HSC'],
        //   accademic_info: this.fb.group({
        //     school_name: [hsc_11th_academics.accademic_info.school_name || ''],
        //     school_place: [hsc_11th_academics.accademic_info.school_place || ''],
        //     course_name: [hsc_11th_academics.accademic_info.course_name || ''],
        //     exam_reg_no: [hsc_11th_academics.accademic_info.exam_reg_no || ''],
        //     vocational: [hsc_11th_academics.accademic_info.vocational || false],
        //     year_month_passout: [hsc_11th_academics.accademic_info.year_month_passout || ''],
        //     year_of_passout: [hsc_11th_academics.accademic_info.year_of_passout || ''],
        //     month_of_passout: [hsc_11th_academics.accademic_info.month_of_passout || ''],
        //     medium_of_study: [hsc_11th_academics.accademic_info.medium_of_study || 'tamil'],
        //     first_graduate: [hsc_11th_academics.accademic_info.first_graduate || false],
        //     syllabus_type: [hsc_11th_academics.accademic_info.syllabus_type || 'STATE_BOARD'],
        //     mark_statement_file: [hsc_11th_academics.accademic_info.mark_statement_file || ''],
        //   }),
        //   marks: this.fb.array(this.prepare12thAccedamicMarkInfo('HSC',
        //     (hsc_11th_academics.accademic_info.vocational || false), 'STATE_BOARD', hsc_11th_academics.marks))
        // }),
        degree_academics: this.fb.group({
          accedemic_type: ['DEGREE'],
          accademic_info: this.fb.group({
            is_anjac: [degree_academics.accademic_info.school_name === 'Ayya Nadar Janaki Ammal College' ? 'true' : 'false'],
            school_name: [degree_academics.accademic_info.school_name || ''],
            school_place: [degree_academics.accademic_info.school_place || ''],
            course_name: [degree_academics.accademic_info.course_name || ''],
            exam_reg_no: [degree_academics.accademic_info.exam_reg_no || ''],
            year_month_passout: [degree_academics.accademic_info.year_month_passout || ''],
            year_of_passout: [degree_academics.accademic_info.year_of_passout || ''],
            month_of_passout: [degree_academics.accademic_info.month_of_passout || ''],
            medium_of_study: [degree_academics.accademic_info.medium_of_study || 'english'],
            first_graduate: [degree_academics.accademic_info.first_graduate || false]
          }),
          marks: this.fb.array(this.prepareDegreeAccedamicMarkInfo('DEGREE', degree_academics.marks))
        }),
        phd_academics: this.fb.group({
          accedemic_type: ['PHD'],
          accademic_info: this.fb.group({
            is_anjac: [phd_academics.accademic_info.school_name === 'Ayya Nadar Janaki Ammal College' ? 'true' : 'false'],
            school_name: [phd_academics.accademic_info.school_name || ''],
            school_place: [phd_academics.accademic_info.school_place || ''],
          }),
          qualifiying_info: this.fb.group({
            qualifying_exam: [phd_academics.qualifiying_info.qualifying_exam || ''],
            qualifying_exam_appearing_month: [phd_academics.qualifiying_info.qualifying_exam_appearing_month || ''],
            qualifying_exam_appearing_year: [phd_academics.qualifiying_info.qualifying_exam_appearing_year || ''],
            qualifying_exam_register_no: [phd_academics.qualifiying_info.qualifying_exam_register_no || ''],
            qualifying_exam_no_of_sittings: [phd_academics.qualifiying_info.qualifying_exam_no_of_sittings || ''],
            qualifying_exam_distinctions: [phd_academics.qualifiying_info.qualifying_exam_distinctions || ''],
            qualifying_exam_proof: [phd_academics.qualifiying_info.qualifying_exam_proof || ''],
            mku_entrance_qualified: [phd_academics.qualifiying_info.mku_entrance_qualified || false],
            mku_entrance_qualified_proof: [phd_academics.qualifiying_info.mku_entrance_qualified_proof || ''],
            net_set_qualified: [phd_academics.qualifiying_info.net_set_qualified || false],
            net_set_qualified_proof: [phd_academics.qualifiying_info.net_set_qualified_proof || ''],
            published_research_articles: [phd_academics.qualifiying_info.published_research_articles || false],
            published_research_articles_proof: [phd_academics.qualifiying_info.published_research_articles_proof || ''],
            rank_medal_received: [phd_academics.qualifiying_info.rank_medal_received || false],
            rank_medal_received_proof: [phd_academics.qualifiying_info.rank_medal_received_proof || ''],
            summer_project_completed: [phd_academics.qualifiying_info.summer_project_completed || false],
            summer_project_completed_proof: [phd_academics.qualifiying_info.summer_project_completed_proof || ''],
            tnscst_project_awarded: [phd_academics.qualifiying_info.tnscst_project_awarded || false],
            tnscst_project_awarded_proof: [phd_academics.qualifiying_info.tnscst_project_awarded_proof || ''],
            papers_in_conferences: [phd_academics.qualifiying_info.papers_in_conferences || false],
          }),
          has_teaching_experience: [phd_academics.has_teaching_experience || false],
          experience_info: this.fb.group({
            teaching_experience_institution: [phd_academics.experience_info.teaching_experience_institution || ''],
            teaching_experience_post_nature: [phd_academics.experience_info.teaching_experience_post_nature || ''],
            teaching_experience_years: [phd_academics.experience_info.teaching_experience_years || ''],
          }),
          academic_attainment: this.fb.array(this.preparePhdAcademicAttainment(phd_academics.academic_attainment)),
          has_submitted_project: [phd_academics.has_submitted_project || false],
          project_info: this.fb.group({
            title_of_project: [phd_academics.project_info.title_of_project || ''],
            name_designation_of_guide: [phd_academics.project_info.name_designation_of_guide || ''],
            guide_testimonial_proof: [phd_academics.project_info.guide_testimonial_proof || ''],
          }),
          has_research_experience: [phd_academics.has_research_experience || false],
          research_experience: this.fb.group({
            research_paper_published: [phd_academics.research_experience.research_paper_published || ''],
            details_of_papers_presented: [phd_academics.research_experience.details_of_papers_presented || ''],
            summer_training_workshop_attended: [phd_academics.research_experience.summer_training_workshop_attended || ''],
          }),
          fields_of_research_interest: [phd_academics.fields_of_research_interest || ''],
          had_disciplinary_action: [phd_academics.had_disciplinary_action || false],
          disciplinary_action: [phd_academics.had_disciplinary_action || ''],
          had_scholarship: [phd_academics.had_scholarship || false],
          scholarship: [phd_academics.had_scholarship || ''],
          bank_details: this.fb.group({
            bank_name: [phd_academics.bank_details.bank_name || ''],
            place: [phd_academics.bank_details.place || ''],
            account_number: [phd_academics.bank_details.account_number || ''],
            ifsc_code: [phd_academics.bank_details.ifsc_code || ''],
            micr_code: [phd_academics.bank_details.micr_code || ''],
          }),
          hostel_needed: [phd_academics.hostel_needed || ''],
          accommodation_details: this.fb.group({
            name_of_guardian: [phd_academics.accommodation_details.name_of_guardian || ''],
            relationship: [phd_academics.accommodation_details.relationship || ''],
            residential_address: [phd_academics.accommodation_details.guardian_residential_address || ''],
            residential_phone_std_code: [phd_academics.accommodation_details.guardian_std_code || ''],
            residential_address_mobile_no: [phd_academics.accommodation_details.guardian_mobile_no || ''],
            official_address: [phd_academics.accommodation_details.official_residential_address || ''],
            official_std_code: [phd_academics.accommodation_details.official_std_code || ''],
            official_mobile_no: [phd_academics.accommodation_details.official_mobile_no || ''],
          }),
          applicant_has_staff_reference: [phd_academics.applicant_has_staff_reference || ''],
          applicant_reference_details: this.fb.group({
            name_of_staff: [phd_academics.applicant_reference_details.name_of_staff || ''],
            relationship: [phd_academics.applicant_reference_details.relationship || ''],
            staff_department: [phd_academics.applicant_reference_details.staff_department || ''],
          })
        }),
        agreed_to_save: [this.user_info.agreed_to_save || false],
        mark_lock: [this.user_info.mark_lock || false],
      });

    } catch (error) {
      console.log(error);
    }

  }

  private preparePhdAcademicAttainment(academic_attainment: any[]): any {
    const value_to_return = [];
    if (!academic_attainment) {
      academic_attainment = [];
    }
    if (academic_attainment.length > 0) {
      for (const course of academic_attainment) {
        value_to_return.push(this.fb.group({
          examination_passed: [course.examination_passed || ''],
          year: [course.year || ''],
          institution: [course.institution || ''],
          university: [course.university || ''],
          subjects_studied: [course.subjects_studied || ''],
          percentage: [course.percentage || 0],
          distinction: [course.distinction || ''],
          proof: [course.proof || '']
        }));

      }
    } else {
      value_to_return.push(this.fb.group({
        examination_passed: [''],
        year: [''],
        institution: [''],
        university: [''],
        subjects_studied: [''],
        percentage: [0],
        distinction: [''],
        proof: ['']
      }));
    }
    return value_to_return;
  }

  get PHDAcademicAttainmentControls() {
    console.log(this.user_form.value.phd_academics.academic_attainment);
    return (((this.user_form as FormGroup).get('phd_academics') as FormGroup).get('academic_attainment') as FormArray).controls;
  }

  addPHDAcademicAttainment() {
    (((this.user_form as FormGroup).get('phd_academics') as FormGroup).get('academic_attainment') as FormArray).push(this.fb.group({
      examination_passed: [''],
      year: [''],
      institution: [''],
      university: [''],
      subjects_studied: [''],
      percentage: [0],
      distinction: [''],
      proof: ['']
    }));
  }

  deletePHDAcademicAttainment(i) {
    const consent = confirm("Are you sure want to delete this?");
    if (!consent) return;
    (((this.user_form as FormGroup).get('phd_academics') as FormGroup).get('academic_attainment') as FormArray).removeAt(i);
  }


  private prepareDegreeAccedamicMarkInfo(acadamic_type: string, marks: any[]): any {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({
          sno: [mark.sno],
          semester: [mark.semester || (mark.sno + 1)],
          semester_file: [mark.semester_file || ''],
          no_of_subjects: [mark.no_of_subjects || 4],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      for (let index = 0; index < 6; index++) {
        value_to_return.push(this.fb.group({
          sno: [index],
          semester: [index + 1],
          semester_file: [''],
          no_of_subjects: [4],
          mark: [0],
        }));
      }
    }
    return value_to_return;
  }


  /* async onSSLCBoardChange(): Promise<void> {
    const syllabus_type = this.user_form.value.sslc_academics.accademic_info.syllabus_type;
    const values = this.prepareAccedamicMarkInfo('SSLC', syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('sslc_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  } */

  private prepareAccedamicMarkInfo(acadamic_type: string, syllabus_type: string, marks: any[]): any[] {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({

          sno: [mark.sno],
          subject_id: [mark.subject_id || ''],
          subject_name: [mark.subject_name || ''],
          subject_type: [mark.subject_type || ''],
          part_type: [mark.part_type || ''],
          part_index: [mark.part_index || ''],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      let subjects = [];
      if (syllabus_type === 'ICSE') {
        subjects = [
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 2,
            sno: 4
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 3,
            sno: 5
          }
        ];
      } else {
        subjects = [
          {
            subject_type: 'LANGUAGE',
            subject_name: 'TAMIL',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: 'LANGUAGE',
            subject_name: 'ENGLISH',
            part_type: 1,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: 'THEORY',
            subject_name: 'MATHEMATICS',
            part_type: 2,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: 'THEORY',
            subject_name: 'SCIENCE',
            part_type: 2,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: 'THEORY',
            subject_name: 'SOCIAL SCIENCE',
            part_type: 2,
            part_index: 2,
            sno: 4
          }
        ];
      }
      for (const subject of subjects) {
        value_to_return.push(this.fb.group({
          sno: [subject.sno],
          subject_id: [''],
          subject_name: [subject.subject_name],
          subject_type: [subject.subject_type],
          part_type: [subject.part_type],
          part_index: [subject.part_index],
          mark: [0],
        }));

      }

    }



    return value_to_return;
  }

  async on11thBoardChange(): Promise<void> {
    const vocational = this.user_form.value.hsc_11th_academics.accademic_info.vocational;
    const syllabus_type = this.user_form.value.hsc_11th_academics.accademic_info.syllabus_type;
    const values = this.prepare12thAccedamicMarkInfo('HSC', vocational, syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('hsc_11th_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  }

  async onBoardChange(): Promise<void> {
    const vocational = this.user_form.value.hsc_academics.accademic_info.vocational;
    const syllabus_type = this.user_form.value.hsc_academics.accademic_info.syllabus_type;
    const values = this.prepare12thAccedamicMarkInfo('HSC', vocational, syllabus_type, []);
    const marks = (((this.user_form as FormGroup).get('hsc_academics') as FormGroup).get('marks') as FormArray);
    marks.clear();
    let i = 0;
    for (const value of values) {
      marks.insert(i, value);
      i++;
    }

  }
  private prepare12thAccedamicMarkInfo(acadamic_type: string, vocational: boolean, syllabus_type: string, marks: any[]): any {
    const value_to_return = [];
    if (!marks) {
      marks = [];
    }
    if (marks.length > 0) {
      for (const mark of marks) {

        value_to_return.push(this.fb.group({

          sno: [mark.sno],
          subject_id: [mark.subject_id || ''],
          subject_name: [mark.subject_name || ''],
          subject_type: [mark.subject_type || ''],
          part_type: [mark.part_type || ''],
          part_index: [mark.part_index || ''],
          //mark_1: [mark.mark_1 || 0],
          mark: [mark.mark || 0],
        }));

      }
    } else {
      let subjects = [];
      if (syllabus_type === 'STATE_BOARD') {
        if (vocational === true) {
          subjects = [
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 1,
              part_index: 0,
              sno: 0
            },
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 2,
              part_index: 1,
              sno: 1
            },
            {
              subject_type: 'SUBJECT', //THEORY
              subject_name: '',
              part_type: 3,
              part_index: 0,
              sno: 2
            },
            {
              subject_type: 'SUBJECT', //THEORY
              subject_name: '',
              part_type: 3,
              part_index: 1,
              sno: 3
            },
            {
              subject_type: 'SUBJECT', //THEORY
              subject_name: '',
              part_type: 4,
              part_index: 2, //0
              sno: 4
            },
            {
              subject_type: 'SUBJECT', //THEORY
              subject_name: '',
              part_type: 4,
              part_index: 3, //0
              sno: 5
            }
          ];
        } else {
          subjects = [
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 1,
              part_index: 0,
              sno: 0
            },
            {
              subject_type: 'LANGUAGE',
              subject_name: '',
              part_type: 2,
              part_index: 1,
              sno: 1
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 0,
              sno: 2
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 1,
              sno: 3
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 2, //4
              sno: 4
            },
            {
              subject_type: 'THEORY',
              subject_name: '',
              part_type: 3,
              part_index: 3, //5
              sno: 5
            }
          ];
        }

      } else {
        subjects = [
          {
            subject_type: '',
            subject_name: '',
            part_type: 1,
            part_index: 0,
            sno: 0
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 2,
            part_index: 1,
            sno: 1
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 0,
            sno: 2
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 1,
            sno: 3
          },
          {
            subject_type: '',
            subject_name: '',
            part_type: 3,
            part_index: 3,
            sno: 4
          }
        ];
      }

      for (const subject of subjects) {
        value_to_return.push(this.fb.group({
          sno: [subject.sno],
          subject_id: [''],
          subject_name: [subject.subject_name],
          subject_type: [subject.subject_type],
          part_type: [subject.part_type],
          part_index: [subject.part_index],
          //mark_1: [0],
          mark: [0],
        }));

      }

    }



    return value_to_return;
  }

  private prepareIdentificationMasrs(id_marks: string[]): any[] {
    const prepared_id_marks = [];
    if (!id_marks) {
      id_marks = [];
    }
    if (id_marks && id_marks.length > 0) {
      for (const id_mark of id_marks) {
        prepared_id_marks.push(this.fb.control(id_mark));
      }
    }
    if (prepared_id_marks.length < 2) {
      const length = 2 - prepared_id_marks.length;
      for (let index = 0; index < length; index++) {
        prepared_id_marks.push(this.fb.control(''));
      }
    }
    return prepared_id_marks;
  }

  private prepareFamilyInfo(data: any[]): any[] {
    const family_infos = [];
    if (!data) {
      data = [];
    }

    const father = data.find(c => c.relationship_type.toLowerCase() === 'father');
    if (father) {
      family_infos.push(
        this.fb.group({
          id: [father.id],
          relationship_type: ['Father'],
          relationship_name: [''],
          given_name: [father.given_name || ''],
          occupation: [father.occupation || ''],
          salary: [father.salary || '', [Validators.min(1)]],
          // contact_no: [father.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [father.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Father'],
          relationship_name: [''],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]],

          // contact_no: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: ["", [Validators.email]]

        })
      );
    }
    const mother = data.find(c => c.relationship_type.toLowerCase() === 'mother');
    if (mother) {
      family_infos.push(
        this.fb.group({
          id: [mother.id],
          relationship_type: ['Mother'],
          relationship_name: [''],
          given_name: [mother.given_name || ''],
          occupation: [mother.occupation || ''],
          salary: [mother.salary || '', [Validators.min(1)]],

          // contact_no: [mother.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [mother.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Mother'],
          relationship_name: [''],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]],

          // contact_no: ["", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: ["", [Validators.email]]

        })
      );
    }

    const guardian = data.find(c => c.relationship_type.toLowerCase() === 'guardian');
    if (guardian) {
      family_infos.push(
        this.fb.group({
          id: [guardian.id],
          relationship_type: [guardian.relationship_type],
          relationship_name: [guardian.relationship_name],
          given_name: [guardian.given_name || ''],
          occupation: [guardian.occupation || ''],
          salary: [guardian.salary || '', [Validators.min(1)]],

          // contact_no: [guardian.contact_no || "", [Validators.minLength(10), Validators.maxLength(10)]],
          // contact_email: [guardian.contact_email || "", [Validators.email]]

        })
      );
    } else {
      family_infos.push(
        this.fb.group({
          id: [''],
          relationship_type: ['Guardian'],
          relationship_name: ['Uncle'],
          given_name: [''],
          occupation: [''],
          salary: ['', [Validators.min(1)]]

        })
      );
    }


    return family_infos;


  }

  private validateMobile(mobile: string): boolean {
    if (!mobile) {
      return false;
    }
    if (mobile.trim().length === 0) {
      return false;
    }
    if (mobile.trim().length !== 10) {
      return false;
    }
    try {
      parseFloat(mobile);
    } catch (error) {
      return false;
    }

    return true;
  }

  private validateString(val: string): boolean {
    if (!val) {
      return false;
    }
    if (val.trim().length === 0) {
      return false;
    }
    return true;
  }

  private validateDate(val: string): boolean {
    if (!val) {
      return false;
    }
    try {
      const datae = new Date(val);
    } catch (error) {
      return false;
    }
    return true;
  }
  private validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async previewProfile(): Promise<void> {
    try {
      const user_info = this.user_form.value;
      console.log(user_info);
      if (!this.validateString(user_info.aadhar_card_no)) {
        alert('Please enter valid AADHAR card number');
        return;
      }
      /* if (!this.validateString(user_info.aadhar_card_no_file_back)) {
        alert('Please upload AADHAR card back image');
        return;
      }
      if (!this.validateString(user_info.aadhar_card_no_file_front)) {
        alert('Please upload AADHAR card front image');
        return;
      } */
      if (!this.validateMobile(user_info.mobile_number)) {
        alert('Please enter valid 10 digit father mobile number');
        return;
      }
      if (!this.validateMobile(user_info.mobile_number_2)) {
        alert('Please enter valid 10 digit student/alternate mobile number 1');
        return;
      }
      if (!this.validateString(user_info.email)) {
        alert('Please enter valid email');
        return;
      }
      if (!this.validateEmail(user_info.email)) {
        alert('Please enter valid email');
        return;
      }
      if (!this.validateString(user_info.middle_name)) {
        alert('Please enter valid initial');
        return;
      }
      if (!this.validateString(user_info.given_name)) {
        alert('Please enter valid full applicant name');
        return;
      }
      if (!this.validateDate(user_info.birth_date)) {
        alert('Please select valid birth date');
        return;
      }
      if (!this.validateString(user_info.gender)) {
        alert('Please select valid gender');
        return;
      }
      if (!this.validateString(user_info.blood_group)) {
        alert('Please select valid blood group');
        return;
      }
      if (!this.validateString(user_info.community_id)) {
        alert('Please select valid community');
        return;
      }
      if (user_info.community_id !== 'OC') {
        if (!this.validateString(user_info.community_id_file)) {
          alert('Please upload Community Certificate image');
          return;
        }
      }

      if (!this.validateString(user_info.caste)) {
        alert('Please enter valid caste');
        return;
      }
      if (!this.validateString(user_info.religion)) {
        alert('Please select valid caste');
        return;
      }

      if (user_info.physically_challanged) {
        if (!this.validateString(user_info.physically_challanged_proof)) {
          alert('Please upload Physically Challenged Proof');
          return;
        }
      }

      if (user_info.ex_service) {

        if (!this.validateString(user_info.ex_service_rank)) {
          alert('Please select valid ex service man rank');
          return;
        }
        if (!this.validateString(user_info.ex_service_i_card_no)) {
          alert('Please select valid i-card no.');
          return;
        }

        if (!this.validateString(user_info.ex_service_proof)) {
          alert('Please upload valid Service Man Proof');
          return;
        }
      }


      /* if (user_info.gender === 'male') {
        user_info.need_bus = false;
      } */
      if (!this.validateString(user_info.nationality)) {
        alert('Please select valid nationality');
        return;
      }

      if (!this.validateString(user_info.address.country)) {
        alert('Please select valid country');
        return;
      }
      if (!this.validateString(user_info.address.state)) {
        alert('Please select valid state');
        return;
      }
      if (!this.validateString(user_info.address.district)) {
        alert('Please select valid district');
        return;
      }
      if (!this.validateString(user_info.address.city)) {
        alert('Please enter valid city');
        return;
      }
      if (!this.validateString(user_info.address.pincode)) {
        alert('Please enter valid pincode');
        return;
      }
      if (!this.validateString(user_info.address.street)) {
        alert('Please enter valid street');
        return;
      }
      const identification_marks = [];
      for (const identification_mark of user_info.identification_marks) {
        if (identification_mark.trim()) {
          identification_marks.push(identification_mark.trim());
        }
      }
      if (identification_marks.length === 0) {
        alert('Please enter atleast one identification mark');
        return;
      }
      user_info.identification_marks = identification_marks;

      const family_members = [];
      for (const family_member of user_info.family_members) {
        if (family_member.given_name.trim()) {
          family_members.push(family_member);
        }
      }
      if (family_members.length === 0) {
        alert('Please enter atleast one family member details');
        return;
      }
      user_info.family_members = family_members;

      if (!user_info.apply_for) {
        alert('Please Select Which Programme Type you like to apply');
        return;
      }
      if (user_info.apply_for === 'UG') {
        /* const sslc_academics = user_info.sslc_academics;

        if (!this.validateString(sslc_academics.accademic_info.school_name)) {
          alert('Please enter valid SSLC school name');
          return;
        }
        if (!this.validateString(sslc_academics.accademic_info.school_place)) {
          alert('Please enter valid SSLC school address');
          return;
        }

        if (!this.validateString(sslc_academics.accademic_info.year_of_passout.toString())) {
          alert('Please select valid HSC year of passout');
          return;
        }
        if (!this.validateString(sslc_academics.accademic_info.month_of_passout)) {
          alert('Please select valid HSC month of passout');
          return;
        }
        if (!this.validateString(sslc_academics.accademic_info.mark_statement_file)) {
          alert('Please upload SSLC Mark Certificate image');
          return;
        }
        if (!this.validateString(sslc_academics.accademic_info.syllabus_type)) {
          alert('Please select board type');
          return;
        }


        sslc_academics.accademic_info.year_month_passout = sslc_academics.accademic_info.year_of_passout + '-'
          + sslc_academics.accademic_info.month_of_passout.padStart(2, '0');


        const valid_marks = [];
        for (const mark of sslc_academics.marks) {
          try {
            if (sslc_academics.accademic_info.syllabus_type === 'ICSE') {
              if (mark.subject_type === 'LANGUAGE') {
                mark.part_type = 1;
              } else if (mark.subject_type === 'THEORY') {
                mark.part_type = 2;
              } else if (mark.subject_type === 'PRACTICAL') {
                mark.part_type = 3;
              }
            }
            mark.mark = parseInt(mark.mark, 10);
            if (mark.mark > 0 && mark.mark <= 100 && mark.subject_name) {
              valid_marks.push(mark);
            }
          } catch (error) {

          }
        }
        if (valid_marks.length !== sslc_academics.marks.length) {
          alert('Please enter valid SSLC marks');
          return;
        }


        user_info.sslc_academics = sslc_academics; */




        const hsc_academics = user_info.hsc_academics;

        if (!this.validateString(hsc_academics.accademic_info.school_name)) {
          alert('Please enter valid HSC school name');
          return;
        }
        if (!this.validateString(hsc_academics.accademic_info.school_place)) {
          alert('Please enter valid HSC school address');
          return;
        }
        if (!this.validateString(hsc_academics.accademic_info.school_type)) {
          alert('Please choose HSC School Type');
          return;
        }
        if (!this.validateString(hsc_academics.accademic_info.year_of_passout.toString())) {
          alert('Please select valid HSC year of passout');
          return;
        }
        if (!this.validateString(hsc_academics.accademic_info.month_of_passout)) {
          alert('Please select valid HSC month of passout');
          return;
        }

        if (!this.validateString(hsc_academics.accademic_info.mark_statement_file)) {
          alert('Please upload HSC (12th) Mark Sheet Image');
          return;
        }
        // if (!this.validateString(hsc_academics.accademic_info.mark_statement_file)) {
        //   alert('Please upload HSC Mark Certificate image');
        //   return;
        // }
        hsc_academics.accademic_info.year_month_passout = hsc_academics.accademic_info.year_of_passout + '-'
          + hsc_academics.accademic_info.month_of_passout.padStart(2, '0');

        const valid_marks_2 = [];
        const validate_mark = false;
        for (const mark of hsc_academics.marks) {
          let is_valid = true;
          if (validate_mark) {
            /* try {
              mark.mark_1 = parseInt(mark.mark_1, 10);
              mark.mark_1 = Math.abs(mark.mark_1);
              if (mark.mark_1 > 0 && mark.mark_1 <= 100) {
                is_valid = true;
              } else {
                is_valid = false;
              }
            } catch (error) {
              is_valid = false;
            } */
            if (is_valid) {

              try {
                mark.mark = parseInt(mark.mark, 10);
                mark.mark = Math.abs(mark.mark);
                if (mark.mark > 0 && mark.mark <= 100) {
                  is_valid = true;
                } else {
                  is_valid = false;
                }
              } catch (error) {
                is_valid = false;
              }


            }
          }

          if (is_valid) {
            if (mark.subject_id) {
              const subject = this.subject_list.find(c => c.subject_id === mark.subject_id);
              mark.subject_name = subject.subject_name;
            }
            if (mark.subject_name || mark.subject_id) {
              is_valid = true;
            } else {
              is_valid = false;
            }
          }
          if (is_valid) {
            valid_marks_2.push(mark);
          }

        }
        if (valid_marks_2.length !== hsc_academics.marks.length) {
          alert('Please enter valid HSC marks');
          return;
        }
        user_info.hsc_academics = hsc_academics;


      } else if (user_info.apply_for === 'PG') {
        const degree_academics = user_info.degree_academics;

        if (degree_academics.accademic_info.is_anjac === 'true') {
          degree_academics.accademic_info.school_name = 'Ayya Nadar Janaki Ammal College';
        }

        if (!this.validateString(degree_academics.accademic_info.school_name)) {
          alert('Please enter valid name of the college/university last studied');
          return;
        }
        if (!this.validateString(degree_academics.accademic_info.school_place)) {
          alert('Please enter valid place of the college/university last studied');
          return;
        }
        if (!this.validateString(degree_academics.accademic_info.year_of_passout.toString())) {
          alert('Please select valid year of passout');
          return;
        }
        if (!this.validateString(degree_academics.accademic_info.month_of_passout)) {
          alert('Please select valid month of passout');
          return;
        }
        degree_academics.accademic_info.year_month_passout = degree_academics.accademic_info.year_of_passout + '-'
          + degree_academics.accademic_info.month_of_passout.padStart(2, '0');

        const valid_marks_2 = [];
        let has_error = false;
        const validate_mark = true;
        for (const mark of degree_academics.marks) {
          const max_mark = Number(mark.no_of_subjects) * 100;

          if (validate_mark) {
            try {
              mark.mark = parseInt(mark.mark, 10);
              mark.mark = Math.abs(mark.mark);
              if (mark.mark !== 0) {
                if (mark.mark > 0 && mark.mark <= max_mark) {
                  if (mark.semester <= 4) {
                    if (!this.validateString(mark.semester_file)) {
                      alert('Please upload Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' mark certificate');
                      has_error = true;
                      break;
                    }
                  }
                } else {
                  has_error = true;
                  alert('Error : In Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' Mark must be between 1 to ' + max_mark);
                  break;
                }
              }
            } catch (error) {
              has_error = true;
              alert('Error : In Semester ' + this.commonEnums.rommon_letters[mark.semester] + ' Please enter valid mark');
              break;
            }

          }
          valid_marks_2.push(mark);

        }
        if (has_error) {
          return;
        }
        const valid_marks_2_filter = valid_marks_2.filter(c => c.mark !== 0);
        if (valid_marks_2_filter.length < 4) {
          alert('Please enter at least 4 semester marks');
          return;
        }
        user_info.degree_academics = degree_academics;
      }
      else if (user_info.apply_for == 'PHD') {
        const phd_academics = user_info.phd_academics;
        if (phd_academics.accademic_info.is_anjac === 'true') {
          phd_academics.accademic_info.school_name = 'Ayya Nadar Janaki Ammal College';
        }

        if (!this.validateString(phd_academics.accademic_info.school_name)) {
          alert('Please enter valid name of the college/university last studied');
          return;
        }
        if (!this.validateString(phd_academics.accademic_info.school_place)) {
          alert('Please enter valid place of the college/university last studied');
          return;
        }

        if (!this.validateString(phd_academics.qualifiying_info.qualifying_exam)) {
          alert('Please enter valid Qualifying Exam');
          return;
        }

        if (phd_academics.qualifiying_info.qualifying_exam_appearing_month == '') {
          alert('Please enter Qualifying Exam Appearing Month');
          return;
        }

        if (phd_academics.qualifiying_info.qualifying_exam_appearing_year == '') {
          alert('Please enter Qualifying Exam Appearing Year');
          return;
        }

        if (!this.validateString(phd_academics.qualifiying_info.qualifying_exam_register_no)) {
          alert('Please enter valid Qualifying Exam Register No.');
          return;
        }

        if (phd_academics.qualifiying_info.mku_entrance_qualified) {
          if (!this.validateString(phd_academics.qualifiying_info.mku_entrance_qualified_proof)) {
            alert('Please attach Proof for MKU Entrance Qualification');
            return;
          }
        }

        if (phd_academics.qualifiying_info.net_set_qualified) {
          if (!this.validateString(phd_academics.qualifiying_info.net_set_qualified_proof)) {
            alert('Please attach Proof for NET/SET/GATE Qualification');
            return;
          }
        }


        if (phd_academics.qualifiying_info.published_research_articles) {
          if (!this.validateString(phd_academics.qualifiying_info.published_research_articles_proof)) {
            alert('Please attach Proof for Research Articles Published');
            return;
          }
        }

        if (phd_academics.qualifiying_info.rank_medal_received) {
          if (!this.validateString(phd_academics.qualifiying_info.rank_medal_received_proof)) {
            alert('Please attach Proof for Rank or Medal Received');
            return;
          }
        }

        if (phd_academics.qualifiying_info.summer_project_completed) {
          if (!this.validateString(phd_academics.qualifiying_info.summer_project_completed_proof)) {
            alert('Please attach Proof for Summer Project Completion');
            return;
          }
        }

        if (phd_academics.qualifiying_info.tnscst_project_awarded) {
          if (!this.validateString(phd_academics.qualifiying_info.tnscst_project_awarded_proof)) {
            alert('Please attach Proof for TNSCST Project Awarded');
            return;
          }
        }

        if (phd_academics.has_teaching_experience) {
          if (!this.validateString(phd_academics.experience_info.teaching_experience_institution)) {
            alert('Please enter a valid Teaching Experience Institution Name');
            return;
          }

          if (!this.validateString(phd_academics.experience_info.teaching_experience_post_nature)) {
            alert('Please enter a valid Teaching Experience Nature of Post');
            return;
          }

          if (phd_academics.experience_info.teaching_experience_years == '') {
            alert('Please enter a valid Teaching Experience Years');
            return;
          }
        }
        var academic_attainment_error: boolean = false;
        var academic_attainment: any[] = phd_academics.academic_attainment;
        for (var i = 0; i < academic_attainment.length; i++) {
          for (const [key, value] of Object.entries(academic_attainment[i])) {
            if (value == "" && key != 'distinction') {
              academic_attainment_error = true;
            }
          }
        }
        if (academic_attainment_error) {
          alert("Please fill academic attainment details correctly");
          return;
        }

        if (phd_academics.has_submitted_project) {
          if (!this.validateString(phd_academics.project_info.title_of_project)) {
            alert('Please enter a valid Submitted Project\'s Title');
            return;
          }

          if (!this.validateString(phd_academics.project_info.name_designation_of_guide)) {
            alert('Please enter a valid Project\'s Guide/Supervisior Name');
            return;
          }
        }

        if (!this.validateString(phd_academics.fields_of_research_interest)) {
          alert('Please enter a valid Research Interest');
          return;
        }

        if (phd_academics.had_disciplinary_action) {
          if (!this.validateString(phd_academics.disciplinary_action)) {
            alert('Please enter a valid Disciplinary Action');
            return;
          }
        }

        if (phd_academics.had_scholarship) {
          if (!this.validateString(phd_academics.scholarship)) {
            alert('Please enter a valid Scholarship Recevied');
            return;
          }
        }


        if (!this.validateString(phd_academics.bank_details.bank_name)) {
          alert('Please enter a valid Bank Name');
          return;
        }

        if (!this.validateString(phd_academics.bank_details.place)) {
          alert('Please enter a valid Bank Place');
          return;
        }

        if (!this.validateString(phd_academics.bank_details.account_number)) {
          alert('Please enter a valid Bank Account No.');
          return;
        }

        if (!this.validateString(phd_academics.bank_details.ifsc_code)) {
          alert('Please enter a valid Bank IFSC Code');
          return;
        }

        if (!this.validateString(phd_academics.bank_details.micr_code)) {
          alert('Please enter a valid MICR Code');
          return;
        }

        if (!phd_academics.hostel_needed) {
          if (!this.validateString(phd_academics.accommodation_details.name_of_guardian)) {
            alert('Please enter a valid Guardian Name');
            return;
          }

          if (!this.validateString(phd_academics.accommodation_details.relationship)) {
            alert('Please enter a valid Guardian Relationship');
            return;
          }

          if (!this.validateString(phd_academics.accommodation_details.residential_address)) {
            alert('Please enter a valid Residential Address');
            return;
          }

          if (!this.validateMobile(phd_academics.accommodation_details.residential_address_mobile_no)) {
            alert('Please enter a valid Residential Address Mobile No.');
            return;
          }

          if (!this.validateString(phd_academics.accommodation_details.official_address)) {
            alert('Please enter a valid Official Address');
            return;
          }

          if (!this.validateMobile(phd_academics.accommodation_details.official_mobile_no)) {
            alert('Please enter a valid Official Address Mobile No.');
            return;
          }
        }

        if (phd_academics.applicant_has_staff_reference) {
          if (!this.validateString(phd_academics.applicant_reference_details.name_of_staff)) {
            alert('Please enter a valid Reference Staff Name');
            return;
          }
          if (!this.validateString(phd_academics.applicant_reference_details.relationship)) {
            alert('Please enter a valid Reference Staff Relationship');
            return;
          }

          if (!this.validateString(phd_academics.applicant_reference_details.staff_department)) {
            alert('Please enter a valid Reference Staff Department');
            return;
          }
        }

      }

      console.log(user_info);
      this.preview_info = this.user_form.value;
      this.popup_mode = 'preview';
      console.log(this.preview_info);
      this.getPurchasedApplications();
      JQueryHelper.openModal('#modal-popup', { keyboard: false, backdrop: 'static' });
    } catch (error) {
      console.error(error);
      alert('Error while saving Profile');
    }

  }

  async saveProfile() {
    try {
      const consent = confirm('Are you sure do you want to save?');
      if (!consent) {
        return;
      }
      this.save_loading = true;
      this.previewProfile();
      const user_info = this.user_form.value;
      const respose = await this.restService.saveApplicantInfo(user_info);
      if (respose.success) {
        alert("Form submitted successfully");
        this.router.navigate(['/applications/apply']);
      }
    }
    catch (error) {
      console.error(error);
      alert('Error while saving Profile');
    }
    finally {
      this.save_loading = false;
      this.onCloseClick();
    }
  }


  /* async onProfileSaveClick() {
    console.log(this.user_form.value.phd_academics);
  } */
  async uploadImage(e: any, form: FormGroup, key: string): Promise<void> {

    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      const response = await this.restService.uploadFile(formData);
      if (response.success && response.data && response.data.url) {
        form.get(key).setValue(response.data.url);
      }
    } catch (error) {

    } finally {

    }
  }


  onCloseClick() {
    this.popup_mode = '';
    JQueryHelper.closeModal('#modal-popup');
  }

  getPassingMonth(id) {
    return this.commonEnums.months.find(x => x.id == id).text;
  }

  async getPurchasedApplications(): Promise<void> {
    try {
      this.purchased_application_list_loading = true;
      this.purchased_application_list = [];
      const response = await this.restService.getPurchasedApplications();
      if (response.success && response.data) {

        for (const data of response.data) {
          data.show_payment = true;
          if (data.status === 'paid') {
            this.one_paid = true;
            break;
          }
        }
        const transfer_data = response.data.find(c => c.transfer_pending_amount > 0);
        if (transfer_data) {
          for (const data of response.data) {
            data.show_payment = false;
            if (data.application_no === transfer_data.application_no) {
              data.show_payment = true;
            }
          }
        }
        this.purchased_application_list = response.data;

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
    } finally {
      this.purchased_application_list_loading = false;
    }
  }

  async downloadProfile() {
    this.previewProfile();
    setTimeout(() => {
      let self = this;
      var window_height = window.screen.height;
      var window_width = window.screen.width;
      var mywindow = window.open('', 'PRINT', 'height=' + window_height + ',width=' + window_width);
      mywindow.document.write('<html><head>');
      mywindow.document.write("<link rel=\"stylesheet\" href=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css\">" +
        "<script src=\"https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js\" > </script>" +
        "<script src=\"https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js\" > </script>" +
        "<script src=\"https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js\" > </script>" +
        "<style>.table-cell { border: 0.5px solid black; } .table-row { border: 1px solid black; } </style>");
      mywindow.document.write('</head><body><div style="margin-top:20px; font-family:\'serif\'">');
      mywindow.document.write(document.getElementById('profile-view').innerHTML);
      setTimeout(() => {
        mywindow.document.getElementById('preview-family-members').classList.remove('table-responsive');
        if (this.preview_info.apply_for == 'UG') {
          mywindow.document.getElementById('preview-hsc-marks').classList.remove('table-responsive');
        }
        if (this.preview_info.apply_for == 'PG') {
          mywindow.document.getElementById('preview-ug-marks').classList.remove('table-responsive');
        }
      }, 500);
      mywindow.document.write('</div></body></html>');
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10
      setTimeout(function () {
        mywindow.print();
        mywindow.close();
        self.onCloseClick();
      }, 1000);
      return true;
    }, 1000)
  }

  getSchoolType(id: string) {
    return this.school_types.find(x => x.id == id)?.text;
  }


  /* async findEnrollmentDetails(): Promise<void> {
    try {
      this.enrolled_list_loading = true;
      this.enrolled_list = [];
      const response = await this.restService.findOnlineClassDetails();
      if (response.success && response.data) {

        for (const data of response.data) {
          if (data.status === 'paid') {
            this.one_paid = true;
            break;
          }
        }
        this.enrolled_list = response.data;

      }
    } catch (error) {
      alert(CommonErrorHelper.handleErrorMessagge(error, CommonErrorHelper.read));
 
    } finally {
      this.enrolled_list_loading = false;
    }
  } */
}
