import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComboBarComponent } from './components/combo-bar/combo-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { AccessDeniedComponent } from './controllers/public/access-denied/access-denied.component';
import { LoginComponent } from './controllers/public/login/login.component';
import { RegisterComponent } from './controllers/public/register/register.component';
import { UnderMaintenanceComponent } from './controllers/public/under-maintenance/under-maintenance.component';
import { DashboardComponent } from './controllers/students/dashboard/dashboard.component';
import { ApplynewComponent } from './controllers/students/applynew/applynew.component';
import { ViewappliedComponent } from './controllers/students/viewapplied/viewapplied.component';
import { TransactionsComponent } from './controllers/students/transactions/transactions.component';
import { StatusComponent } from './controllers/payment/status/status.component';
import { ExamComponent } from './controllers/students/entrance/exam/exam.component';
import { EntranceDashboardComponent } from './controllers/students/entrance/entrance-dashboard/entrance-dashboard.component';
import { ProgrammestatusComponent } from './controllers/payment/programmestatus/programmestatus.component';
import { OnlineclassComponent } from './controllers/students/onlineclass/onlineclass.component';
import { SelectionListComponent } from './controllers/public/selection-list/selection-list.component';
import { PortalHelperComponent } from './controllers/students/portal-helper/portal-helper.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SideBarComponent,
    FooterComponent,
    ComboBarComponent,
    AccessDeniedComponent,
    UnderMaintenanceComponent,
    NotificationComponent,
    LoginComponent,
    RegisterComponent,
    ApplynewComponent,
    ViewappliedComponent,
    TransactionsComponent,
    StatusComponent,
    ExamComponent,
    EntranceDashboardComponent,
    ProgrammestatusComponent,
    OnlineclassComponent,
    SelectionListComponent,
    PortalHelperComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientJsonpModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
